import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import {LoginIsTrue} from './ajax_login';
import "./Login.css"
import spinner from './spinner4.svg'
import logo from './logo_bgwhite.png'

export default class Login extends Component {
constructor(props) {
super(props);
  this.state={
	istrue:false,
	esSubCuenta:'0',
    usuario:'',
    pass:'',
	id:'',
	loginIn:'',
    nombreEmpresa:'',
	idUsu:'',
	heredarGrupo:'',
	changeIsTrue:this.props.changeIsTrue,
	error_msg:''
  }
	this.changeLogin = this.changeLogin.bind(this);
}

  	changeLogin(objLogin){
		console.log(objLogin);
		this.setState({loginIn:objLogin.loginIn});
		this.setState({id:objLogin.id});
		this.setState({idUsu:objLogin.idUsu});
		this.setState({usuario:objLogin.usuario});
		this.setState({nombreEmpresa:objLogin.nombreEmpresa});
		this.setState({heredarGrupo:objLogin.heredarGrupo});
		this.setState({istrue:objLogin.istrue});
		this.setState({esSubCuenta:objLogin.esSubCuenta});
		this.state.changeIsTrue(objLogin);
		console.log(this.state);
		
	}
  	onLogin =() =>
  	{   
	let objLogin={
		usuario:this.state.usuario,
		pass:this.state.pass
	}
	console.log(LoginIsTrue(this.changeLogin,objLogin));
    LoginIsTrue(this.changeLogin,objLogin);
				
	}
	
	onUsernameChange = (elem) => {
		this.setState({ usuario: elem.target.value });
		console.log(this.state.usuario)
	}

	onPasswordChange = (elem) => {
		this.setState({ pass: elem.target.value });
	}


  render() {
		let proState = this.state;
		//console.log(proState);
    return (<React.Fragment>
     {/*this.state.istrue && <Redirect
                from="/login"
                to={{
                pathname: '/',
                istrue:true,
				esSubCuenta:this.state.esSubCuenta,
                usuario:this.state.usuario,
                idUsu:this.state.idUsu,
				loginIn:this.state.loginIn,
				heredarGrupo:this.state.heredarGrupo,
                id: this.state.id
                }}
			/>*/}
		
							<div className="fs f">
							
								<div className="login_box">
									<img id="logo" src={logo}></img>
									<span className="title">Plataforma de Gestión de Flota Vehicular</span>
									<div className="login_fields">
										<div className="field_line">
											<span className="fieldname">Usuario:</span>
											<input
												value={this.state.usuario}
												onChange={this.onUsernameChange}
												className="login_input"
											/>
										</div>
										<div className="field_line">
											<span className="fieldname">Contraseña:</span>
											<input
												value={this.state.pass}
												onChange={this.onPasswordChange}
												className="login_input"
		
												type="password" />
										</div>
										{
											(this.state.error_msg.length > 0)
											&& <span className="error_msg fade">{this.state.error_msg}</span>
										}
										<div className="field_line">
											{!this.state.loading ?
												<button
													onClick={this.onLogin}
													className="login_button"
												>Ingresar</button> :
												<button
													className="login_button"
												><img className="spinner" src={spinner} /></button>
											}
										</div>
									</div>
								</div>
						</div>    
						</React.Fragment> 
    );
  }
}

//export default Login;
