import React, { Component } from 'react';

class Sensor extends Component {
    //atributos del state que comiensan en 'o' obtienen su valor de ajax_obtenerInfoSelect
    constructor(props){
        super(props);
        this.state={     
            sensor:this.props.sensor,
            acumsJson:this.props.acumsJson
        }
        
      }
    componentWillReceiveProps(nextProps){
        if(nextProps.sensor!==this.state.sensor){
            this.setState({sensor:nextProps.sensor});
            this.setState({acumsJson:nextProps.acumsJson});
        }
    }
    componentWillMount(){
       
    }
    componentDidMount(){
        
    }
    
    render() {
        console.log(this.props);
        let sensor = this.state.sensor;
        return (
            <div>
            <div style={{display:'flex', justifyContent:'spaceBetween', width:'60%'}} key={'dKardexTVe'+this.state.sensor['AcumNro']}> 
                <div style={{width:'60%',backgroundColor:'white', color:'#2E2D2D'}}>{this.state.sensor['nombre']+':'}</div>
                <div style={{width:'40%'}}>{this.state.sensor['formula']!==undefined?
                  Math.trunc(eval(this.state.acumsJson[this.state.sensor['AcumNro']]+this.state.sensor['formula']))+ " "+this.state.sensor['medida']:this.state.acumsJson[this.state.acumAsignado['AcumNro']]+ " "+this.state.sensor['medida']}</div>
              </div>
            </div>
        )
    }
}

export default Sensor;
