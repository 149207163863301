import React from 'react';
import Principal from './Principal/Principal';
import './App.css';

function App()  {
  
    
  return (
    <div className="App">
      <Principal></Principal>
    </div>
  );
}

export default App;
