import React, { Component } from 'react';
import Select2 from 'react-select';

export default class EnviarComando extends Component {
    constructor(props) {
    super(props);
    this.state={
        statePrincipal:this.props.statePrincipal,
        optionPlaca:[{value:0,label:'Apagado de motor'},{value:1,label:'Cambio de Velocidad'}],
        changeEnviarComandoShow:this.props.changeEnviarComandoShow,
        istrue:false,
        unidad:''
    }

    }
    handleChange = (unidad) => {
        this.setState({unidad:unidad['value'] });
        console.log('Option selected:', unidad['value']);
	}
  render() {
		let proState = this.state;
		console.log(proState);
    return (
		<div >
            <div style={{width:'100vw',backgroundColor:'rgb(31, 105, 173)',color:'white'}}><h1>{proState['statePrincipal'].placa}</h1></div>
            <div style={{width:'80vw', marginLeft:'10vw', marginRight:'10vw'}}><Select2 options={this.state.optionPlaca} isSearchable='true' isClearable='true'  onChange={this.handleChange} /></div>
            <div style={{borderRadius: '6px',backgroundColor:'#3a4248',width:'50vw',height:'10vh',color:'white',marginLeft:'25vw',marginRight:'25vw',fontSize:'16px'}}>
					<div style={{paddingTop:'2vh',marginLeft:'20vw',marginRight:'20vw', marginTop:'5vh'}} onClick={()=>{/*alert('Se envio el comando')*/}}>Enviar Comando</div>
				</div>
            <div style={{borderRadius: '6px',backgroundColor:'#3a4248',width:'50vw',height:'10vh',color:'white',marginLeft:'25vw',marginRight:'25vw',fontSize:'16px'}}>
					<div style={{paddingTop:'2vh',marginLeft:'20vw',marginRight:'20vw', marginTop:'2vh'}} onClick={()=>{this.state.changeEnviarComandoShow(false)}}>Volver rastreo</div>
				</div>
		</div>    
						
    );
  }
  
}

