import $ from 'jquery';
import {url} from '../../ParametroConexion/parametroConexion';

export function ObtenerConductor(changeConductor,idConductor){
    $.ajax({
        //url: 'http://127.0.0.1:2525/datos',
        url: url,
        type: "POST",
        data: {
                data_op:idConductor,
                op:'READ-CONDUCTORKARDEX'
        },
        success: function(result)            
           {
            let obj =  $.parseJSON(result);
            obj=obj['data'];
            console.log(obj);
                if(obj!==undefined){
                    changeConductor(obj[0]);
                }
            }
    
    }).done(function(result){

       console.log(result);     
    })

}
export function ObtenerPlanViajePlaca(that,obj,changeNroOrden){
    $.ajax({
        //url: 'http://127.0.0.1:2525/datos',
        url: url,
        type: "POST",
        data: {
                data_op:obj,
                op:'READ-PLANVIAJEPLACA'
        },
        success: function(result)            
                   {

            var array = JSON.parse( result );
            console.log(array);         
            result= array['data'];
            that.setState({nro:result});
            if(result[0]!==undefined){
                changeNroOrden(result[0],that);
            }            
            console.log(result);
            }
    
    }).done(function(result){
    })
}