import React, { Component } from 'react';
import Login from '../Login/Login';
import Rastreo from '../Rastreo/Rastreo';
import Historico from '../Historico/Historico';
import EnviarComando from '../EnviarComando/EnviarComando';

export default class Principal extends Component {
constructor(props) {
super(props);
  this.state={

    istrue:false,
    historicoShow:false,
    enviarComandoShow:false,
    esSubCuenta:0,
    placa:'',
    usuario:'',
    pass:'',
	  id:'',
	  loginIn:'',
    nombreEmpresa:'',
	  idUsu:'',
	  heredarGrupo:'',
	  error_msg:''
  }
this.changeIsTrue=this.changeIsTrue.bind(this);
this.changeHistoricoShow=this.changeHistoricoShow.bind(this);
this.changePlacaHistorico=this.changePlacaHistorico.bind(this);
this.changeEnviarComandoShow=this.changeEnviarComandoShow.bind(this);
}
 changePlacaHistorico(placa){
    this.setState({placa});
    console.log(placa);
 }
 changeHistoricoShow(historicoShow){
     this.setState({historicoShow});
 }
 changeEnviarComandoShow(enviarComandoShow){
   this.setState({enviarComandoShow})
 }
 changeIsTrue(obj){
     console.log(obj);
     this.setState({id:obj.id});
     this.setState({nombreEmpresa:obj.nombreEmpresa});
     this.setState({idUsu:obj.idUsu});
     this.setState({esSubCuenta:obj.esSubCuenta});
     this.setState({heredarGrupo:obj.heredarGrupo});
     this.setState({loginIn:obj.loginIn});
     this.setState({istrue:obj.istrue});
 }
  render() {
		let proState = this.state;
		console.log(proState);
    return (
		<div>
            {this.state.istrue===false&&
                <Login changeIsTrue={this.changeIsTrue} ></Login>		
            }
            {this.state.istrue===true&& this.state.historicoShow===false && this.state.enviarComandoShow===false &&
                <Rastreo statePrincipal={proState} changeEnviarComandoShow={this.changeEnviarComandoShow} changeHistoricoShow={this.changeHistoricoShow} changePlacaHistorico={this.changePlacaHistorico}></Rastreo>
            }
            {this.state.istrue===true&& this.state.historicoShow===true &&
                <Historico statePrincipal={proState} changeHistoricoShow={this.changeHistoricoShow}></Historico>
            }	
            {this.state.istrue===true&&this.state.enviarComandoShow===true &&
                <EnviarComando statePrincipal={proState} changeEnviarComandoShow={this.changeEnviarComandoShow}/>
            }		
		</div>    
						
    );
  }
}
