import $ from 'jquery';
import {url} from '../ParametroConexion/parametroConexion';
export function ObtenerAcumAsignado(idActivo,changeAcumAsignado,changeNroTemp,changeAcumAsignadoRastreo,changeAcumsJson,acums){
    $.ajax({
        //url: 'http://127.0.0.1:2525/datos',
        url: url,
        type: "POST",
        data: {
                data_op:idActivo,
                op:'READ-ACUMASIGNADO'
        },
        success: function(result)            
           {
            let obj =  $.parseJSON(result);
            obj=obj['data'];
            console.log(obj);
            let objAcums = $.parseJSON(acums);
            if(obj!==undefined){
                changeAcumAsignado(obj);
                console.log(obj);
                //obj[0].acums=$.parseJSON(acums);
                changeAcumAsignadoRastreo(obj);
               
                console.log(objAcums);
                changeAcumsJson(objAcums);
                if(obj[0!==undefined]){
                    changeNroTemp(obj[0].AcumNro);
                }
               
            }            
            }
    
    }).done(function(result){

       console.log(result);
       
       
    })

}