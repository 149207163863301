import React, { Component } from 'react';
import Group from './Group';
import Placa from "./placa"
import './style.css';
let ar=[];
let ag=[];
let openg=false;
let tex='';
class OGrid extends Component {
    constructor(props){
      super(props);
      this.state={
        placaO:this.props.placaO,
        txt:'',
        ag2:[],
        ar2:[],
        mostrarVehiculo:this.props.mostrarVehiculo,
        checkConductor:false,
        pFinder:[],
        groupp:this.props.groupp,
        fObtenerVTikeado:this.props.f,
        fClearMArker: this.props.u,
        o:this.props.o,
        opengrilla:false,
        google:this.props.google,
        clearMarkerForTodos:this.props.clearMarkerForTodos,
        clearMarkerForGroup:this.props.clearMarkerForGroup,
        clearMarkerForSubGroup:this.props.clearMarkerForSubGroup,
        alturaGrid:this.props.alturaGrid,
        changeZoomMap:this.props.changeZoomMap,
        arrayPlaca:[],
        centrar: this.props.centrar,
        changeAnchura: this.props.changeAnchura,
        changeAnchoMapa:this.props.changeAnchoMapa,
        changeHistoricoShow:this.props.changeHistoricoShow,
        changePlacaHistorico:this.props.changePlacaHistorico,
        changeEnviarComandoShow:this.props.changeEnviarComandoShow,
        oPlaca: this.props.oPlaca
      }
     
      }
  componentWillReceiveProps(nextProps){
    this.setState({o:nextProps.o});
    this.setState({placaO:nextProps.placaO});
    
    if((this.state.placaO!==''&&this.state.placaO!==undefined)||(nextProps.placaO!==''&&nextProps.placaO!==undefined)){
      if(nextProps.alturaGrid!==undefined){
        this.setState({alturaGrid:nextProps.alturaGrid});
      }
      
      console.log('entro altura '+nextProps.alturaGrid);
    }
    if((this.state.placaO==='')&&(nextProps.placaO==='')){
      if(nextProps.alturaGrid!==undefined){
        this.setState({alturaGrid:nextProps.alturaGrid});
      }
    }
    
  }    
  find(txt){
    ar.length=0;
    let g=[];
    ag=[];
    let objFind2 = this.state.groupp;
    let objFind = this.state.o;
    let obf = objFind.includes(txt);
    for(let j=0;j<Object.keys(objFind).length;j++){
      if(Object.keys(objFind)[j].includes(txt)){
       ag[Object.keys(objFind)[j]]= objFind[Object.keys(objFind)[j]];
      }
    }
    this.setState({ag2:ag});
    let a = Object.keys(objFind2).map(o=>(console.log(objFind2[o]),objFind2[o]));
    //-----------------**------------------------//
    for(let i=0;i<Object.keys(a).length;i++)
    {     
      let n = a[i];
      for(let x=0;x<Object.keys(n).length;x++){
        let f=Object.keys(n);
        g = n[f[x]];
        for(let k=0;k<g.length;k++){
          if(g[k].includes(txt)){
            ar.push(g[k]);
          }         
        }       
      }
    }
    if((document.getElementsByClassName("row3")!==null) || (document.getElementsByClassName("row3")!==undefined))
    {
      var list = document.getElementsByClassName("row3");
      for(let i=0;i<list.length;i++){
        list[i].innerHTML='';
      }
    }
    console.log(ar);
    console.log(ag);
   this.setState({ar2:ar})
  }
  insertarPlaca(tt){  
    let arrayPlaca=[];
    for(let x=0;x<Object.keys(tt).length;x++){
      let val = tt[Object.keys(tt)[x]];
      arrayPlaca.push(<Placa changeEnviarComandoShow={this.state.changeEnviarComandoShow} changePlacaHistorico={this.state.changePlacaHistorico} changeHistoricoShow={this.state.changeHistoricoShow} centrar={this.state.centrar} grupoName={'juntoGroup'} subGrupoName={'juntoSubGroup'} pc={val} checkConductor={this.state.checkConductor} oPlaca={this.state.oPlaca} o={ag[val]} u={this.state.fClearMArker} f={this.state.fObtenerVTikeado}/>);
    }
    return <div style={{marginTop:'60px'}} className="row5" >{arrayPlaca}</div>;
  }
  
  removePlaca(){
  
  }
  opengrilla(){
    let eTxt = this.state.txt.length;
    switch(eTxt){
      case 1:
        openg= 1;
      break;
      case 2:
        openg= 2;
      break;
      case 3:
        openg= 3;
      break;
      case 4:
        openg= 4;
      break;
      case 5:
        openg= 5;
      break;
      case 6:
        openg= 6;
      break;
      case 7:
        openg= 7;
      break;
      default:
        break;
    }
  }
  obtenerChekeadoTotal(){
		let listCheck=[];
		let x = document.getElementsByClassName('check');
		
		
		for(let i=0;i<x.length;i++){
				if(x[i].checked){
						listCheck.push(x[i].id);
				}
		}
		for(let x=0;x<document.getElementsByClassName('check').length;x++){
				document.getElementsByClassName('check')[x].checked=0;
		}
		return listCheck;
  }
  obtenerChekeado(){
   
    let x = document.getElementsByClassName('checkConductor'); 
    this.setState({checkConductor:x[0].checked});
  }
  
  componentDidUpdate(){
    this.state.mostrarVehiculo(ar);
  }
  render() {
      let gro2 = this.state.groupp;
      let tex = this.state.txt;
    return (
        <div>       
        <div id='grid' className='grid' key={'grid'} style={{height:this.state.alturaGrid,width:'280px' ,overflowY: 'scroll',overflowX:'hidden',borderRight:'1px solid grey',boxShadow: '3px 0px 2px grey'}} >
        <div id='find' className='grid' style={{background:'#1F69AD', color:'white', position:'absolute',width:'263px',paddingTop:'5px'}}><b style={{marginLeft:'5px'}}>Buscar:</b><input id='txtFind' type='Text'  style={{marginLeft:'2px'}} onChange={(e)=>{this.setState({txt:(e.target.value).toUpperCase()});this.find((e.target.value).toUpperCase());this.opengrilla(); tex=e.target.value;console.log(this.state.txt)}}/><div style={{display:'flex',justifyContent:'spaceBetween'}}><div><input type='checkbox' className='checkTodos' style={{marginLeft:'5px'}} defaultChecked onClick={()=>{this.state.clearMarkerForTodos(1,this.state.groupp)}}/>Check</div><div><input type='checkbox' className='checkConductor' style={{marginLeft:'5px'}} onClick={()=>{this.obtenerChekeado()}}/>Conductor</div></div>  </div>
            <div style={{height:'50px'}}>.</div>
           {this.state.checkConductor&&this.state.txt===''&&Object.keys(this.state.groupp).map(g =>(<Group changeEnviarComandoShow={this.state.changeEnviarComandoShow} changePlacaHistorico={this.state.changePlacaHistorico} changeHistoricoShow={this.state.changeHistoricoShow} changeZoomMap={this.state.changeZoomMap} centrar={this.state.centrar}  google={this.state.google} clearMarkerForGroup={this.state.clearMarkerForGroup}
              clearMarkerForSubGroup={this.state.clearMarkerForSubGroup}  group1={g} groupp={gro2[g]} o={this.state.o} oPlaca={this.state.oPlaca} f={this.state.fObtenerVTikeado} u={this.state.fClearMArker} txt={this.state.txt} checkConductor={this.state.checkConductor}
              />))}
           {!this.state.checkConductor&&this.state.txt===''&&Object.keys(this.state.groupp).map(g =>(<Group changeEnviarComandoShow={this.state.changeEnviarComandoShow} changePlacaHistorico={this.state.changePlacaHistorico} changeHistoricoShow={this.state.changeHistoricoShow} changeZoomMap={this.state.changeZoomMap} centrar={this.state.centrar}  google={this.state.google} clearMarkerForGroup={this.state.clearMarkerForGroup}
               clearMarkerForSubGroup={this.state.clearMarkerForSubGroup}  group1={g} groupp={gro2[g]} o={this.state.o} oPlaca={this.state.oPlaca} f={this.state.fObtenerVTikeado} u={this.state.fClearMArker} txt={this.state.txt} checkConductor={this.state.checkConductor}
              />))}
         {((this.state.txt!=='') &&(openg===1))&&this.insertarPlaca(ar)}
         {((this.state.txt!=='') &&(openg===2))&&this.insertarPlaca(ar)}
         {((this.state.txt!=='') &&(openg===3))&&this.insertarPlaca(ar)}
         {((this.state.txt!=='') &&(openg===4))&&this.insertarPlaca(ar)}
         {((this.state.txt!=='') &&(openg===5))&&this.insertarPlaca(ar)}
         {((this.state.txt!=='') &&(openg===6))&&this.insertarPlaca(ar)}
         {((this.state.txt!=='') &&(openg===7))&&this.insertarPlaca(ar)}
         
        </div>
        </div>                 
    )
}
}
export default OGrid;