import $ from 'jquery';
import {url} from '../ParametroConexion/parametroConexion';
/*export function ObtenerGeocera(that,idEmpresa){
    $.ajax({
        url: 'http://127.0.0.1:2525/datos',
        type: "POST",
        data: {
                data_op:idEmpresa,
                op:'READ-GEOCERCA'
        },
        success: function(result)            
		   {

            var array = JSON.parse( result );
            var istrue = false;
            result= array['data'];
            
            if(result!=null){
                istrue=true;
                console.log(result);
                console.log('entro a no es null');
            }
            else{
               istrue=false;
            }
            that.setState({geoCercas:result});
            
            }
    
    }).done(function(result){
        console.log('ajax_GeoCerca');
       console.log(result);
 
    })
}*/
export function ObtenerGeocera(that,idEmpresa){
    $.ajax({
        //url: 'http://127.0.0.1:2525/datos',
        url: url,
        type: "POST",
        data: {
                data_op:idEmpresa,
                op:'READ-GEOCERCA'
        },
        success: function(result)            
           {
            let obj =  $.parseJSON(result);          
            //console.log(obj);
            //console.log(obj[0]);
            that.setState({geoCercas:obj});
            that.verGeocerca();    
            }
    
    }).done(function(result){

       //console.log(result);
       
       
    })

}