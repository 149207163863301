import React, { Component } from 'react';
import Grid from './grid';
import KardexGrid from './kardexGrid';

class GridRastreo extends Component {
    constructor(props){
      super(props);
      this.state={
        placa:'',
        nextPlaca:'',
        group2:{},
        oList:{},
        google:this.props.google,
        changePlacaHistorico:this.props.changePlacaHistorico,
        changePlacaSensores:this.props.changePlacaSensores,
        changeAcumAsignado:this.props.changeAcumAsignado,
        changeAcumsJson:this.props.changeAcumsJson,
        changeHistoricoShow:this.props.changeHistoricoShow,
        changeEnviarComandoShow:this.props.changeEnviarComandoShow,
        oVehiculo: this.props.v,
        fObtenerVTikeado: this.props.f,
        openInfo:false,
        alturaGrid:'100vh',
        nro:this.props.nro,
        class:this.props.classG,
        punto:this.props.punto,
        mostrarVehiculo:this.props.mostrarVehiculo,
        clearMarkerForTodos:this.props.clearMarkerForTodos,
        clearMarkerForGroup:this.props.clearMarkerForGroup,
        clearMarkerForSubGroup:this.props.clearMarkerForSubGroup,
        changeZoomMap:this.props.changeZoomMap,
        iniciarElem:this.props.iniciarElem,
        centrar:this.props.centrar,
        changeShowSensores:this.props.changeShowSensores,
        changeDirLiteral:this.props.changeDirLiteral,
        obtenerPlaca:this.props.obtenerPlaca,
        anchura:this.props.anchura,
        class:'App',
        changeAnchoMapa:this.props.changeAnchoMapa,
        fClearMArker: this.props.u
      }
      this.obtenerPlaca = this.obtenerPlaca.bind(this);
      this.changeAlturaGrid = this.changeAlturaGrid.bind(this);
      this.changeAnchura = this.changeAnchura.bind(this);
      
    }
    componentWillReceiveProps(nextProps){
      if(this.state.anchura!==nextProps.anchura){
        this.setState({anchura:nextProps.anchura});
      }
      if(this.state.class!==nextProps.classG){
        this.setState({class:nextProps.classG});
      }
      if((this.state.oVehiculo!==nextProps.v)&&(nextProps.v!==undefined)){
      this.setState({oVehiculo:nextProps.v});
      this.setState({nro:nextProps.nro});
     
      this.crearObjetogrilla(this.state.oVehiculo);
      this.setState({alturaGrid:nextProps.alturaGrid});
      if(nextProps.placa!==''&&nextProps.placa!==undefined){  
        this.setState({placa:nextProps.placa});
      }
      
    }
    }
    changeAnchura(){
      this.setState({anchura:'0px'});
      this.setState({class:'App-hidden'});
    }
    crearObjetogrilla(obj){
      let grilla={};
      let grillagrupo={};
      let consolevehiculo=[];
      for(let i =0;i<Object.keys(obj).length;i++){       
        if(grillagrupo[obj[i].grupo]===undefined){
          grillagrupo[obj[i].grupo]=[];
        }
        if(grilla[obj[i].grupo+obj[i].subgrupo]===undefined){
         grilla[obj[i].grupo+obj[i].subgrupo]=[];
         grilla[obj[i].grupo+obj[i].subgrupo].push(obj[i].nombre);
        }else{
         grilla[obj[i].grupo+obj[i].subgrupo].push(obj[i].nombre);
        }
        grillagrupo[obj[i].grupo][obj[i].subgrupo]=(grilla[obj[i].grupo+obj[i].subgrupo]);
        consolevehiculo.push(obj[i].nombre);
      }
      this.setState({group2:grillagrupo});
      this.obtenerCoordenadas(obj);
    }
    
    crearObjetogrilla2(obj){
      let grilla={};
      console.log(obj);
      for(let i =0;i<Object.keys(obj).length;i++){
        if(grilla[obj[i].subgrupo]===undefined){
         grilla[obj[i].subgrupo]=[];
         grilla[obj[i].subgrupo].push(obj[i].nombre);
        }else{
         grilla[obj[i].subgrupo].push(obj[i].nombre);
        }
      }
      this.setState({group2:grilla});
      this.obtenerCoordenadas(obj);
      console.log(grilla);
    }
    changeAlturaGrid(){
      this.setState({alturaGrid:'100vh'});
      this.setState({placa:''});
      console.log('ejecuta');
    }
    obtenerInputs(obj){
      let inputs = JSON.parse(obj);
      inputs = inputs['input'];
      return inputs
    }
    obtenerCoordenadas(obj){
      let list=[];
      for(let i =0;i<Object.keys(obj).length;i++){
        if(list[obj[i].nombre]===undefined){
          list[obj[i].nombre]={};
          list[obj[i].nombre].placa = obj[i].placa;
          list[obj[i].nombre].ID_EQUIPO = obj[i].ID_EQUIPO;
          list[obj[i].nombre].LATITUD = obj[i].LATITUD;
          list[obj[i].nombre].LONGITUD = obj[i].LONGITUD;
          list[obj[i].nombre].I_ENCENDIDO = obj[i].I_ENCENDIDO;
          list[obj[i].nombre].VELOCIDAD = obj[i].VELOCIDAD;
          list[obj[i].nombre].satelites = obj[i].satelites;
          list[obj[i].nombre].ACUM_ODOMETRO = obj[i].ACUM_ODOMETRO;
          list[obj[i].nombre].ACUM_RALENTI = obj[i].ACUM_RALENTI;
          list[obj[i].nombre].ACUM_ENCENDIDO = obj[i].ACUM_ENCENDIDO;
          list[obj[i].nombre].FECHAHORA_EVENTO = obj[i].FECHAHORA_EVENTO;
          list[obj[i].nombre].CALIDAD_GPS = obj[i].CALIDAD_GPS;
          list[obj[i].nombre].CALIDAD_GPRS = obj[i].CALIDAD_GPS;
          list[obj[i].nombre].ID_CONDUCTOR = obj[i].ID_CONDUCTOR;
          list[obj[i].nombre].corte = obj[i].corte;
          list[obj[i].nombre].VOLTAJE_BATERIA = obj[i].VOLTAJE_BATERIA;
          list[obj[i].nombre].FECHAHORA_EVENTO = obj[i].FECHAHORA_EVENTO;
          list[obj[i].nombre].ORIENT = obj[i].ORIENT;
          list[obj[i].nombre].ACUMS = obj[i].ACUMS;
          list[obj[i].nombre].INPUTS =this.obtenerInputs(obj[i].metadata);
          list[obj[i].nombre].grupo = obj[i].grupo;
        }else{ 
          list[obj[i].nombre].placa = obj[i].placa;
          list[obj[i].nombre].ID_EQUIPO = obj[i].ID_EQUIPO;
          list[obj[i].nombre].LATITUD = obj[i].LATITUD;
          list[obj[i].nombre].LONGITUD = obj[i].LONGITUD;
          list[obj[i].nombre].I_ENCENDIDO = obj[i].I_ENCENDIDO;
          list[obj[i].nombre].VELOCIDAD = obj[i].VELOCIDAD;
          list[obj[i].nombre].satelites = obj[i].satelites;
          list[obj[i].nombre].ACUM_ODOMETRO = obj[i].ACUM_ODOMETRO;
          list[obj[i].nombre].ACUM_RALENTI = obj[i].ACUM_RALENTI;
          list[obj[i].nombre].ACUM_ENCENDIDO = obj[i].ACUM_ENCENDIDO;
          list[obj[i].nombre].FECHAHORA_EVENTO = obj[i].FECHAHORA_EVENTO;
          list[obj[i].nombre].CALIDAD_GPS = obj[i].CALIDAD_GPS;
          list[obj[i].nombre].CALIDAD_GPRS = obj[i].CALIDAD_GPS;
          list[obj[i].nombre].ID_CONDUCTOR = obj[i].ID_CONDUCTOR;
          list[obj[i].nombre].corte = obj[i].corte;
          list[obj[i].nombre].VOLTAJE_BATERIA = obj[i].VOLTAJE_BATERIA;
          list[obj[i].nombre].ORIENT = obj[i].ORIENT;
          list[obj[i].nombre].ACUMS = obj[i].ACUMS;
          list[obj[i].nombre].INPUTS =this.obtenerInputs(obj[i].metadata);
          list[obj[i].nombre].grupo = obj[i].grupo;
        }
        if(obj[i].corte===1){
          list[obj[i].nombre].LATITUD = 0;
          list[obj[i].nombre].LONGITUD = 0;
          list[obj[i].nombre].I_ENCENDIDO = 0;
          list[obj[i].nombre].VELOCIDAD = 0;
          list[obj[i].nombre].satelites = obj[i].satelites;
          list[obj[i].nombre].ACUM_ODOMETRO = 0;
          list[obj[i].nombre].ACUM_RALENTI = 0;
          list[obj[i].nombre].ACUM_ENCENDIDO = 0;
          list[obj[i].nombre].FECHAHORA_EVENTO = obj[i].FECHAHORA_EVENTO;
          list[obj[i].nombre].CALIDAD_GPS = obj[i].CALIDAD_GPS;
          list[obj[i].nombre].CALIDAD_GPRS = obj[i].CALIDAD_GPS;
          list[obj[i].nombre].ID_CONDUCTOR = 0;
          list[obj[i].nombre].VOLTAJE_BATERIA = 0;
          list[obj[i].nombre].ORIENT = 0;
          list[obj[i].nombre].ACUMS = 0;
          list[obj[i].nombre].INPUTS =0;
          list[obj[i].nombre].grupo = obj[i].grupo;
        }
      }
      this.setState({oList:list});           
    }
    obtenerPlaca(p){ 
      let pl = p;
      this.setState({placa:pl.toString()});
      this.setState({alturaGrid:'54vh'});
      console.log('obtenerPlaca');
     }
    cambiarOpenInfo(){
      if(this.state.openInfo){
        this.setState({openInfo:false});
      }
      else{
        this.setState({openInfo:true});
      }
    }
    componentWillMount(){
      this.crearObjetogrilla(this.state.oVehiculo);
    }
    
    render() {
      console.log(this.state.anchura);
      console.log(this.state.changeAcumAsignado);
      return (
        <div className={this.state.class} id="GridRastreo" style={{height:'100vh', width:this.state.anchura, backgroundColor:'white',borderLeft:'2px solid white'}}>
          <div>
            {<Grid changeEnviarComandoShow={this.state.changeEnviarComandoShow} changePlacaHistorico={this.state.changePlacaHistorico} changeHistoricoShow={this.state.changeHistoricoShow} changeZoomMap={this.state.changeZoomMap}	mostrarVehiculo={this.props.mostrarVehiculo} centrar={this.state.centrar}  google={this.state.google} clearMarkerForTodos={this.state.clearMarkerForTodos} clearMarkerForSubGroup={this.state.clearMarkerForSubGroup} clearMarkerForGroup={this.state.clearMarkerForGroup} alturaGrid={this.state.alturaGrid} groupp={this.state.group2} oPlaca={this.obtenerPlaca} o={this.state.oList} f={this.state.fObtenerVTikeado} u={this.state.fClearMArker} placaO={this.state.placa} changeAnchura={this.changeAnchura} changeAnchoMapa={this.state.changeAnchoMapa}/>}
            {this.state.placa!=''&&<div style={{height:'auto'}}>{<KardexGrid changeAcumAsignado={this.state.changeAcumAsignado} changeAcumsJson={this.state.changeAcumsJson} changePlacaSensores={this.state.changePlacaSensores} changeShowSensores={this.state.changeShowSensores} changeDirLiteral={this.changeDirLiteral} changeAlturaGrid={this.changeAlturaGrid} centrar={this.state.centrar} nro={this.state.nro} punto={this.state.punto} google={this.state.google} placa={this.state.placa} o={this.state.oList[this.state.placa]} op ={this.changeAlturaGrid}/>}</div>}
          </div>
        </div>
      );
    }
  }
  
  export default GridRastreo;