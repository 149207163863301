export let DataTipoEvento=[
    {
        value:'Apagado',label:'Apagado'
    },
    {
        value:'Detencion Prolongada',label:'Detencion Prolongada'
    },
    {
        value:'Exceso Vel. Personalizada',label:'Exceso Vel. Personalizada'
    },
    {
        value:'Eventos General',label:'Eventos General'
    }
    /*{
        value:'',label:''
    },
    {
        value:'',label:''
    },*/
]