import $ from 'jquery';
import {obtenerTipoEvento} from './tipoEvento'
import {url} from '../ParametroConexion/parametroConexion';
export let objDatos ;        
export function obtenerPlacas(objDatos,changeOptionPlaca){
    $.ajax({
        //url: 'http://127.0.0.1:2525/datos',
        url: url,
        type: "POST",
        data: {
                data_op:objDatos,
                op:'READ-OBTENERPLACAS'
        },
        success: function(result)            
		   {
            var array = JSON.parse( result );
            result= array['data'];
            console.log(result);
            let vector2=[];
            let vector={id:'',nombre:''};
            vector['value']=0;
            vector['label']='elije opcion';
            for(let i=0;i< Object.keys(result).length;i++){
                vector={};
                vector['value']=result[i].placa;
                vector['label']=result[i].nombre;
                vector2.push(vector);
            }  
            changeOptionPlaca(vector2);
           
            }
    
    }).done(function(result){
       
       
    })

}

export function mostrarHistorico(that,placa,FechaIni,FechaFin,HoraIni,HoraFin){
    $.ajax({
        //url: 'http://127.0.0.1:2525/datos',
        url: url,
        type: "POST",
        data: {
                data_op:{placa,FechaIni,FechaFin,HoraIni,HoraFin},
                op:'READ-HISTORICO'
        },
        success: function(result)            
		   {
            var array = JSON.parse( result );
            result= array['data'];
            that.setState({historico:result}); 
            that.mostrarH();
            }
    
    }).done(function(result){
       
       
    })
}

export function mostrarHistoricoConEvento(that,objHistorico,changeRowData,changeHistoricoConEvento,mostrarH,mostrarMArker){
    //console.log(HoraIni);
    //console.log(HoraFin);
    $.ajax({
        //url: 'http://127.0.0.1:2525/datos',
        url: url,
        type: "POST",
        data: {
                data_op:objHistorico,
                op:'READ-HISTORICOCONEVENTO'
        },
        success: function(result)            
		   {
            var array = JSON.parse( result );
            result= array['data'];
            let re =[];
            re= result.map(h=>{h.tipoEvento=obtenerTipoEvento(h.tipoEvento);h.rgba=that.random_rgba(); return h});
            console.log(re);
            changeRowData(re);
            //that.setState({rowData:re});
           let tipo = objHistorico.tipo;
            $.when(changeHistoricoConEvento(re)).then(mostrarH(re,tipo)).then(()=>{mostrarMArker(re);that.obtenerContarEventos(re);if(tipo!=='porviajesvelocidad'&&tipo!=='simple'&&tipo!=='porviaje'){that.obtenerTotalKm(re)}});
            
            }
    
    }).done(function(result){
       

       
       
    })
}





