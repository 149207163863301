import React, { Component } from 'react';
import Placa from "./placa"
import SubGroup from "./SubGroup"
import './style.css';
import icono from './icono.png'
class Group extends Component {
    constructor(props){
      super(props);
      this.state={
      placa:'',
      isHire:true,
      group1:this.props.group1,
      groupp:this.props.groupp,
      fObtenerVTikeado:this.props.f,
      clearMarkerForGroup:this.props.clearMarkerForGroup,
      clearMarkerForSubGroup:this.props.clearMarkerForSubGroup,
      changeZoomMap:this.props.changeZoomMap,
      changeHistoricoShow:this.props.changeHistoricoShow,
      changePlacaHistorico:this.props.changePlacaHistorico,
      changeEnviarComandoShow:this.props.changeEnviarComandoShow,
      google:this.props.google,
      fClearMArker: this.props.u,
      o:this.props.o,
      txt: this.props.txt,
      totalVehiculoGrupo:0,
      centrar:this.props.centrar,
      checkConductor:this.props.checkConductor,
      oPlaca:this.props.oPlaca
      }
      this.handleClick = this.handleClick.bind(this); 
    }
  componentWillReceiveProps(nextProps){
      this.setState({o:nextProps.o});
  } 
  descheckGroup(){
    
  }
  handleClick(){
    if(this.state.isHire){
        this.setState({isHire:false});
    }
    else{
        this.setState({isHire:true});
    }
  }
  obtenerTotalVehiculoGrupo(){
    let sum =0;
    for(let i=0;i<Object.keys(this.state.groupp).length;i++){
      sum+=this.state.groupp[Object.keys(this.state.groupp)[i]].length;
    }
    this.setState({totalVehiculoGrupo:sum});
    //return sum;
  }
  componentDidMount(){
    //console.log(this.state.groupp);
    this.obtenerTotalVehiculoGrupo();
  }
  render() {
    let gro2 = this.state.groupp;
    return (
    <div id={"div"+this.state.group1} className='group'  key={"div"+this.state.group1} >   
    <div key={'d'+this.state.group1} className='group'  style={{borderTop:'1px solid Lavender',background:'white'}}><div style={{marginLeft:'5px',marginBottom:'0px',fontSize:'12px',fontWeight:'bold',color:'#2E2D2D',display:'flex',justifyContent:'spaceBetween'}} ><div><input type="checkbox" id={this.state.placa} className={'checkGrilla group '+this.state.group1.replace(/ /g, "")} defaultChecked onClick={()=>{this.state.clearMarkerForGroup(1,this.state.group1,this.state.groupp)}}/></div><div onClick={()=>{this.handleClick()}}>{this.state.group1+" "+"("+this.state.totalVehiculoGrupo+")"}</div></div></div>
    {this.state.isHire &&
    <div key={"div2"+this.state.group1}>
    <div style={{borderRight:'1px solid white',background: 'Lavender',display:'flex',justifyContent:'spaceBetween'}}>
    </div> 
    <div className="row" style={{display:'block', marginLeft:'1px'}}>
    {this.state.txt===''&&Object.keys(this.state.groupp).map(g =>(
      <SubGroup changeEnviarComandoShow={this.state.changeEnviarComandoShow} changePlacaHistorico={this.state.changePlacaHistorico} changeHistoricoShow={this.state.changeHistoricoShow} changeZoomMap={this.state.changeZoomMap} centrar={this.state.centrar} google={this.state.google} clearMarkerForSubGroup={this.state.clearMarkerForSubGroup} grupoName={this.state.group1} checkConductor={this.state.checkConductor} group1={g} groupp={gro2[g]} o={this.state.o} oPlaca={this.state.oPlaca} f={this.state.fObtenerVTikeado} u={this.state.fClearMArker}  />))
    }
    </div>
    </div>
    }
    </div>
    )          
}
}
export default Group;
