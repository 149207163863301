import React, { Component } from 'react';
import GridRastreo from './Cgrid/gridRastreo';
import {ObtenerVehiculo,ObtenerMapaSenal, ObtenerActivos} from './Ajax_rastreo';
import {ObtenerConductor} from './Ajax_ConductorInfoWindow';
import {ObtenerAcumAsignado} from './Cgrid/ajax_obtenerAcumAsignado';
import Sensores from './Cgrid/Sensores';
import Draggable from 'react-draggable';
import VentanaKardex from './Cgrid/ventanaKardex';
import KardexGrid from './kardexGrid';
import {ObtenerGeocera} from './Ajax_ObtenerGeoCerca';
import {url} from '../ParametroConexion/parametroConexion';
import menu from "./menu.svg";
//import {MarkerClusterer} from './MarkerClusterer';

import camionetaNN from './camionetaNN.png';
import camionetaSS from './camionetaSS.png';
import camionetaNE from './camionetaNE.png';
import camionetaNW from './camionetaNW.png';
import camionetaSE from './camionetaSE.png';
import camionetaSW from './camionetaSW.png';
import camionetaEE from './camionetaEE.png';
import camionetaWW from './camionetaWW.png';

import camionetaOffNN from './camionetaOffNN.png';
import camionetaOffSS from './camionetaOffSS.png';
import camionetaOffNE from './camionetaOffNE.png';
import camionetaOffNW from './camionetaOffNW.png';
import camionetaOffSE from './camionetaOffSE.png';
import camionetaOffSW from './camionetaOffSW.png';
import camionetaOffEE from './camionetaOffEE.png';
import camionetaOffWW from './camionetaOffWW.png';

import camionetaOnNN from './camionetaOnNN.png';
import camionetaOnSS from './camionetaOnSS.png';
import camionetaOnNE from './camionetaOnNE.png';
import camionetaOnNW from './camionetaOnNW.png';
import camionetaOnSE from './camionetaOnSE.png';
import camionetaOnSW from './camionetaOnSW.png';
import camionetaOnEE from './camionetaOnEE.png';
import camionetaOnWW from './camionetaOnWW.png';

import camionetaReNN from './camionetaReNN.png';
import camionetaReSS from './camionetaReSS.png';
import camionetaReNE from './camionetaReNE.png';
import camionetaReNW from './camionetaReNW.png';
import camionetaReSE from './camionetaReSE.png';
import camionetaReSW from './camionetaReSW.png';
import camionetaReEE from './camionetaReEE.png';
import camionetaReWW from './camionetaReWW.png';

import tractorNN from './tractorNN.png';
import tractorSS from './tractorSS.png';
import tractorNE from './tractorNE.png';
import tractorNW from './tractorNW.png';
import tractorSE from './tractorSE.png';
import tractorSW from './tractorSW.png';
import tractorEE from './tractorEE.png';
import tractorWW from './tractorWW.png';

import tractorOffNN from './tractorOffNN.png';
import tractorOffSS from './tractorOffSS.png';
import tractorOffNE from './tractorOffNE.png';
import tractorOffNW from './tractorOffNW.png';
import tractorOffSE from './tractorOffSE.png';
import tractorOffSW from './tractorOffSW.png';
import tractorOffEE from './tractorOffEE.png';
import tractorOffWW from './tractorOffWW.png';

import tractorOnNN from './tractorOnNN.png';
import tractorOnSS from './tractorOnSS.png';
import tractorOnNE from './tractorOnNE.png';
import tractorOnNW from './tractorOnNW.png';
import tractorOnSE from './tractorOnSE.png';
import tractorOnSW from './tractorOnSW.png';
import tractorOnEE from './tractorOnEE.png';
import tractorOnWW from './tractorOnWW.png';

import tractorReNN from './tractorReNN.png';
import tractorReSS from './tractorReSS.png';
import tractorReNE from './tractorReNE.png';
import tractorReNW from './tractorReNW.png';
import tractorReSE from './tractorReSE.png';
import tractorReSW from './tractorReSW.png';
import tractorReEE from './tractorReEE.png';
import tractorReWW from './tractorReWW.png';

import camionchicoNN from './camionchicoNN.png';
import camionchicoSS from './camionchicoSS.png';
import camionchicoNE from './camionchicoNE.png';
import camionchicoNW from './camionchicoNW.png';
import camionchicoSE from './camionchicoSE.png';
import camionchicoSW from './camionchicoSW.png';
import camionchicoEE from './camionchicoEE.png';
import camionchicoWW from './camionchicoWW.png';

import camionchicoOffNN from './camionchicoOffNN.png';
import camionchicoOffSS from './camionchicoOffSS.png';
import camionchicoOffNE from './camionchicoOffNE.png';
import camionchicoOffNW from './camionchicoOffNW.png';
import camionchicoOffSE from './camionchicoOffSE.png';
import camionchicoOffSW from './camionchicoOffSW.png';
import camionchicoOffEE from './camionchicoOffEE.png';
import camionchicoOffWW from './camionchicoOffWW.png';

import camionchicoOnNN from './camionchicoOnNN.png';
import camionchicoOnSS from './camionchicoOnSS.png';
import camionchicoOnNE from './camionchicoOnNE.png';
import camionchicoOnNW from './camionchicoOnNW.png';
import camionchicoOnSE from './camionchicoOnSE.png';
import camionchicoOnSW from './camionchicoOnSW.png';
import camionchicoOnEE from './camionchicoOnEE.png';
import camionchicoOnWW from './camionchicoOnWW.png';

import camionchicoReNN from './camionchicoReNN.png';
import camionchicoReSS from './camionchicoReSS.png';
import camionchicoReNE from './camionchicoReNE.png';
import camionchicoReNW from './camionchicoReNW.png';
import camionchicoReSE from './camionchicoReSE.png';
import camionchicoReSW from './camionchicoReSW.png';
import camionchicoReEE from './camionchicoReEE.png';
import camionchicoReWW from './camionchicoReWW.png';

import autoNN from './autoNN.png';
import autoSS from './autoSS.png';
import autoNE from './autoNE.png';
import autoNW from './autoNW.png';
import autoSE from './autoSE.png';
import autoSW from './autoSW.png';
import autoEE from './autoEE.png';
import autoWW from './autoWW.png';

import autoOffNN from './autoOffNN.png';
import autoOffSS from './autoOffSS.png';
import autoOffNE from './autoOffNE.png';
import autoOffNW from './autoOffNW.png';
import autoOffSE from './autoOffSE.png';
import autoOffSW from './autoOffSW.png';
import autoOffEE from './autoOffEE.png';
import autoOffWW from './autoOffWW.png';

import autoOnNN from './autoOnNN.png';
import autoOnSS from './autoOnSS.png';
import autoOnNE from './autoOnNE.png';
import autoOnNW from './autoOnNW.png';
import autoOnSE from './autoOnSE.png';
import autoOnSW from './autoOnSW.png';
import autoOnEE from './autoOnEE.png';
import autoOnWW from './autoOnWW.png';

import autoReNN from './autoReNN.png';
import autoReSS from './autoReSS.png';
import autoReNE from './autoReNE.png';
import autoReNW from './autoReNW.png';
import autoReSE from './autoReSE.png';
import autoReSW from './autoReSW.png';
import autoReEE from './autoReEE.png';
import autoReWW from './autoReWW.png';

import camionOnWW from './camionOnWW.png';
import camionOnNN from './camionOnNN.png';
import camionOnSS from './camionOnSS.png';
import camionOnNE from './camionOnNE.png';
import camionOnNW from './camionOnNW.png';
import camionOnSE from './camionOnSE.png';
import camionOnSW from './camionOnSW.png';
import camionOnEE from './camionOnEE.png';

import camionReWW from './camionReWW.png';
import camionReNN from './camionReNN.png';
import camionReSS from './camionReSS.png';
import camionReNE from './camionReNE.png';
import camionReNW from './camionReNW.png';
import camionReSE from './camionReSE.png';
import camionReSW from './camionReSW.png';
import camionReEE from './camionReEE.png';

import camionOffWW from './camionOffWW.png';
import camionOffNN from './camionOffNN.png';
import camionOffSS from './camionOffSS.png';
import camionOffNE from './camionOffNE.png';
import camionOffNW from './camionOffNW.png';
import camionOffSE from './camionOffSE.png';
import camionOffSW from './camionOffSW.png';
import camionOffEE from './camionOffEE.png';

import camionWW from './camionWW.png';
import camionNN from './camionNN.png';
import camionSS from './camionSS.png';
import camionNE from './camionNE.png';
import camionNW from './camionNW.png';
import camionSE from './camionSE.png';
import camionSW from './camionSW.png';
import camionEE from './camionEE.png';
import vistaMotor from './VistaMotor.svg';
import geocercaV from './geocerca.svg';

import arrow from './upload.png';
import './rastreo.css';
import './label.css';
import makeMarkerWithLabel from 'markerwithlabel'
import { ObtenerPlanViajePlaca } from './Cgrid/Ajax_ObtenerConductor';

var google = window.google;
console.log(google);
//var geocoder = new google.maps.Geocoder;
var MarkerWithLabel =  makeMarkerWithLabel(google.maps);
let v=[];
let listEventoR;
let poli=[];
let miPoligono=[];
let infoGeocerca=[];
let poli2;
let miPoligono2;
let h = false;
let dirLiteral={};
let conductorPopUpd='';
var directionsRequest = {
  origin: '',
  destination: '',
  travelMode: google.maps.DirectionsTravelMode.DRIVING,
  unitSystem: google.maps.UnitSystem.METRIC
};


function random_rgba() {
	var o = Math.round, r = Math.random, s = 255;
	return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + 1 + ')';
}
function consoleTest(){
	ObtenerPlanViajePlaca()
	console.log('yeah2');
}
window.consoleTest = consoleTest;
class Rastreo extends Component {	
	constructor(props) {
		super(props);	
		this.map1 = undefined;
		this.markerRastreo=[];
		this.markerMapaSenal=[];
		this.markerInfoRastreo=[];
		this.markerGeocerca=[];
		//this.markerCluster;
		this.OnInterval=false;
		this.state = {
			conductor:{
				nombre:'',
				apellidoPaterno:'',
				nroCelPersonal:''
			},
			statePrincipal:this.props.statePrincipal,
			changeHistoricoShow:this.props.changeHistoricoShow,
			changePlacaHistorico:this.props.changePlacaHistorico,
			changeEnviarComandoShow:this.props.changeEnviarComandoShow,
			activeDrags:0,
			placa:'',
			anchura:'80vw',
			dirLiteral:'',
			acumAsignado:[],
      		acumsJson:[],
			nro:'',
			nroTemp:null,
			logueo:this.props.logueo,
			punto:{},
			punto2:[],
			puntoMapaSenal:{},
			activos:{},
			timerID1:'',
			timerID2:'',
			id:8,
			class:'App',
			markerVacio:true,
			detalleVisible: false,
			lastUpdateTimestamp: new Date().getTime(),
			refreshTimerID: 0,
			verVentana:false,
			eventos:'',
			isArrow:false,
			popupRealTime:{},
			showSensores:false,
			anchoMapa:'0%',
			anchuraBoton:'30vw',
			flagKardexGridMinimizado:false,
			eventoSeleccionado: null,
			placaSensores:'',
			flagBotonMinimizado:false,
			geoCercas:[]
		}
		this.consolePrueba = this.consolePrueba.bind(this);
		this.changePunto = this.changePunto.bind(this);
		this.mostrarVehiculos=this.mostrarVehiculos.bind(this);
		this.mostrarVehiculo=this.mostrarVehiculo.bind(this);
		this.centrar=this.centrar.bind(this);
		this.clearMarkers=this.clearMarkers.bind(this);
		this.clearMarkerForSubGroup=this.clearMarkerForSubGroup.bind(this);
		this.clearMarkerForSubGroup2=this.clearMarkerForSubGroup2.bind(this);
		this.clearMarkerForGroup=this.clearMarkerForGroup.bind(this);
		this.clearMarkerForGroup2=this.clearMarkerForGroup2.bind(this);
		this.clearMarkerForTodos=this.clearMarkerForTodos.bind(this);
		this.clearMarkerForTodos2=this.clearMarkerForTodos2.bind(this);
		this.changeDirLiteral=this.changeDirLiteral.bind(this);
		this.changeAnchoMapa=this.changeAnchoMapa.bind(this);
		this.changeConductor=this.changeConductor.bind(this);
		this.changeShowSensores=this.changeShowSensores.bind(this);
		this.changePlacaSensores=this.changePlacaSensores.bind(this);
		this.changeNroTemp=this.changeNroTemp.bind(this);
		this.changeAcumAsignado=this.changeAcumAsignado.bind(this);
		this.changeAcumsJson=this.changeAcumsJson.bind(this);
		this.changeZoomMap=this.changeZoomMap.bind(this);
	}
	async	changePlacaSensores(placaSensores){
		this.setState({placaSensores})
		console.log('Entro a changeSensores');
	//await	ObtenerAcumAsignado(placaSensores,this.changeAcumAsignado,this.changeNroTemp);
	}
	changeZoomMap(){
		 this.state.map1.setZoom(15);
	}
	changeAcumAsignado(acumAsignado){
		console.log(acumAsignado);
		this.setState({acumAsignado})
		console.log('Entro a ACumAsig');
	}
	changeAcumsJson(acumsJson){
		this.setState({acumsJson});
	}
	changeNroTemp(nroTemp){
		this.setState({nroTemp});
	}
	changeShowSensores(){
		if(this.state.showSensores){
			this.setState({showSensores:false});
		}else{
			this.setState({showSensores:true});
		}
	}
	changeConductor(conductor){
		this.setState({conductor});
	}
	changeAnchoMapa(){
		if(this.state.anchoMapa!=='100vw'){
			this.setState({anchoMapa:'100vw'});
		}else{
			this.setState({anchoMapa:'0%'});
		}
		
	}
	changePunto(punto){
		this.setState({punto});
	}
	changeDirLiteral(dir){
		this.setState({dirLiteral:dir});
	}
	mapInit() {
		var boltracksrl = {lat: -17.759, lng: -63.194};
		this.map1 = new google.maps.Map(
				document.getElementById('mapaContainer'), 
				{
					zoom: 8, 
					center: boltracksrl,
					zoomControl: true,
					mapTypeControl: true,
					mapTypeControlOptions: {
						mapTypeIds: ['hybrid','roadmap','satellite','terrain'],
						position: google.maps.ControlPosition.TOP_RIGHT
					},
					streetViewControl: true,
					fullScreenControl: true,
					scrollwheel: true,
					noClear: true,
				}
			);
		//COMPONER LUEGO	
		/*var centerControlDiv = document.createElement('div');
		var centerControl = new this.geocerca(centerControlDiv, this);
		centerControlDiv.index = 1;
		this.map1.controls[google.maps.ControlPosition.TOP_RIGHT].push(centerControlDiv);

		var centerControlDiv2 = document.createElement('div');
		var centerControl2 = new this.arrow(centerControlDiv2, this);
		centerControlDiv.index = 1;
		this.map1.controls[google.maps.ControlPosition.TOP_RIGHT].push(centerControlDiv2);*/
		this.map1.mapaElems = {};

	}
	obtenerFechaLocal(d){  
		let fechaserver = new Date (d);
		let fechalocal = fechaserver.getTime();
		fechalocal=String(new Date(fechalocal));
		return fechalocal.substring(3,23);
	}
	obtenerIconBd(vH,isA,eEstado,eVelocidad,idIcon){
		let icon = {
			nn:'',
			ne:'',
			ee:'',
			se:'',
			ss:'',
			sw:'',
			ww:'',
			nw:'',
			onnn:'',
			onne:'',
			onee:'',
			onse:'',
			onss:'',
			onsw:'',
			onww:'',
			onnw:'',
			offnn:'',
			offne:'',
			ofee:'',
			offse:'',
			offss:'',
			offsw:'',
			offww:'',
			offnw:'',
			renn:'',
			rene:'',
			reee:'',
			rese:'',
			ress:'',
			resw:'',
			reww:'',
			renw:'',
		}
		switch(idIcon){
			case 1:
				icon.nn=camionNN;
				icon.ne=camionNE;
				icon.ee=camionEE;
				icon.se=camionSE;
				icon.ss=camionSS;
				icon.sw=camionSW;
				icon.ww=camionWW;
				icon.nw=camionNW;

				icon.onnn=camionOnNN;
				icon.onne=camionOnNE;
				icon.onee=camionOnEE;
				icon.onse=camionOnSE;
				icon.onss=camionOnSS;
				icon.onsw=camionOnSW;
				icon.onww=camionOnWW;
				icon.onnw=camionOnNW;
				
				icon.offnn=camionOffNN;
				icon.offne=camionOffNE;
				icon.offee=camionOffEE;
				icon.offse=camionOffSE;
				icon.offss=camionOffSS;
				icon.offsw=camionOffSW;
				icon.offww=camionOffWW;
				icon.offnw=camionOffNW;

				icon.renn=camionReNN;
				icon.rene=camionReNE;
				icon.reee=camionReEE;
				icon.rese=camionReSE;
				icon.ress=camionReSS;
				icon.resw=camionReSW;
				icon.reww=camionReWW;
				icon.renw=camionReNW;
			break;
			case 2:
					icon.nn=autoNN;
					icon.ne=autoNE;
					icon.ee=autoEE;
					icon.se=autoSE;
					icon.ss=autoSS;
					icon.sw=autoSW;
					icon.ww=autoWW;
					icon.nw=autoNW;

					icon.onnn=autoOnNN;
					icon.onne=autoOnNE;
					icon.onee=autoOnEE;
					icon.onse=autoOnSE;
					icon.onss=autoOnSS;
					icon.onsw=autoOnSW;
					icon.onww=autoOnWW;
					icon.onnw=autoOnNW;
					
					icon.offnn=autoOffNN;
					icon.offne=autoOffNE;
					icon.offee=autoOffEE;
					icon.offse=autoOffSE;
					icon.offss=autoOffSS;
					icon.offsw=autoOffSW;
					icon.offww=autoOffWW;
					icon.offnw=autoOffNW;
	
					icon.renn=autoReNN;
					icon.rene=autoReNE;
					icon.reee=autoReEE;
					icon.rese=autoReSE;
					icon.ress=autoReSS;
					icon.resw=autoReSW;
					icon.reww=autoReWW;
					icon.renw=autoReNW;
			break;
			case 3:
					icon.nn=camionetaNN;
					icon.ne=camionetaNE;
					icon.ee=camionetaEE;
					icon.se=camionetaSE;
					icon.ss=camionetaSS;
					icon.sw=camionetaSW;
					icon.ww=camionetaWW;
					icon.nw=camionetaNW;

					icon.onnn=camionetaOnNN;
					icon.onne=camionetaOnNE;
					icon.onee=camionetaOnEE;
					icon.onse=camionetaOnSE;
					icon.onss=camionetaOnSS;
					icon.onsw=camionetaOnSW;
					icon.onww=camionetaOnWW;
					icon.onnw=camionetaOnNW;
					
					icon.offnn=camionetaOffNN;
					icon.offne=camionetaOffNE;
					icon.offee=camionetaOffEE;
					icon.offse=camionetaOffSE;
					icon.offss=camionetaOffSS;
					icon.offsw=camionetaOffSW;
					icon.offww=camionetaOffWW;
					icon.offnw=camionetaOffNW;
	
					icon.renn=camionetaReNN;
					icon.rene=camionetaReNE;
					icon.reee=camionetaReEE;
					icon.rese=camionetaReSE;
					icon.ress=camionetaReSS;
					icon.resw=camionetaReSW;
					icon.reww=camionetaReWW;
					icon.renw=camionetaReNW;
			break;
			case 4:
					icon.nn=camionchicoNN;
					icon.ne=camionchicoNE;
					icon.ee=camionchicoEE;
					icon.se=camionchicoSE;
					icon.ss=camionchicoSS;
					icon.sw=camionchicoSW;
					icon.ww=camionchicoWW;
					icon.nw=camionchicoNW;

					icon.onnn=camionchicoOnNN;
					icon.onne=camionchicoOnNE;
					icon.onee=camionchicoOnEE;
					icon.onse=camionchicoOnSE;
					icon.onss=camionchicoOnSS;
					icon.onsw=camionchicoOnSW;
					icon.onww=camionchicoOnWW;
					icon.onnw=camionchicoOnNW;
					
					icon.offnn=camionchicoOffNN;
					icon.offne=camionchicoOffNE;
					icon.offee=camionchicoOffEE;
					icon.offse=camionchicoOffSE;
					icon.offss=camionchicoOffSS;
					icon.offsw=camionchicoOffSW;
					icon.offww=camionchicoOffWW;
					icon.offnw=camionchicoOffNW;
	
					icon.renn=camionchicoReNN;
					icon.rene=camionchicoReNE;
					icon.reee=camionchicoReEE;
					icon.rese=camionchicoReSE;
					icon.ress=camionchicoReSS;
					icon.resw=camionchicoReSW;
					icon.reww=camionchicoReWW;
					icon.renw=camionchicoReNW;
			break;
			case 5:
					icon.nn=tractorNN;
					icon.ne=tractorNE;
					icon.ee=tractorEE;
					icon.se=tractorSE;
					icon.ss=tractorSS;
					icon.sw=tractorSW;
					icon.ww=tractorWW;
					icon.nw=tractorNW;

					icon.onnn=tractorOnNN;
					icon.onne=tractorOnNE;
					icon.onee=tractorOnEE;
					icon.onse=tractorOnSE;
					icon.onss=tractorOnSS;
					icon.onsw=tractorOnSW;
					icon.onww=tractorOnWW;
					icon.onnw=tractorOnNW;
					
					icon.offnn=tractorOffNN;
					icon.offne=tractorOffNE;
					icon.offee=tractorOffEE;
					icon.offse=tractorOffSE;
					icon.offss=tractorOffSS;
					icon.offsw=tractorOffSW;
					icon.offww=tractorOffWW;
					icon.offnw=tractorOffNW;
	
					icon.renn=tractorReNN;
					icon.rene=tractorReNE;
					icon.reee=tractorReEE;
					icon.rese=tractorReSE;
					icon.ress=tractorReSS;
					icon.resw=tractorReSW;
					icon.reww=tractorReWW;
					icon.renw=tractorReNW;
			break;
			//off
			case 6:
				icon.nn=camionOffNN;
				icon.ne=camionOffNE;
				icon.ee=camionOffEE;
				icon.se=camionOffSE;
				icon.ss=camionOffSS;
				icon.sw=camionOffSW;
				icon.ww=camionOffWW;
				icon.nw=camionOffNW;

				icon.onnn=camionOnNN;
				icon.onne=camionOnNE;
				icon.onee=camionOnEE;
				icon.onse=camionOnSE;
				icon.onss=camionOnSS;
				icon.onsw=camionOnSW;
				icon.onww=camionOnWW;
				icon.onnw=camionOnNW;
				
				icon.offnn=camionOffNN;
				icon.offne=camionOffNE;
				icon.offee=camionOffEE;
				icon.offse=camionOffSE;
				icon.offss=camionOffSS;
				icon.offsw=camionOffSW;
				icon.offww=camionOffWW;
				icon.offnw=camionOffNW;

				icon.renn=camionReNN;
				icon.rene=camionReNE;
				icon.reee=camionReEE;
				icon.rese=camionReSE;
				icon.ress=camionReSS;
				icon.resw=camionReSW;
				icon.reww=camionReWW;
				icon.renw=camionReNW;
			break;
			case 7:
					icon.nn=autoOffNN;
					icon.ne=autoOffNE;
					icon.ee=autoOffEE;
					icon.se=autoOffSE;
					icon.ss=autoOffSS;
					icon.sw=autoOffSW;
					icon.ww=autoOffWW;
					icon.nw=autoOffNW;

					icon.onnn=autoOnNN;
					icon.onne=autoOnNE;
					icon.onee=autoOnEE;
					icon.onse=autoOnSE;
					icon.onss=autoOnSS;
					icon.onsw=autoOnSW;
					icon.onww=autoOnWW;
					icon.onnw=autoOnNW;
					
					icon.offnn=autoOffNN;
					icon.offne=autoOffNE;
					icon.offee=autoOffEE;
					icon.offse=autoOffSE;
					icon.offss=autoOffSS;
					icon.offsw=autoOffSW;
					icon.offww=autoOffWW;
					icon.offnw=autoOffNW;
	
					icon.renn=autoReNN;
					icon.rene=autoReNE;
					icon.reee=autoReEE;
					icon.rese=autoReSE;
					icon.ress=autoReSS;
					icon.resw=autoReSW;
					icon.reww=autoReWW;
					icon.renw=autoReNW;
			break;
			case 8:
					icon.nn=camionetaOffNN;
					icon.ne=camionetaOffNE;
					icon.ee=camionetaOffEE;
					icon.se=camionetaOffSE;
					icon.ss=camionetaOffSS;
					icon.sw=camionetaOffSW;
					icon.ww=camionetaOffWW;
					icon.nw=camionetaOffNW;

					icon.onnn=camionetaOnNN;
					icon.onne=camionetaOnNE;
					icon.onee=camionetaOnEE;
					icon.onse=camionetaOnSE;
					icon.onss=camionetaOnSS;
					icon.onsw=camionetaOnSW;
					icon.onww=camionetaOnWW;
					icon.onnw=camionetaOnNW;
					
					icon.offnn=camionetaOffNN;
					icon.offne=camionetaOffNE;
					icon.offee=camionetaOffEE;
					icon.offse=camionetaOffSE;
					icon.offss=camionetaOffSS;
					icon.offsw=camionetaOffSW;
					icon.offww=camionetaOffWW;
					icon.offnw=camionetaOffNW;
	
					icon.renn=camionetaReNN;
					icon.rene=camionetaReNE;
					icon.reee=camionetaReEE;
					icon.rese=camionetaReSE;
					icon.ress=camionetaReSS;
					icon.resw=camionetaReSW;
					icon.reww=camionetaReWW;
					icon.renw=camionetaReNW;
			break;
			case 9:
					icon.nn=camionchicoOffNN;
					icon.ne=camionchicoOffNE;
					icon.ee=camionchicoOffEE;
					icon.se=camionchicoOffSE;
					icon.ss=camionchicoOffSS;
					icon.sw=camionchicoOffSW;
					icon.ww=camionchicoOffWW;
					icon.nw=camionchicoOffNW;

					icon.onnn=camionchicoOnNN;
					icon.onne=camionchicoOnNE;
					icon.onee=camionchicoOnEE;
					icon.onse=camionchicoOnSE;
					icon.onss=camionchicoOnSS;
					icon.onsw=camionchicoOnSW;
					icon.onww=camionchicoOnWW;
					icon.onnw=camionchicoOnNW;
					
					icon.offnn=camionchicoOffNN;
					icon.offne=camionchicoOffNE;
					icon.offee=camionchicoOffEE;
					icon.offse=camionchicoOffSE;
					icon.offss=camionchicoOffSS;
					icon.offsw=camionchicoOffSW;
					icon.offww=camionchicoOffWW;
					icon.offnw=camionchicoOffNW;
	
					icon.renn=camionchicoReNN;
					icon.rene=camionchicoReNE;
					icon.reee=camionchicoReEE;
					icon.rese=camionchicoReSE;
					icon.ress=camionchicoReSS;
					icon.resw=camionchicoReSW;
					icon.reww=camionchicoReWW;
					icon.renw=camionchicoReNW;
			break;
			case 10:
					icon.nn=tractorOffNN;
					icon.ne=tractorOffNE;
					icon.ee=tractorOffEE;
					icon.se=tractorOffSE;
					icon.ss=tractorOffSS;
					icon.sw=tractorOffSW;
					icon.ww=tractorOffWW;
					icon.nw=tractorOffNW;

					icon.onnn=tractorOnNN;
					icon.onne=tractorOnNE;
					icon.onee=tractorOnEE;
					icon.onse=tractorOnSE;
					icon.onss=tractorOnSS;
					icon.onsw=tractorOnSW;
					icon.onww=tractorOnWW;
					icon.onnw=tractorOnNW;
					
					icon.offnn=tractorOffNN;
					icon.offne=tractorOffNE;
					icon.offee=tractorOffEE;
					icon.offse=tractorOffSE;
					icon.offss=tractorOffSS;
					icon.offsw=tractorOffSW;
					icon.offww=tractorOffWW;
					icon.offnw=tractorOffNW;
	
					icon.renn=tractorReNN;
					icon.rene=tractorReNE;
					icon.reee=tractorReEE;
					icon.rese=tractorReSE;
					icon.ress=tractorReSS;
					icon.resw=tractorReSW;
					icon.reww=tractorReWW;
					icon.renw=tractorReNW;
			break;
			
			//-------------//
			
			//RE//
			case 11:
					icon.nn=camionReNN;
					icon.ne=camionReNE;
					icon.ee=camionReEE;
					icon.se=camionReSE;
					icon.ss=camionReSS;
					icon.sw=camionReSW;
					icon.ww=camionReWW;
					icon.nw=camionReNW;
	
					icon.onnn=camionOnNN;
					icon.onne=camionOnNE;
					icon.onee=camionOnEE;
					icon.onse=camionOnSE;
					icon.onss=camionOnSS;
					icon.onsw=camionOnSW;
					icon.onww=camionOnWW;
					icon.onnw=camionOnNW;
					
					icon.offnn=camionOffNN;
					icon.offne=camionOffNE;
					icon.offee=camionOffEE;
					icon.offse=camionOffSE;
					icon.offss=camionOffSS;
					icon.offsw=camionOffSW;
					icon.offww=camionOffWW;
					icon.offnw=camionOffNW;
	
					icon.renn=camionReNN;
					icon.rene=camionReNE;
					icon.reee=camionReEE;
					icon.rese=camionReSE;
					icon.ress=camionReSS;
					icon.resw=camionReSW;
					icon.reww=camionReWW;
					icon.renw=camionReNW;
				break;
				case 12:
						icon.nn=autoReNN;
						icon.ne=autoReNE;
						icon.ee=autoReEE;
						icon.se=autoReSE;
						icon.ss=autoReSS;
						icon.sw=autoReSW;
						icon.ww=autoReWW;
						icon.nw=autoReNW;
	
						icon.onnn=autoOnNN;
						icon.onne=autoOnNE;
						icon.onee=autoOnEE;
						icon.onse=autoOnSE;
						icon.onss=autoOnSS;
						icon.onsw=autoOnSW;
						icon.onww=autoOnWW;
						icon.onnw=autoOnNW;
						
						icon.offnn=autoOffNN;
						icon.offne=autoOffNE;
						icon.offee=autoOffEE;
						icon.offse=autoOffSE;
						icon.offss=autoOffSS;
						icon.offsw=autoOffSW;
						icon.offww=autoOffWW;
						icon.offnw=autoOffNW;
		
						icon.renn=autoReNN;
						icon.rene=autoReNE;
						icon.reee=autoReEE;
						icon.rese=autoReSE;
						icon.ress=autoReSS;
						icon.resw=autoReSW;
						icon.reww=autoReWW;
						icon.renw=autoReNW;
				break;
				case 13:
						icon.nn=camionetaReNN;
						icon.ne=camionetaReNE;
						icon.ee=camionetaReEE;
						icon.se=camionetaReSE;
						icon.ss=camionetaReSS;
						icon.sw=camionetaReSW;
						icon.ww=camionetaReWW;
						icon.nw=camionetaReNW;
	
						icon.onnn=camionetaOnNN;
						icon.onne=camionetaOnNE;
						icon.onee=camionetaOnEE;
						icon.onse=camionetaOnSE;
						icon.onss=camionetaOnSS;
						icon.onsw=camionetaOnSW;
						icon.onww=camionetaOnWW;
						icon.onnw=camionetaOnNW;
						
						icon.offnn=camionetaOffNN;
						icon.offne=camionetaOffNE;
						icon.offee=camionetaOffEE;
						icon.offse=camionetaOffSE;
						icon.offss=camionetaOffSS;
						icon.offsw=camionetaOffSW;
						icon.offww=camionetaOffWW;
						icon.offnw=camionetaOffNW;
		
						icon.renn=camionetaReNN;
						icon.rene=camionetaReNE;
						icon.reee=camionetaReEE;
						icon.rese=camionetaReSE;
						icon.ress=camionetaReSS;
						icon.resw=camionetaReSW;
						icon.reww=camionetaReWW;
						icon.renw=camionetaReNW;
				break;
				case 14:
						icon.nn=camionchicoReNN;
						icon.ne=camionchicoReNE;
						icon.ee=camionchicoReEE;
						icon.se=camionchicoReSE;
						icon.ss=camionchicoReSS;
						icon.sw=camionchicoReSW;
						icon.ww=camionchicoReWW;
						icon.nw=camionchicoReNW;
	
						icon.onnn=camionchicoOnNN;
						icon.onne=camionchicoOnNE;
						icon.onee=camionchicoOnEE;
						icon.onse=camionchicoOnSE;
						icon.onss=camionchicoOnSS;
						icon.onsw=camionchicoOnSW;
						icon.onww=camionchicoOnWW;
						icon.onnw=camionchicoOnNW;
						
						icon.offnn=camionchicoOffNN;
						icon.offne=camionchicoOffNE;
						icon.offee=camionchicoOffEE;
						icon.offse=camionchicoOffSE;
						icon.offss=camionchicoOffSS;
						icon.offsw=camionchicoOffSW;
						icon.offww=camionchicoOffWW;
						icon.offnw=camionchicoOffNW;
		
						icon.renn=camionchicoReNN;
						icon.rene=camionchicoReNE;
						icon.reee=camionchicoReEE;
						icon.rese=camionchicoReSE;
						icon.ress=camionchicoReSS;
						icon.resw=camionchicoReSW;
						icon.reww=camionchicoReWW;
						icon.renw=camionchicoReNW;
				break;
				case 15:
						icon.nn=tractorReNN;
						icon.ne=tractorReNE;
						icon.ee=tractorReEE;
						icon.se=tractorReSE;
						icon.ss=tractorReSS;
						icon.sw=tractorReSW;
						icon.ww=tractorReWW;
						icon.nw=tractorReNW;
	
						icon.onnn=tractorOnNN;
						icon.onne=tractorOnNE;
						icon.onee=tractorOnEE;
						icon.onse=tractorOnSE;
						icon.onss=tractorOnSS;
						icon.onsw=tractorOnSW;
						icon.onww=tractorOnWW;
						icon.onnw=tractorOnNW;
						
						icon.offnn=tractorOffNN;
						icon.offne=tractorOffNE;
						icon.offee=tractorOffEE;
						icon.offse=tractorOffSE;
						icon.offss=tractorOffSS;
						icon.offsw=tractorOffSW;
						icon.offww=tractorOffWW;
						icon.offnw=tractorOffNW;
		
						icon.renn=tractorReNN;
						icon.rene=tractorReNE;
						icon.reee=tractorReEE;
						icon.rese=tractorReSE;
						icon.ress=tractorReSS;
						icon.resw=tractorReSW;
						icon.reww=tractorReWW;
						icon.renw=tractorReNW;
				break;
				//--------/
				//ON//
				case 16:
				icon.nn=camionOnNN;
				icon.ne=camionOnNE;
				icon.ee=camionOnEE;
				icon.se=camionOnSE;
				icon.ss=camionOnSS;
				icon.sw=camionOnSW;
				icon.ww=camionOnWW;
				icon.nw=camionOnNW;

				icon.onnn=camionOnNN;
				icon.onne=camionOnNE;
				icon.onee=camionOnEE;
				icon.onse=camionOnSE;
				icon.onss=camionOnSS;
				icon.onsw=camionOnSW;
				icon.onww=camionOnWW;
				icon.onnw=camionOnNW;
				
				icon.offnn=camionOffNN;
				icon.offne=camionOffNE;
				icon.offee=camionOffEE;
				icon.offse=camionOffSE;
				icon.offss=camionOffSS;
				icon.offsw=camionOffSW;
				icon.offww=camionOffWW;
				icon.offnw=camionOffNW;

				icon.renn=camionReNN;
				icon.rene=camionReNE;
				icon.reee=camionReEE;
				icon.rese=camionReSE;
				icon.ress=camionReSS;
				icon.resw=camionReSW;
				icon.reww=camionReWW;
				icon.renw=camionReNW;
			break;
			case 17:
					icon.nn=autoOnNN;
					icon.ne=autoOnNE;
					icon.ee=autoOnEE;
					icon.se=autoOnSE;
					icon.ss=autoOnSS;
					icon.sw=autoOnSW;
					icon.ww=autoOnWW;
					icon.nw=autoOnNW;

					icon.onnn=autoOnNN;
					icon.onne=autoOnNE;
					icon.onee=autoOnEE;
					icon.onse=autoOnSE;
					icon.onss=autoOnSS;
					icon.onsw=autoOnSW;
					icon.onww=autoOnWW;
					icon.onnw=autoOnNW;
					
					icon.offnn=autoOffNN;
					icon.offne=autoOffNE;
					icon.offee=autoOffEE;
					icon.offse=autoOffSE;
					icon.offss=autoOffSS;
					icon.offsw=autoOffSW;
					icon.offww=autoOffWW;
					icon.offnw=autoOffNW;
	
					icon.renn=autoReNN;
					icon.rene=autoReNE;
					icon.reee=autoReEE;
					icon.rese=autoReSE;
					icon.ress=autoReSS;
					icon.resw=autoReSW;
					icon.reww=autoReWW;
					icon.renw=autoReNW;
			break;
			case 18:
					icon.nn=camionetaOnNN;
					icon.ne=camionetaOnNE;
					icon.ee=camionetaOnEE;
					icon.se=camionetaOnSE;
					icon.ss=camionetaOnSS;
					icon.sw=camionetaOnSW;
					icon.ww=camionetaOnWW;
					icon.nw=camionetaOnNW;

					icon.onnn=camionetaOnNN;
					icon.onne=camionetaOnNE;
					icon.onee=camionetaOnEE;
					icon.onse=camionetaOnSE;
					icon.onss=camionetaOnSS;
					icon.onsw=camionetaOnSW;
					icon.onww=camionetaOnWW;
					icon.onnw=camionetaOnNW;
					
					icon.offnn=camionetaOffNN;
					icon.offne=camionetaOffNE;
					icon.offee=camionetaOffEE;
					icon.offse=camionetaOffSE;
					icon.offss=camionetaOffSS;
					icon.offsw=camionetaOffSW;
					icon.offww=camionetaOffWW;
					icon.offnw=camionetaOffNW;
	
					icon.renn=camionetaReNN;
					icon.rene=camionetaReNE;
					icon.reee=camionetaReEE;
					icon.rese=camionetaReSE;
					icon.ress=camionetaReSS;
					icon.resw=camionetaReSW;
					icon.reww=camionetaReWW;
					icon.renw=camionetaReNW;
			break;
			case 19:
					icon.nn=camionchicoOnNN;
					icon.ne=camionchicoOnNE;
					icon.ee=camionchicoOnEE;
					icon.se=camionchicoOnSE;
					icon.ss=camionchicoOnSS;
					icon.sw=camionchicoOnSW;
					icon.ww=camionchicoOnWW;
					icon.nw=camionchicoOnNW;

					icon.onnn=camionchicoOnNN;
					icon.onne=camionchicoOnNE;
					icon.onee=camionchicoOnEE;
					icon.onse=camionchicoOnSE;
					icon.onss=camionchicoOnSS;
					icon.onsw=camionchicoOnSW;
					icon.onww=camionchicoOnWW;
					icon.onnw=camionchicoOnNW;
					
					icon.offnn=camionchicoOffNN;
					icon.offne=camionchicoOffNE;
					icon.offee=camionchicoOffEE;
					icon.offse=camionchicoOffSE;
					icon.offss=camionchicoOffSS;
					icon.offsw=camionchicoOffSW;
					icon.offww=camionchicoOffWW;
					icon.offnw=camionchicoOffNW;
	
					icon.renn=camionchicoReNN;
					icon.rene=camionchicoReNE;
					icon.reee=camionchicoReEE;
					icon.rese=camionchicoReSE;
					icon.ress=camionchicoReSS;
					icon.resw=camionchicoReSW;
					icon.reww=camionchicoReWW;
					icon.renw=camionchicoReNW;
			break;
			case 20:
					icon.nn=tractorOnNN;
					icon.ne=tractorOnNE;
					icon.ee=tractorOnEE;
					icon.se=tractorOnSE;
					icon.ss=tractorOnSS;
					icon.sw=tractorOnSW;
					icon.ww=tractorOnWW;
					icon.nw=tractorOnNW;

					icon.onnn=tractorOnNN;
					icon.onne=tractorOnNE;
					icon.onee=tractorOnEE;
					icon.onse=tractorOnSE;
					icon.onss=tractorOnSS;
					icon.onsw=tractorOnSW;
					icon.onww=tractorOnWW;
					icon.onnw=tractorOnNW;
					
					icon.offnn=tractorOffNN;
					icon.offne=tractorOffNE;
					icon.offee=tractorOffEE;
					icon.offse=tractorOffSE;
					icon.offss=tractorOffSS;
					icon.offsw=tractorOffSW;
					icon.offww=tractorOffWW;
					icon.offnw=tractorOffNW;
	
					icon.renn=tractorReNN;
					icon.rene=tractorReNE;
					icon.reee=tractorReEE;
					icon.rese=tractorReSE;
					icon.ress=tractorReSS;
					icon.resw=tractorReSW;
					icon.reww=tractorReWW;
					icon.renw=tractorReNW;
			break;
			default:
				break;
		}
		let ico = this.obtenerIcon(vH,isA,eEstado,eVelocidad,icon);
		return ico;
	} 
	obtenerIcon(vH,isA,eEstado,eVelocidad,icon){
		if(isA){
			if(eEstado===1){
				if(eVelocidad>0.1){
					if (vH > 335.7 && vH < 360) { return icon.onnn;}
					if (vH >= 0 && vH < 22.5) { return icon.onnn;}
					if (vH > 22.5 && vH < 67.5) {return icon.onne;}
					if (vH > 67.5 && vH < 112.5) { return icon.onee;}
					if (vH > 112.5 && vH < 157.5) { return icon.onse;}
					if (vH > 157.5 && vH < 202.5) { return icon.onss;}
					if (vH > 202.5 && vH < 247.5) { return icon.onsw;}
					if (vH > 247.5 && vH < 292.5) { return icon.onww;}
					if (vH > 292.5 && vH < 337.5) { return icon.onnw;}
				}else{
					if (vH > 335.7 && vH < 360) { return icon.renn;}
					if (vH >= 0 && vH < 22.5) { return icon.renn;}
					if (vH > 22.5 && vH < 67.5) {return icon.rene;}
					if (vH > 67.5 && vH < 112.5) { return icon.reee;}
					if (vH > 112.5 && vH < 157.5) { return icon.rese;}
					if (vH > 157.5 && vH < 202.5) { return icon.ress;}
					if (vH > 202.5 && vH < 247.5) { return icon.resw;}
					if (vH > 247.5 && vH < 292.5) { return icon.reww;}
					if (vH > 292.5 && vH < 337.5) { return icon.renw;}
				}
			}else{
				if (vH > 335.7 && vH < 360) { return icon.offnn;}
				if (vH >= 0 && vH < 22.5) { return icon.offnn;}
				if (vH > 22.5 && vH < 67.5) {return icon.offne;}
				if (vH > 67.5 && vH < 112.5) { return icon.offee;}
				if (vH > 112.5 && vH < 157.5) { return icon.offse;}
				if (vH > 157.5 && vH < 202.5) { return icon.offss;}
				if (vH > 202.5 && vH < 247.5) { return icon.offsw;}
				if (vH > 247.5 && vH < 292.5) { return icon.offww;}
				if (vH > 292.5 && vH < 337.5) { return icon.offnw;}
			}
			
		}else{
			if (vH > 335.7 && vH < 360) { return icon.nn;}
			if (vH >= 0 && vH < 22.5) { return icon.nn;}
			if (vH > 22.5 && vH < 67.5) {return icon.ne;}
			if (vH > 67.5 && vH < 112.5) { return icon.ee;}
			if (vH > 112.5 && vH < 157.5) { return icon.se;}
			if (vH > 157.5 && vH < 202.5) { return icon.ss;}
			if (vH > 202.5 && vH < 247.5) { return icon.sw;}
			if (vH > 247.5 && vH < 292.5) { return icon.ww;}
			if (vH > 292.5 && vH < 337.5) { return icon.nw;}
		}
	}

	obtenerListEvento(){
		listEventoR=[];
		var leh = this.state.punto;
		try{
			listEventoR = leh.map(l => (l.ID_TIPOEVENTO));
		}catch(e){
			console.log(e);
		}	
	}
	verMarker(t){
		for(let i=0;i<t.markerRastreo.length;i++){
			t.markerRastreo[i].setMap(t.map1);
		}	
	}
	
	consolePrueba(){
		console.log('yeahh');
	}
	iniciarElem(listElem){
		for(let i=0;i<listElem.length;i++){
			let titulo='';
			
			if(listElem[i].corte!==1){
				titulo=".  "+listElem[i].nombre+"  .";
				this.markerRastreo[listElem[i].placa] =	new MarkerWithLabel({
					icon: {
						url: this.obtenerIconBd(listElem[i].ORIENT,this.state.isArrow,listElem[i].I_ENCENDIDO,listElem[i].VELOCIDAD,listElem[i].icono),						
						anchor: {x:5,y:5}
					},
					position: new google.maps.LatLng(listElem[i].LATITUD,listElem[i].LONGITUD),
					map: this.map1,
					labelContent: titulo,
					labelAnchor: new google.maps.Point(22, -15),
					labelClass: "label", // the CSS class for the label
					labelStyle: {opacity: 0.95}
				});
		
				
				let countStop=listElem[i].placa;
				//google.maps.event.removeListener(this.markerRastreo[countStop]);
				this.markerRastreo[countStop].addListener('click', 
					((countStop) => { 
						return ()=>{this.datosDirectionRequest(listElem[i])};
					}) (countStop)
				);
				/*this.markerInfoRastreo[listElem[i].placa]= new google.maps.InfoWindow({
					content: '<div><div>Placa:'+listElem[i].placa+'</div><div>Fecha/Hora:'+this.obtenerFechaLocal(listElem[i].FECHAHORA_EVENTO)+'</div><div>Estado Motor:'+this.obtenerEstadoMotor(listElem[i].I_ENCENDIDO)+'</div><div>Velocidad:'+listElem[i].VELOCIDAD+'</div><div>Direccion:'+dirLiteral[listElem[i].placa]+'</div><div>Satelites:'+listElem[i].satelites+'</div><div>Km. diario:'+(listElem[i].ACUM_ODOMETRO/1000).toFixed(2)+' km</div></div>'
				});*/
				countStop++;
				this.markerRastreo[listElem[i].placa].mapVacio=false;
			}
		}
		this.setState({markerVacio:false});
	}
	obtenerEstadoMotor(l){
		if(l===1){
			return "Encendido";
		}else{
			return "Apagado";
		}
	}
	updateElem(elem,i) {	
		let titulo='';
		//this.datosDirectionRequest(elem);
		if(elem.corte!==1){
			titulo=".  "+elem.placa+"  .";
			var cnv=document.createElement("canvas"); 
			var cntx = cnv.getContext("2d"); 
			cnv.width=50;
			cnv.height=50; 
			cntx.fillText("Hello World!",10,10); 
			if (!this.markerRastreo[elem.placa]) {
				var trace_color= random_rgba();
			} else {
				var elemAntesDeAct = this.markerRastreo[elem.placa];
				if (elemAntesDeAct.FECHAHORA_EVENTO!=elem.FECHAHORA_EVENTO) {
					elemAntesDeAct.setIcon({
						url: this.obtenerIconBd(elem.ORIENT,this.state.isArrow,elem.I_ENCENDIDO,elem.VELOCIDAD,elem.icono),
						anchor: {x:5,y:5}
					});
					elemAntesDeAct.setPosition(new google.maps.LatLng(elem.LATITUD,elem.LONGITUD));
					elemAntesDeAct.data = elem;
					//google.maps.event.removeListener(this.markerRastreo[elem.placa]);
					//this.markerRastreo[elem.placa].removeEventListener();
					if(this.markerRastreo[elem.placa]!==undefined){
						//console.log(this.markerRastreo[elem.placa].__e3_['click']);
						this.markerRastreo[elem.placa].__e3_['click']={};
					}
					
					this.markerRastreo[elem.placa].addListener('click', 
					(() => { 
						return ()=>{this.datosDirectionRequest(elem)};
					})()
					);

					/*this.markerInfoRastreo[elem.placa]= new google.maps.InfoWindow({
						content: '<div><div>Placa:'+elem.placa+'</div><div>Fecha/Hora:'+this.obtenerFechaLocal(elem.FECHAHORA_EVENTO)+'</div><div>Estado Motor:'+this.obtenerEstadoMotor(elem.I_ENCENDIDO)+'</div><div>Velocidad:'+elem.VELOCIDAD+'</div><div>Direccion:'+dirLiteral[elem.placa]+'</div><div>Satelites:'+elem.satelites+'</div><div>Km. diario:'+(elem.ACUM_ODOMETRO/1000).toFixed(2)+' km</div></div>'
					});*/
					
				}
				
			}
		}
	}
	setMapOnAll(map) {
		let vp = Object.keys(this.markerRastreo);
        for (var i = 0; i < Object.keys(this.markerRastreo).length; i++) {
			this.markerRastreo[vp[i]].setMap(map);
        }
	}
	clearMarkerForTodos(tipo,list){
		let docChecks = document.getElementsByClassName("checkGrilla");
		let groupActual = document.getElementsByClassName("checkTodos")
		if(!groupActual[0].checked){	
			for(let i=0;i<docChecks.length;i++){
				docChecks[i].checked=0;
			}
			this.clearMarkerForTodos2(tipo,list,false);
		}else{
			for(let i=0;i<docChecks.length;i++){
				docChecks[i].checked=1;
			}
			this.clearMarkerForTodos2(tipo,list,true);
		}
	}	
	clearMarkerForGroup(tipo,group,list){
		let docChecks = document.getElementsByClassName(group.replace(/ /g, ""));
		let groupActual = document.getElementsByClassName("group "+group.replace(/ /g, ""))
		if(!groupActual[0].checked){	
			for(let i=0;i<docChecks.length;i++){
				docChecks[i].checked=0;
			}
			this.clearMarkerForGroup2(tipo,group,list,false);
		}else{	
			for(let i=0;i<docChecks.length;i++){
				docChecks[i].checked=1;
			}
			this.clearMarkerForGroup2(tipo,group,list,true);
		}
	}	
	clearMarkerForSubGroup(tipo,group,subGroup,list){
		let docChecks = document.getElementsByClassName(group.replace(/ /g, "")+" "+subGroup.replace(/ /g, ""));
		let groupActual = document.getElementsByClassName("subGroup "+group.replace(/ /g, "")+" "+subGroup.replace(/ /g, ""));
		if(!groupActual[0].checked){
			for(let i=0;i<docChecks.length;i++){
				docChecks[i].checked=0;
			}
			this.clearMarkerForSubGroup2(tipo,group,list,false);
		}else{			
			for(let i=0;i<docChecks.length;i++){
				docChecks[i].checked=1;
			}
			this.clearMarkerForSubGroup2(tipo,group,list,true);
		}
	}	
	clearMarkerForSubGroup2(tipo,group,list,encendido){
		if(tipo===1){
			for(let i=0;i<list.length;i++){
				if(this.markerRastreo[list[i]]!==undefined){
					if(!encendido){
						this.markerRastreo[list[i]].setMap(null);
						this.markerRastreo[list[i]].mapVacio=true;
					}else{
						this.markerRastreo[list[i]].setMap(this.map1);
						this.markerRastreo[list[i]].mapVacio=false;
					}
				}
			}
		}
	}
	mostrarVehiculo(list){		
		for(let i=0;i<list.length;i++){
			if(this.markerRastreo[list[i]]!==null&&this.markerRastreo[list[i]]!==undefined){
				console.log(list[i]);
				this.markerRastreo[list[i]].setMap(this.map1);
				this.markerRastreo[list[i]].mapVacio=false;
			}
		}
	}
	mostrarMapaSenal(listElem){		
			for(let i=0;i<listElem.length;i++){
				let titulo='';
				if(listElem[i].corte!==1){
					titulo=".  "+listElem[i].nombre+"  .";
					this.markerMapaSenal[i] =	new MarkerWithLabel({
						position: new google.maps.LatLng(listElem[i].LATITUD,listElem[i].LONGITUD),
						map: this.map1,
					});
					this.markerMapaSenal[i].mapVacio=false;
				}
			}
			this.setState({markerVacio:false});
	}
	clearMarkerForGroup2(tipo,group,list,encendido){
		if(tipo===1){
			for(let j =0;j<Object.keys(list).length;j++){
				let listO = Object.keys(list);
				for(let i=0;i<list[listO[j]].length;i++){
					let listP = list[listO[j]];
					if(this.markerRastreo[listP[i]]!==undefined){
						if(!encendido){
							this.markerRastreo[listP[i]].setMap(null);
							this.markerRastreo[listP[i]].mapVacio=true;
						}else{
							this.markerRastreo[listP[i]].setMap(this.map1);
							this.markerRastreo[listP[i]].mapVacio=false;
						}
					}
				}
			}
		}
	}
	clearMarkerForTodos2(tipo,list,encendido){
		if(tipo===1){
			for(let x=0;x<Object.keys(list).length;x++){
				let listG=list[Object.keys(list)[x]];
				for(let j =0;j<Object.keys(listG).length;j++){
					let listO = Object.keys(listG);
					for(let i=0;i<listG[listO[j]].length;i++){
						let listP = listG[listO[j]];
						if(this.markerRastreo[listP[i]]!==undefined){
							if(!encendido){
								this.markerRastreo[listP[i]].setMap(null);
								this.markerRastreo[listP[i]].mapVacio=true;
							}else{
								this.markerRastreo[listP[i]].setMap(this.map1);
								this.markerRastreo[listP[i]].mapVacio=false;
							}
						}
					}
				}
			}	
		}
	}
    // Remueve los MArkers del map, pero los mantiene en el array.
    clearMarkers(tipo,placa) {
		if(tipo===0){
			let vp = Object.keys(this.markerRastreo);
			for (var i = 0; i < Object.keys(this.markerRastreo).length; i++) {
				this.markerRastreo[vp[i]].setMap(null);
			}
		}else if(tipo===1){
			if(this.markerRastreo[placa]!==undefined){
				if(!this.markerRastreo[placa].mapVacio){
					this.markerRastreo[placa].setMap(null);
					this.markerRastreo[placa].mapVacio=true;
				}else{
					this.markerRastreo[placa].setMap(this.map1);
					this.markerRastreo[placa].mapVacio=false;
				}
			}
		}
		
		
	}
	showMarker(){
		let vp = Object.keys(this.markerRastreo);
    	for (var i = 0; i < Object.keys(this.markerRastreo).length; i++) {
			this.markerRastreo[vp[i]].setMap(this.map1);
		}
	 }
	
	obtenerIdEmpresa(){
		var x = this.state.statePrincipal['id'];
		console.log(x);
		return x;
	}
	obtenerHeredarGrupo(){
		var x = this.state.statePrincipal['heredarGrupo'];		
		console.log(x);
		return x;
	}
	obtenerLoginIn(){
		var x = this.state.statePrincipal['loginIn'];
		console.log(x);		
		return x;
	}
	obtenerEsSubCuenta(){
		var x = this.state.statePrincipal['esSubCuenta'];
		console.log(x);
		return x;
	}
	obtenerIdUsu(){
		var x = this.state.statePrincipal['idUsu'];
		console.log(x);
		return x.id;
	}
	agregarPunto2(val){
		this.setState({punto2:val});
	}
	obternerVehiculoTikeado(obj){	
		v=obj;
	}
	mostrarVehiculos(listaVehiculos) {	
		if(this.state.markerVacio){
			//this.setState({popupRealTime:listaVehiculos});	
			this.iniciarElem(listaVehiculos);
		}
		for (var i=0; i < listaVehiculos.length; ++i){
			if(v.length===0){
				//this.setState({popupRealTime:listaVehiculos});
				//this.updateElem(this.state.popupRealTime[i]);	
				this.updateElem(listaVehiculos[i],i);	
			}
		}
		this.obtenerListEvento();
	}
	componentDidMount() {
		console.log(this.state.statePrincipal);
		this.obtenerIdEmpresa();
		this.mapInit();
		//console.log("heredarGrupo: "+this.obtenerHeredarGrupo());
		ObtenerVehiculo(this.obtenerIdEmpresa(),this.obtenerEsSubCuenta(),this.state.statePrincipal['idUsu'],this.obtenerLoginIn(),this.changePunto,this.mostrarVehiculos,this.obtenerHeredarGrupo());
		window.timerID1 = setInterval(
		() => {ObtenerVehiculo(this.obtenerIdEmpresa(),this.obtenerEsSubCuenta(),this.state.statePrincipal['idUsu'],this.obtenerLoginIn(),this.changePunto,this.mostrarVehiculos,this.obtenerHeredarGrupo())},
		21000
		);
	}
	
	componentWillUnmount() {
		clearInterval(window.timerID1);
	}
	componentWillReceiveProps(nextProps){
		if(this.state.statePrincipal!==nextProps.statePrincipal){
			this.setState({statePrincipal:nextProps.statePrincipal});
		}
	}
	obtenerPlacasEnGeocerca(){
		for(let i=0;i<miPoligono.length;i++){
			let cont = "";
			let polygon2=miPoligono[i].getPath();
			for(let x=0;x<Object.keys(this.markerRastreo).length;x++){
				if(google.maps.geometry.poly.containsLocation(this.markerRastreo[Object.keys(this.markerRastreo)[x]].getPosition(), miPoligono[i])){
					cont+="<div>"+this.markerRastreo[Object.keys(this.markerRastreo)[x]].labelContent+"</div>"
				}
			}
			if(this.markerGeocerca[i]!==undefined){
				this.markerGeocerca[i].labelContent="<div style='background-color:green'>"+this.markerGeocerca[i].labelContent+"</div>"+cont;
			}
		}	
	}
	verGeocerca2(){
		ObtenerGeocera(this,this.obtenerIdEmpresa());
	}
	verGeocerca(){
		let coord = this.state.geoCercas.data;
		if(!h){
			if(coord===undefined){
			}else{			
				for(let i =0;i<Object.keys(coord).length;i++){
					let objGeocerca=coord[i].coordenadas;
					objGeocerca = JSON.parse(objGeocerca);
					poli=[];
					if(objGeocerca['type']==='Polygon')
					{
						let objCoordenadas = objGeocerca['coordinates'];
						objCoordenadas=objCoordenadas[0];
						for(let j=0;j<objCoordenadas.length;j++){
							let sCoorLat=(objCoordenadas[j][0]);
							let sCoorLng=(objCoordenadas[j][1]);
							poli[j]=new google.maps.LatLng(sCoorLat,sCoorLng);
						}	
					}
					miPoligono[i] = new google.maps.Polygon({ paths: poli, strokeColor: "#FF0000", strokeOpacity: 0.8, strokeWeight: 3, fillColor: "#FF0000", fillOpacity: 0.4 });
					miPoligono[i].setMap(this.map1);
					var bounds = new google.maps.LatLngBounds();
					let polygonCoords=poli;
					for (let j = 0; j < polygonCoords.length;j++) {
						bounds.extend(polygonCoords[j]);
					}
					let corlatlng=bounds.getCenter();
					this.markerGeocerca[i]= new MarkerWithLabel({
						position: bounds.getCenter(),
						draggable: true,
						raiseOnDrag: true,
						map: this.map1,
						labelContent: "<div>. "+coord[i].nombre+" .</div>",
						labelAnchor: new google.maps.Point(22, -15),
						labelClass: "labelGeocerca", //the CSS class for the label
						labelStyle: {opacity: 0.95}
					});
					this.markerGeocerca[i].setMap(this.map1);
					infoGeocerca[i] = new google.maps.InfoWindow({
						content: "<div><div>Nombre:"+coord[i].nombre+" </div></div>"
						});

					google.maps.event.addListener(miPoligono[i],'click', 
					() => {
						console.log('entroInfo');
						console.log(infoGeocerca);
						console.log(coord[i]);
						console.log(infoGeocerca[i].content);
						infoGeocerca[0].open(this.map1, miPoligono[0]);
						}
					);
					h=true;
				}
			}
		}else{
			h=false;
			for(let x=0;x<miPoligono.length;x++){
				miPoligono[x].setMap(null);
				this.markerGeocerca[x].setMap(null);
			}	
		}
		this.obtenerPlacasEnGeocerca();
	}
	
	geocerca(controlDiv, thet) {

		// Set CSS for the control border.
		var controlUI = document.createElement('div');
		controlUI.style.backgroundColor = '#fff';
		controlUI.style.border = '2px solid #fff';
		controlUI.style.borderRadius = '1px';
		controlUI.style.padding = '0px';
		controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
		controlUI.style.cursor = 'pointer';
		controlUI.style.marginBottom = '0px';
		controlUI.style.marginTop = '10px';
		controlUI.style.textAlign = 'center';
		controlUI.style.backgroundImage=geocercaV;
		controlUI.title = 'Click para mostrar las geocercas en el mapa';
		controlDiv.appendChild(controlUI);

		// Set CSS for the control interior.
		var controlText = document.createElement('div');
		controlText.style.color = 'rgb(25,25,25)';
		controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
		controlText.style.fontSize = '16px';
		controlText.style.lineHeight = '35px';
		controlText.style.paddingLeft = '5px';
		controlText.style.paddingRight = '5px';
		controlText.innerHTML = "<img src='"+geocercaV+"' width='20px' height='20px' />";
		
		controlUI.appendChild(controlText);

		// Setup the click event listeners: simply set the map to Chicago.
		controlUI.addEventListener('click', function() {
			
			ObtenerGeocera(thet,thet.obtenerIdEmpresa());
		});

	}	
	arrow(controlDiv, thet) {

		// Set CSS for the control border.
		var controlUI = document.createElement('div');
		controlUI.style.backgroundColor = '#fff';
		controlUI.style.border = '2px solid #fff';
		controlUI.style.borderRadius = '1px';
		controlUI.style.padding = '0px';
		controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
		controlUI.style.cursor = 'pointer';
		controlUI.style.marginBottom = '0px';
		controlUI.style.marginTop = '10px';
		controlUI.style.marginRight = '10px';
		controlUI.style.textAlign = 'center';
		controlUI.title = 'Click para cambiar la vista de rastreo';
		controlDiv.appendChild(controlUI);

		// Set CSS for the control interior.
		var controlText = document.createElement('div');
		controlText.style.color = 'rgb(25,25,25)';
		controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
		controlText.style.fontSize = '16px';
		controlText.style.lineHeight = '35px';
		controlText.style.paddingLeft = '5px';
		controlText.style.paddingRight = '5px';
		
		controlText.innerHTML = "<img src='"+vistaMotor+"' width='25px' height='25px' />";
		controlUI.appendChild(controlText);

		// Setup the click event listeners: simply set the map to Chicago.
		controlUI.addEventListener('click', function() {
		thet.changeRastreoEvento();
		});

	}
	centrar(lat,lng){
		let centro ={lat: lat, lng: lng};
		this.map1.setCenter(centro);
		this.map1.setZoom(15);
	}
	changeRastreoEvento(){
		if(this.state.isArrow===false){
			this.setState({isArrow:true});
			ObtenerVehiculo(this.obtenerIdEmpresa(),this.obtenerEsSubCuenta(),this.obtenerIdUsu(),this.obtenerLoginIn(),this.changePunto,this.mostrarVehiculos);
		}
		else{
			this.setState({isArrow:false});
			ObtenerVehiculo(this.obtenerIdEmpresa(),this.obtenerEsSubCuenta(),this.obtenerIdUsu(),this.obtenerLoginIn(),this.changePunto,this.mostrarVehiculos);
		}
	}
	async datosDirectionRequest(o){
		console.log(o);
		let a;
		//function conductorPop(changeConductor){a = ObtenerConductor(changeConductor,o.ID_CONDUCTOR);}
	//	await conductorPop(this.changeConductor);
		//////////////////
		var data = {data_op:o.ID_CONDUCTOR,
								op:'READ-CONDUCTORKARDEX'
								};
		fetch(url, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify(data), // data can be `string` or {object}!
			headers:{
				'Content-Type': 'application/json'
			}
		}).then(res => res.json())
		.catch(error => console.error('Error:', error))
		.then(response => {
			let obj =  response;
            obj=obj['data'];          
                if(obj[0]!==undefined){
								
									conductorPopUpd=obj[0].nombre + ' '+ obj[0].apellidoPaterno;
									
								  //this.setState({conductor:obj[0]});
                } else{								
										conductorPopUpd='';
                    //this.setState({conductor:obj[0]});
								}
								return 1;
		}).then( t=>{
///////////////////////////
		console.log(conductorPopUpd);return 1}).then(t=>
		fetch("https://gestiondeflota.boltrack.net/ubicacionapi/obtener/"+o['LATITUD']+"/"+o['LONGITUD']).then(r => r.json()).then(dir => {console.log(dir); dirLiteral[o.ID_ACTIVO]=dir['direccion'];console.log(dirLiteral);this.setState({dirLiteral:dirLiteral});return 1}).then(p=>{this.markerInfoRastreo[o.placa]={};this.markerInfoRastreo[o.placa]= new google.maps.InfoWindow({
			content: '<div><div>Placa:'+o.placa+'</div><div>Fecha/Hora:'+this.obtenerFechaLocal(o.FECHAHORA_EVENTO)+'</div><div>Estado Motor:'+this.obtenerEstadoMotor(o.I_ENCENDIDO)+'</div><div>Velocidad:'+Math.trunc(o.VELOCIDAD)+'Km</div><div>Direccion:'+dirLiteral[o.placa]+'</div><div>Satelites:'+o.satelites+'</div><div>Km. diario:'+Math.trunc((o.ACUM_ODOMETRO/1000).toFixed(2))+' km</div><div>Grupo: '+o.grupo+'</div><div>Subgrupo: '+o.subgrupo+'</div><div>Conductor: '+conductorPopUpd+'</div></div>'
		});return 1}).then(y=>{this.markerInfoRastreo[o.placa].open(this.map1, this.markerRastreo[o.placa])}));
	return 1;
	}
	obtenerPlaca(p){ 
		let pl = p;
		this.setState({placa:pl.toString()});
		this.setState({alturaGrid:'55vh'});
		console.log('obtenerPlaca');
	}
	verificarProstate(p){
		let result = false;
		try{
			if((p!==undefined)||(p!==null)){
				if(Object.keys(p).length!==0){
					result=true;
				}else{
					result=false;
				}
			}
		}catch(e){

		}
	}
	changeAnchura(){
		if(this.state.anchura==='80vw'){
			this.setState({anchura:'0vw'});
			this.setState({anchuraBoton:'0vw'})
			this.setState({class:'App-hidden'});
			this.setState({flagBotonMinimizado:true});
		}else{
			this.setState({anchura:'80vw'});
			this.setState({anchuraBoton:'20vw'})
			this.setState({class:'App'});
			this.setState({flagBotonMinimizado:false});
		}
		this.changeAnchoMapa();
		}
		changeAnchuraPlacaKardex(){
			if(this.state.anchura==='80vw'){
				this.setState({anchura:'0vw'});
				this.setState({anchuraBoton:'0vw'})
				this.setState({class:'App-hidden'});
				this.setState({flagBotonMinimizado:true});
				this.setState({flagKardexGridMinimizado:true});
			}else{
				
			}
			this.changeAnchoMapa();
			}
		onStart = () => {
			this.setState({activeDrags: ++this.state.activeDrags});
		};
	
		onStop = () => {
			this.setState({activeDrags: --this.state.activeDrags});
		};
  render() {
	const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
	let proState = this.state.punto;
	console.log(proState);
    return (
		<div>
		<div style={{display:'flex',justifyContent:'spacebetween'}}>
		</div>
			<div id="wrapper" style={{ display:'flex', justifyContent: 'spaceAround'}}>
			{Object.keys(proState).length!==0&& <GridRastreo changeEnviarComandoShow={this.state.changeEnviarComandoShow} changePlacaHistorico={this.state.changePlacaHistorico} changeHistoricoShow={this.state.changeHistoricoShow} changeZoomMap={this.changeZoomMap} changeAcumAsignado={this.changeAcumAsignado} changeAcumsJson={this.changeAcumsJson} changePlacaSensores={this.changePlacaSensores} changeShowSensores={this.changeShowSensores} classG={this.state.class} anchura={this.state.anchura} changeDirLiteral={this.changeDirLiteral}	mostrarVehiculo={this.mostrarVehiculo} obtenerPlaca={this.obtenerPlaca} centrar={this.centrar} nro={this.state.nro}  punto={this.state.punto}  verMarker={this.verMarker} google={google} clearMarkerForTodos={this.clearMarkerForTodos} clearMarkerForGroup={this.clearMarkerForGroup} clearMarkerForSubGroup={this.clearMarkerForSubGroup} v={this.state.punto} f={this.obternerVehiculoTikeado}  u={this.clearMarkers} changeAnchoMapa={this.changeAnchoMapa}/>}				
			<div className={this.state.class} style={{ backgroundColor:'rgb(31, 105, 173)',height:'100vh',width:this.state.anchuraBoton, color:'white'}} onClick={()=>{this.changeAnchura()}}><div style={{marginTop:'40vh'}}>Ver <br/> Mapa</div></div>
			<div id="mapaContainer" style={{ width:this.state.anchoMapa ,height:'100vh', margin:'0 0 0 0'}}></div>
			</div>
			{this.state.flagKardexGridMinimizado&&<KardexGrid changeAcumAsignado={this.state.changeAcumAsignado} changeAcumsJson={this.state.changeAcumsJson} changePlacaSensores={this.state.changePlacaSensores} changeShowSensores={this.state.changeShowSensores} changeDirLiteral={this.changeDirLiteral} changeAlturaGrid={this.changeAlturaGrid} centrar={this.state.centrar} nro={this.state.nro} punto={this.state.punto} google={this.state.google} placa={this.state.placa} o={this.state.oList[this.state.placa]} op ={this.changeAlturaGrid}></KardexGrid>}
			{this.state.flagBotonMinimizado&&<div style={{width:'40px', height:'40px', backgroundColor:'white', position:'absolute', zIndex:'999',top:'1vh', left:'3vw'}} onClick={()=>{this.changeAnchura()}}><img src={menu} alt='' /></div>}
			{this.state.showSensores&&<Draggable handle="strong" {...dragHandlers}><div style={{position:'absolute',backgroundColor:'white', width:'30vw', height:'30vh',zIndex:'999',top:'30vh', left:'68vw'}} >
				<strong style={{cursor:'move'}}><div style={{backgroundColor:'rgb(31, 105, 173)',color:'white',display:'flex',justifyContent:'spaceBetween'}}>
						<div style={{marginRight:'25vw'}}>Sensores</div><div style={{cursor:'pointer'}} onClick={()=>{this.changeShowSensores(); console.log(this.state.acumAsignado)}}>X</div>
				</div></strong>
				<div style={{color:'black'}}>
					{this.state.acumsJson.length>0&&<Sensores popup={true} sensores={this.state.acumAsignado} acumsJson={this.state.acumsJson} />}
					</div>
			</div></Draggable>}
		</div>
    );
  }
}

export default Rastreo;
