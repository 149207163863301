export function obtenerTipoEvento(objEvento){
    let results ='';
      switch (objEvento){
        case 2:
            results="Automatico";
        break;
        case 3:
            results="Encendido";
        break;
        case 4:
            results="Inicio cond. fuera de horario";
        break;
        case 5:
            results="Apagado";
        break;
        case 6:
            results="Fin Cond. fuera de Horario";
        break;
        case 11:
            results="Conexion 4x4";
        break;
        case 12:
            results="Desconexion 4x4";
        break;
        case 13:
            results="Conexion cinturon";
        break;
        case 14:
            results="Desconexion cinturon";
        break;
        case 15:
            results="Boton panico ON";
        break;
        case 16:
            results="Boton panico OFF";
        break;
        case 17:
            results="Conexion cable de emergencia";
        break;
        case 18:
            results="Desconexion cable de emergencia";
        break;
        case 19:
            results="Automatico";
        break;
        case 20:
            results="Automatico";
        break;
        case 21:
            results="A/D Above 0";
        break;
        case 22:
            results="A/D Below 0";
        break;
        case 23:
            results="A/D Above 1";
        break;
        case 24:
            results="A/D Below 1";
        break;
        case 25:
            results="A/D Above 2";
        break;
        case 26:
            results="A/D Below 2";
        break;
        case 27:
            results="A/D Above 3";
        break;
        case 28:
            results="A/D Below 3";
        break;
        case 29:
            results="Automatico";
        break;
        case 30:
            results="Detencion Prolongada";
        break;
        case 31:
            results="Aceleracion brusca";
        break;
        case 32:
            results=" Frenada Brusca";
        break;
        case 33:
            results="Alerta de Impacto";
        break;
        case 34:
            results="Grua";
        break;
        case 41:
            results="Conduccion Prolongada";
        break;
        case 44:
            results="Indefinido";
        break;
        case 60:
            results="Sin Identificacion";
        break;
        case 100:
            results="COMM Desconectado";
        break;
        case 1:
            results="Identificacion Conductor";
        break;
        case 101:
            results="COMM Conectado";
        break;
        case 102:
            results="GPS Conectado";
        break;
        case 103:
            results="Power Up";
        break;
        case 253:
            results="Automatico";
        break;
        case 160:
            results=" Parada mayor a 15 min";
        break;
        case 161:
            results="Tiempo de Parada";
        break;
        case 115:
            results="Punto de interes";
        break;
        case 70:
            results="ON GRUA";
        break;
        case 71:
            results="OFF GRUA";
        break;
        case 9:
            results="Exceso en zona";
        break;
        case 10:
            results="Exceso en asfalto";
        break;
        case 163:
            results="Velocidad reestablecida";
        break;
        case 164:
            results="Cable de emergencia ON";
        break;
        case 255:
            results="Automatico";
        break;
        case 162:
            results="Salida de POI";
        break;
        case 165:
            results="OFF Bateria Principal";
        break;
        case 181:
            results="Entrada Caranda";
        break;
        case 185:
            results="Entrada zona prohibida";
        break;
        case 175:
            results="Parada fuera de Estacion";
        break;
        case 174:
            results="Salida de estacion Fuera de Horario";
        break;
        case 90:
            results="Exceso Vel. fuera SC y VM";
        break;
        case 166:
            results="Fin cond. Prolongada";
        break;
        case 167:
            results="Valvula Descarga ON";
        break;
        case 168:
            results="Valvula Descarga OFF";
        break;
        case 169:
            results="Apertura de Compuertas";
        break;
        case 170:
            results="Cierre de Compuertas";
        break;
        case 171:
            results="Salida de zona";
        break;
        case 172:
            results="Conduccion Mayor a 10 horas diarias";
        break;
        case 173:
            results="Conduccion sin cinturon de seguridad";
        break;
        case 91:
            results="Exceso Vel. en SC";
        break;
        case 92:
            results="Exceso Vel. en VM";
        break;
        case 93:
            results="Exceso Vel. en VM carretera";
        break;
        case 94:
            results="Exceso Vel. en La Vertiente";
        break;
        case 190:
            results="Conduccion Fuera de horario";
        break;
        case 250:
            results="Ping";
        break;
        case 254:
            results="Envio de Comando";
        break;
        case 35:
            results="Parada Mayor a 1 hora";
        break;
        case 249:
            results="Automatico";
        break;
        case 95:
            results="Automatico Roaming";
        break;
        case 184:
            results="Salida Colpa";
        break;
        case 183:
            results="Entrada Colpa";
        break;
        case 182:
            results="Salida Caranda";
        break;
        case 180:
            results="Ingreso a punto de control";
        break;
        case 7:
            results="Exceso Velocidad 30";
        break;
        case 8:
            results="Exceso Velocidad 50";
        break;
        //244-255  automatico

        case 29:
        results= "Perdida Gps";
        break;
        case 243:
        results= "Apagado erroneo";
        break;
        case 51:
        results= "Limite 12 horas";
        break;
        case 41:
        results= "Conduccion prolongada";
        break;
        case 52:
        results= "Movimiento sin cinturon";
        break;
        case 72:
        results= "Cinturon permanente"; 
        break;
        case 187:
        results = "Fin de conduccion prolongada" ;
        break;
        case 42:
        results ="Desconexion batería" ;
        break;
        case 43:
        results="Conexión bateria";
        break;
        /*default:
            results="evento sin identificar "+objEvento;
        break;*/
      }
    
    return results;
  }

  export function obtenerTipoEventoAgGrid(objEvento){
    
    let results ='';
      switch (objEvento){
        case 2:
            results="Automatico";
        break;
        case 3:
            results="Encendido";
        break;
        case 4:
            results="Inicio cond. fuera de horario";
        break;
        case 5:
            results="Apagado";
        break;
        case 6:
            results="Fin Cond. fuera de Horario";
        break;
        case 11:
            results="Conexion 4x4";
        break;
        case 12:
            results="Desconexion 4x4";
        break;
        case 13:
            results="Conexion cinturon";
        break;
        case 14:
            results="Desconexion cinturon";
        break;
        case 15:
            results="Boton panico ON";
        break;
        case 16:
            results="Boton panico OFF";
        break;
        case 17:
            results="Conexion cable de emergencia";
        break;
        case 18:
            results="Desconexion cable de emergencia";
        break;
        case 19:
            results="Automatico";
        break;
        case 20:
            results="Automatico";
        break;
        case 21:
            results="A/D Above 0";
        break;
        case 22:
            results="A/D Below 0";
        break;
        case 23:
            results="A/D Above 1";
        break;
        case 24:
            results="A/D Below 1";
        break;
        case 25:
            results="A/D Above 2";
        break;
        case 26:
            results="A/D Below 2";
        break;
        case 27:
            results="A/D Above 3";
        break;
        case 28:
            results="A/D Below 3";
        break;
        case 29:
            results="Automatico";
        break;
        case 30:
            results="Detencion Prolongada";
        break;
        case 31:
            results="Aceleracion brusca";
        break;
        case 32:
            results=" Frenada Brusca";
        break;
        case 33:
            results="Alerta de Impacto";
        break;
        case 34:
            results="Grua";
        break;
        case 41:
            results="Conduccion Prolongada";
        break;
        case 44:
            results="Indefinido";
        break;
        case 60:
            results="Sin Identificacion";
        break;
        case 100:
            results="COMM Desconectado";
        break;
        case 1:
            results="Identificacion Conductor";
        break;
        case 101:
            results="COMM Conectado";
        break;
        case 102:
            results="GPS Conectado";
        break;
        case 103:
            results="Power Up";
        break;
        case 253:
            results="Automatico";
        break;
        case 160:
            results=" Parada mayor a 15 min";
        break;
        case 161:
            results="Tiempo de Parada";
        break;
        case 115:
            results="Punto de interes";
        break;
        case 70:
            results="ON GRUA";
        break;
        case 71:
            results="OFF GRUA";
        break;
        case 9:
            results="Exceso en zona";
        break;
        case 10:
            results="Exceso en asfalto";
        break;
        case 163:
            results="Velocidad reestablecida";
        break;
        case 164:
            results="Cable de emergencia ON";
        break;
        case 255:
            results="Automatico";
        break;
        case 162:
            results="Salida de POI";
        break;
        case 165:
            results="OFF Bateria Principal";
        break;
        case 181:
            results="Entrada Caranda";
        break;
        case 185:
            results="Entrada zona prohibida";
        break;
        case 175:
            results="Parada fuera de Estacion";
        break;
        case 174:
            results="Salida de estacion Fuera de Horario";
        break;
        case 90:
            results="Exceso Vel. fuera SC y VM";
        break;
        case 166:
            results="Fin cond. Prolongada";
        break;
        case 167:
            results="Valvula Descarga ON";
        break;
        case 168:
            results="Valvula Descarga OFF";
        break;
        case 169:
            results="Apertura de Compuertas";
        break;
        case 170:
            results="Cierre de Compuertas";
        break;
        case 171:
            results="Salida de zona";
        break;
        case 172:
            results="Conduccion Mayor a 10 horas diarias";
        break;
        case 173:
            results="Conduccion sin cinturon de seguridad";
        break;
        case 91:
            results="Exceso Vel. en SC";
        break;
        case 92:
            results="Exceso Vel. en VM";
        break;
        case 93:
            results="Exceso Vel. en VM carretera";
        break;
        case 94:
            results="Exceso Vel. en La Vertiente";
        break;
        case 190:
            results="Conduccion Fuera de horario";
        break;
        case 250:
            results="Ping";
        break;
        case 254:
            results="Envio de Comando";
        break;
        case 35:
            results="Parada Mayor a 1 hora";
        break;
        case 249:
            results="Automatico";
        break;
        case 95:
            results="Automatico Roaming";
        break;
        case 184:
            results="Salida Colpa";
        break;
        case 183:
            results="Entrada Colpa";
        break;
        case 182:
            results="Salida Caranda";
        break;
        case 180:
            results="Ingreso a punto de control";
        break;
        case 29:
        results= "Perdida Gps";
        break;
        case 243:
        results= "Apagado erroneo";
        break;
        case 51:
        results= "Limite 12 horas";
        break;
        case 41:
        results= "Conduccion prolongada";
        break;
        case 52:
        results= "Movimiento sin cinturon";
        break;
        case 72:
        results= "Cinturon permanente"; 
        break;
        case 187:
        results = "Fin de conduccion prolongada" ;
        break;
        case 42:
        results ="Desconexion batería" ;
        break;
        case 43:
        results="Conexión bateria";
        break;
       /* default:
            results="evento sin identificar "+objEvento;
        break;*/
      }
    
    return results;
    
  }