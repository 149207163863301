import $ from 'jquery';
import {url} from '../ParametroConexion/parametroConexion';

export function ObtenerConductor(changeConductor,idConductor){
    $.ajax({
        //url: 'http://127.0.0.1:2525/datos',
        url: url,
        type: "POST",
        data: {
                data_op:idConductor,
                op:'READ-CONDUCTORKARDEX'
        },
        success: function(result)            
           {
            let obj =  $.parseJSON(result);
            obj=obj['data'];          
            console.log(obj);
                if(obj!==undefined){
                    changeConductor(obj[0]);
                } else{
                    let obj=[];
                    obj.push({
                        nombre:'',
                        apellidoPaterno:''
                    });
                    obj[0].nombre='';
                    obj[0].apellidoPaterno='';
                    changeConductor(obj[0]);
                }
            //return (obj[0].nombre + ' ' + obj[0].apellidoPaterno);           
            }
    
    }).done(function(result){

       console.log(result);
       
       
    })

}