import $ from 'jquery';
import {url} from '../ParametroConexion/parametroConexion';

export function LoginIsTrue(changeLogin,objLogin){
    $.ajax({
        url: url,
        //url: url,
        type: "POST",
        data: {
                data_op:objLogin,
                op:'READ-COMPROBARLOGIN'
        },
        success: function(result)            
		   {
            try{
                console.log('logueo ppal');
                var array = JSON.parse( result );
                var istrue = false,
                result= array['data'];
                let obj = {
                    loginIn:'',
                    id:'',
                    idUsu:'',
                    usuario:'',
                    nombreEmpresa:'',
                    heredarGrupo:'',
                    istrue:false
                };
                console.log(result[0]);
                if(result[0]!=undefined){
                    istrue=true;
                    //console.log('entro a no es null');
                    obj.loginIn='0';
                    obj.id=result[0].idEmpresa;
                    obj.idUsu=result[0].idUsuario;
                    obj.usuario=result[0].usua;
                    obj.nombreEmpresa=result[0].nombre;
                    obj.heredarGrupo=result[0].heredarGrupo
                    
                    let esSubCuenta = result[0].esSubCuenta
                    console.log((result[0].esSubCuenta).toString());
                    obj.esSubCuenta=esSubCuenta.toString();
                    if(result[0].corte===1){
                        obj.istrue=false;
                        
                    }else{
                        obj.istrue=true;
                        
                    }
                }

                else{
                istrue=false;
                }
                console.log(obj);
                changeLogin(obj);
            }catch(e){
                

            }
        }
    }).done(function(result){
        
       //console.log(result);
       
        
    })
}