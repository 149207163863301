import React, { Component,Redirect } from 'react';

import NewWindow from 'react-new-window';

import {ObtenerConductor} from './Ajax_ObtenerConductor';
import moment, { now } from 'moment';
import './style.css';
import './menu.css';
import './botonEncendido.css';
import eye from './eye.svg';
import corte from './advertencia.png';
import email from './correo-electronico.svg'



class Placa extends Component {
    constructor(props){
        super(props);
        this.state={
            id:'',
            oPlaca: this.props.oPlaca,  //Funcion importada desde gridRastreo (nombre funcion obtenerPlaca) para obtener la placa clickeada y se usa en kardexgrid
            placa:this.props.pc,
            grupoName:this.props.grupoName,
            subGrupoName:this.props.subGrupoName,
            fObtenerVTikeado:this.props.f,
            fClearMArker: this.props.u,
            isOpen: false,
            google:this.props.google,
            oStreetView: false,
            centrar:this.props.centrar,
            o: this.props.o,  //Informacion del vehiculo, como ser Velocidad, estado de motor, etc
            oHistoricoDia: false,
            isCambiargrupo: false,
            conductor:{
                nombre:'',
                apellidoPaterno:'',
                nroCelPersonal:''
            },
            isConductorCheck:this.props.checkConductor,
            changeHistoricoShow:this.props.changeHistoricoShow,
            changeEnviarComandoShow:this.props.changeEnviarComandoShow,
            changePlacaHistorico:this.props.changePlacaHistorico
        }     
      }
    componentWillReceiveProps(nextProps){
        try{
            this.setState({o:nextProps.o});
        }
        catch(e){
            console.log(e);
        }    
      } 

    copyStringToClipboard() {
        // Create new element
        //console.log('clipboard');
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = 'https://maps.google.com/?q='+this.state.o['LATITUD']+','+this.state.o['LONGITUD'];
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
        window.alert("Url google map del vehiculo se obtuvo correctamente");
     }
    mostrarVentanaRastreo(){
        if(this.state.isOpen==false){
            this.setState({isOpen:true});
        }else{
            this.setState({isOpen:false});        
        }
    }
    
    showHistoricoDia(){
        this.setState({oHistoricoDia:false});
        if(this.state.oHistoricoDia==false){
            this.setState({oHistoricoDia:true});
        }else{
            this.setState({oHistoricoDia:false});
        }
    
    }
    showStreetView(){
        if(this.state.oStreetView==false){
            this.setState({oStreetView:true});
        }else{
            this.setState({oStreetView:false});
        }
    }
    changeSelect(event){
        switch(event.target.value){
            case 'HistoricoDia':
                this.state.changePlacaHistorico(this.state.placa);
                this.state.changeHistoricoShow(true);
                //this.showHistoricoDia();
            break;
            case 'StreetView':
                this.showStreetView();
            break;
            case 't':
                this.setState({isCambiargrupo:true});
            break;
            case 'copyClipboard':
                this.copyStringToClipboard();
            break;
            case 'SoliActualizacion':
            break;
            default:
                break;
        }
    }
    obtenerChekeado(){
        this.state.fClearMArker(1,this.state.placa);
        let x = document.getElementsByClassName('check');
        let res=[];
        for(let i=0;i<x.length;i++){
            if(x[i].checked){
            res.push(x[i].id);
            }
        }
    }
    obtenerIdEmpresa(){
		var x = document.getElementsByClassName("HidEmpresa")[0];
		return x.id;
	}
    componentDidMount(){
        if(this.state.isConductorCheck){
            ObtenerConductor(this,this.state.o['ID_CONDUCTOR']);
        }       
    }   
    obtenerFechaLocal(d){
        let fechaserver = new Date (d);
        let fechalocal = fechaserver.getTime() - (fechaserver.getTimezoneOffset());
        fechalocal=String(new Date(fechalocal));
        return fechalocal.substring(3,24);
    }
    obtenerTiempoRetraso(d){
        let fecha1=moment(d);
        let cadena='Min.';
        let fechaNow=new Date();
        let fecha2=moment(Date.now());
        let diff = fecha2.diff(fecha1,'minutes');
        if(diff>60){
            diff = diff / 60
            cadena='Hrs';
            if(diff>24){
                diff=diff/24;
                cadena='Dias'
            }
        }
 
        return (diff.toFixed(0)+' '+cadena);
    }   
  render() { 
      
    return (<React.Fragment>
        {this.state.o!==undefined&&
        <div key={this.state.placa} className='rowPlaca' style={{borderTop:'1px solid Lavender',background: 'white', display:'flex', justifyContent:'spaceBetween'}}>                
        <div style={{width:'30px',marginLeft:'8px'}} ><input type="checkbox" id={this.state.placa} className={"checkGrilla "+this.state.grupoName.replace(/ /g, "")+" "+this.state.subGrupoName.replace(/ /g, "")} defaultChecked onClick={()=>{this.obtenerChekeado()}}/>{this.state.o['corte'] === 1 && <img src={corte} alt='corte' width='12px' height='12px' title='Corte' style={{marginLeft:'3px',marginBottom:'5px'}}/>}</div>
        <div>
        <div style={{width:'80px', cursor: 'pointer', color:'#2E2D2D', marginLeft:'5px'}} onClick={()=>{this.state.centrar(this.state.o['LATITUD'],this.state.o['LONGITUD']); this.state.oPlaca(this.state.placa)}}>{!this.state.isConductorCheck?this.state.placa:this.state.placa+":  "+(this.state.conductor.nombre!==''?this.state.conductor.nombre+" "+this.state.conductor.apellidoPaterno:'S/N')}</div>
        <div style={{color:'#585858',fontSize:'8px',marginLeft:'5px'}}>{this.obtenerFechaLocal(this.state.o['FECHAHORA_EVENTO'])}</div>
        </div>
        <div style={{width:'40px',fontSize:'10px',marginRight:'4px'}}>{this.obtenerTiempoRetraso(this.state.o['FECHAHORA_EVENTO'])}</div>
        {String(this.state.o['I_ENCENDIDO']) === '0' &&<div style={{width:'40px'}}><div style={{width:'30px', borderLeft:'1px solid Lavender', cursor: 'pointer', backgroundColor:'rgb(212, 36, 36)', marginRight:'4px' }} className='speed-holder'  ><div></div><div className='speed' style={{marginLeft:'2px', fontSize:'13px'}}>{parseInt(this.state.o['VELOCIDAD'])}</div></div></div>}
        {(String(this.state.o['I_ENCENDIDO']) === '1' && (String(this.state.o['Velocidad']))!=='0') &&<div style={{width:'40px'}}><div style={{width:'30px', borderLeft:'1px solid Lavender', cursor: 'pointer', marginRight:'4px' }} className='speed-holder' ><div></div><div className='speed' style={{marginLeft:'2px', fontSize:'13px'}}>{parseInt(this.state.o['VELOCIDAD'])}</div></div></div>}
        {(String(this.state.o['I_ENCENDIDO']) === '1' && (String(this.state.o['Velocidad']))==='0') &&<div style={{width:'40px'}}><div style={{width:'30px', borderLeft:'1px solid Lavender', cursor: 'pointer', marginRight:'4px',backgroundColor:'yellow' }} className='speed-holder' ><div></div><div className='speed' style={{marginLeft:'2px', fontSize:'13px'}}>{parseInt(this.state.o['VELOCIDAD'])}</div></div></div>}
        {<div style={{width:'30px', borderLeft:'1px solid Lavender', cursor: 'pointer' }}  onClick={()=>{this.state.changePlacaHistorico(this.state.placa);this.state.changeEnviarComandoShow(true)}}><img src={email} alt='rojo' width='20px' height='20px' style={{marginLeft:'2px'}} /></div>}
        <div className="book-shelf-changer" style={{width:'30px', borderLeft:'1px solid Lavender',borderRight:'1px solid Lavender', cursor: 'pointer'}} value=''  onClick={event => {this.state.changePlacaHistorico(this.state.placa);this.state.changeHistoricoShow(true); /*this.changeSelect(event)*/}}>{/*<select>
            <option value="none" selected="selected" disabled>
              Menu
            </option>
                <option value="HistoricoDia">Historico dia</option>               
        </select>*/}</div>
        <div>
        
        
        
        </div>
        {this.state.isCambiargrupo&& <div draggable="true" style={{backgroundColor:'pink', width:'2px', height:'2px'}}></div>}
        </div>
    }</React.Fragment>
    )          
}
}
export default Placa;