import React, { Component } from 'react';
//import {render} from 'react-dom';

import $ from 'jquery';
import {ObtenerConductor} from '../Ajax_ConductorInfoWindow';
import {ObtenerAcumAsignado} from './ajax_obtenerAcumAsignado';
import {ObtenerInputAsignado} from './ajax_obtenerInputAsignado';
import Sensores from './Sensores'
import signalbars0 from './signalbars0.svg';
import signalbars1 from './signalbars1.svg';
import signalbars2 from './signalbars2.svg';
import signalbars3 from './signalbars3.svg';
import signalbars4 from './signalbars4.svg';
import signalbars5 from './signalbars5.svg';
import battery from './battery.svg'
import clock from './clock.svg';
import clockralenti from './clockralenti.svg';
import street from './street.svg';
import './messageWarning.css';

let dir;
class KardexGrid extends Component {
    constructor(props){
      super(props);
      this.state={
        placa:this.props.placa,
        conductor:{
          nombre:'',
          apellidoPaterno:'',
          nroCelPersonal:''
        },
        changePlacaSensores:this.props.changePlacaSensores,
        changeShowSensores:this.props.changeShowSensores,
        changeAcumAsignadoRastreo:this.props.changeAcumAsignado,
        changeInputAsignadoRastreo:this.props.changeInputAsignado,
        changeAcumsJsonRastreo:this.props.changeAcumsJson,
        nroTemp:null,
        nro:this.props.nro,
        inputAsignado:[],
        acumAsignado:[],
        acumsJson:[],
        op:this.props.op,
        o:this.props.o,
        dirLiteral:'',
        punto:this.props.punto,
        changeAlturaGrid: this.props.changeAlturaGrid
      }
     this.changeAcumAsignado=this.changeAcumAsignado.bind(this);
     this.changeNroTemp=this.changeNroTemp.bind(this);
     this.changeConductor=this.changeConductor.bind(this);
    }
    changeInputAsignado(inputAsignado){
      this.setState({inputAsignado});
    }
    changeConductor(conductor){
      this.setState({conductor});
    }
    changeAcumAsignado(acumAsignado){
      this.setState({acumAsignado})
    }
    changeNroTemp(nroTemp){
      this.setState({nroTemp});
    }
    
    datosDirectionRequest(){
      fetch("https://gestiondeflota.boltrack.net/ubicacionapi/obtener/"+this.state.o['LATITUD']+"/"+this.state.o['LONGITUD']).then(r => r.json()).then(dir => {console.log(dir);this.setState({dirLiteral:dir['direccion']})});
    }
    componentDidMount(){
      this.datosDirectionRequest();  
      ObtenerAcumAsignado(this.state.o['placa'],this.changeAcumAsignado,this.changeNroTemp,this.state.changeAcumAsignadoRastreo,this.state.changeAcumsJsonRastreo,this.state.o['ACUMS']);
      ObtenerAcumAsignado(this.state.o['placa'],this.changeInputAsignado,this.changeNroTemp,this.state.changeInputAsignadoRastreo,this.state.changeInputJsonRastreo,this.state.o['metadata']);
      this.tranformarAcumsJson(this.state.o['ACUMS']);
      ObtenerConductor(this.changeConductor,this.state.o['ID_CONDUCTOR']);     
    }
    obtenerTiempo(tiempo){
      var hours = Math.floor( tiempo / 3600 );  
      var minutes = Math.floor( (tiempo % 3600) / 60 );
      var seconds = tiempo % 60;
 
      //Anteponiendo un 0 a los minutos si son menos de 10 
      minutes = minutes < 10 ? '0' + minutes : minutes;
 
      //Anteponiendo un 0 a los segundos si son menos de 10 
      seconds = seconds < 10 ? '0' + seconds : seconds;
 
      var result = hours + ":" + minutes + ":" + seconds; 
      return result;
    }
    
    tranformarAcumsJson(acums){
      let obj = $.parseJSON(acums);
      console.log(obj);
      this.setState({acumsJson:obj});
      //this.state.changeAcumsJsonRastreo(obj);
      //this.state.changeAcumsJson(obj);
    }
    obtenerFechaLocal(d){  
      let fechaserver = new Date (d);
      let fechalocal = fechaserver.getTime();
      fechalocal=String(new Date(fechalocal));
      return fechalocal.substring(3,24);
    }
   async componentWillReceiveProps(nextProps){
      try{
        console.log(this.state.acumAsignado);
        //console.log(this.state.changePlacaSensores);
        
        if(this.state.placa!==nextProps.placa){
          console.log('Placa Sensores!!');
          //this.state.changePlacaSensores(nextProps.placa);
          this.setState({acumAsignado:undefined});
          this.setState({nroTemp:0});
          await ObtenerAcumAsignado(nextProps.placa,this.changeAcumAsignado,this.changeNroTemp,this.state.changeAcumAsignadoRastreo,this.state.changeAcumsJsonRastreo,nextProps.o['ACUMS']);
          //this.state.changeAcumAsignadoRastreo(this.state.acumAsignado);
          ObtenerConductor(this.changeConductor,nextProps.o['ID_CONDUCTOR']);
         
        }
        this.setState({o:nextProps.o});
        this.setState({placa:nextProps.placa});
        this.datosDirectionRequest();
        this.setState({punto:nextProps.punto}); 
        this.setState({op:nextProps.op});
        this.tranformarAcumsJson(nextProps.o['ACUMS']);
       // this.state.changeAcumAsignado(this.state.acumAsignado);
      }
      catch(e){
          console.log(e);
      }    
    } 
    functionAlert(msg, myYes) {
      var confirmBox = $("#confirm");
      confirmBox.find(".message").text(msg);
      confirmBox.find(".yes").unbind().click(function() {
        confirmBox.hide();
      });
      confirmBox.find(".yes").click(myYes);
      confirmBox.show();
    } 
      
      render() {
        let propState = this.state.o;
        console.log(this.state.changeAcumAsignadoRastreo);
        console.log(propState);
        let propStateConductor = this.state.conductor;
        return (
            <div style={{width: '280px',border:'1px solid Lavender',backgroundColor:'white'}} key={'dKardex'+this.state.placa}>
            <div style={{border:'1px solid Lavender',backgroundColor:'#1F69AD', color:'white'}} key={'dKardexInf'+this.state.placa}><h5 style={{marginBottom:'0'}}>Info. Vehiculo</h5></div>
            <div style={{display:'flex', justifyContent:'spaceBetween'}} key={'dKardexP'+this.state.placa}>
            <div style={{width:'31%',backgroundColor:'white', color:'#2E2D2D'}}>Placa:</div>
            <div style={{width:'58%', color:'#2E2D2D'}}>{propState.placa}</div>
            <div style={{width:'12%', color:'#1F69AD',fontWeight: 'bold'}} onClick={()=>{this.state.changeAlturaGrid()}}>X</div>
            </div>
            <div style={{display:'flex', justifyContent:'spaceBetween'}} key={'dKardexC'+this.state.placa}>
            <div style={{width:'31%',backgroundColor:'white', color:'#2E2D2D'}}>Conductor:</div>
            <div style={{width:'70%', color:'#2E2D2D'}}>{propStateConductor.nombre + " " + propStateConductor.apellidoPaterno}</div>
            </div>
            <div style={{display:'flex', justifyContent:'spaceBetween'}} key={'dKardexUbi'+this.state.placa}>
            <div style={{width:'31%',backgroundColor:'white', color:'#2E2D2D'}}>Grupo:</div>
            <div style={{width:'70%', color:'#2E2D2D', marginLeft:'6px', fontSize:'11px'}}>{propState.grupo}</div>
            </div>
            <div style={{display:'flex', justifyContent:'spaceBetween'}} key={'dKardexCord'+this.state.placa}>
            <div style={{width:'31%',backgroundColor:'white', color:'#2E2D2D'}}>Coordenadas:</div>
            <div style={{width:'70%', color:'#2E2D2D', marginLeft:'6px'}}>{propState.LATITUD + ", " + propState.LONGITUD}</div>
            </div>
            <div style={{display:'flex', justifyContent:'spaceBetween'}} key={'dKardexUbi'+this.state.placa}>
            <div style={{width:'31%',backgroundColor:'white', color:'#2E2D2D'}}>Ubicacion:</div>
            <div style={{width:'70%', color:'#2E2D2D', marginLeft:'6px'}}>{this.state.dirLiteral}</div>
            </div>
            <div style={{color:'white',borderTop:'1px solid Lavender',backgroundColor:'#1F69AD'}}>Actividad de hoy</div>
            <div style={{display:'flex', justifyContent:'spaceBetween'}} key={'dKardexH'+this.state.placa}>
            <div style={{width:'35%',backgroundColor:'white', color:'#2E2D2D'}}><img src={street} alt='rojo' width='15px' height='15px' title='Km. total diario' /> {(propState.ACUM_ODOMETRO / 1000).toFixed(2)}Km</div>
            <div style={{width:'32%', color:'#2E2D2D'}}><img src={clockralenti} alt='rojo' width='15px' height='15px' title='tiempo total del vehiculo parado con motor encendido' /> {this.obtenerTiempo(propState.ACUM_RALENTI)}</div>
            <div style={{width:'32%', color:'#2E2D2D'}}><img src={clock} alt='rojo' width='15px' height='15px' title='tiempo total del vehiculo en encendido' /> {this.obtenerTiempo(propState.ACUM_ENCENDIDO)}</div>
            </div>
            <div style={{display:'flex', justifyContent:'spaceBetween'}} key={'dKardexTU'+this.state.placa}>
            <div style={{width:'31%',backgroundColor:'white', color:'#2E2D2D'}}>Ult. Evento:</div>
            <div style={{width:'70%', color:'#2E2D2D'}}>{this.obtenerFechaLocal(propState.FECHAHORA_EVENTO)}</div>
            </div>
            <div style={{display:'flex', justifyContent:'spaceBetween'}}>
              <div style={{display:'flex', justifyContent:'spaceBetween', width:'40%'}} key={'dKardexTV'+this.state.placa}> 
                <div style={{width:'30%',backgroundColor:'white', color:'#2E2D2D'}}><img src={battery} alt='rojo' width='20px' height='20px' /></div>
                <div style={{width:'70%', color:'#2E2D2D'}}>{propState.VOLTAJE_BATERIA + " V"}</div>
              </div>
              <div style={{display:'flex', justifyContent:'spaceBetween', width:'60%'}} key={'dKardexTVe'+this.state.placa}> 
                <div style={{width:'40%',backgroundColor:'white', color:'#2E2D2D'}}>Velocidad:</div>
                <div style={{width:'60%', color:'#2E2D2D'}}>{propState.VELOCIDAD.toFixed(0) + "Km"}</div>
              </div>
            </div>
            <div style={{display:'flex', justifyContent:'spaceBetween'}}>
            <div style={{display:'flex', justifyContent:'spaceBetween'}} key={'dKardexTGps'+this.state.placa}> 
              <div style={{width:'73%',backgroundColor:'white', color:'#2E2D2D'}}>Calidad GPS({propState.satelites}):</div>
                <div style={{width:'28%', color:'#2E2D2D'}}><div style={{width:'40px', marginLeft:'10px'}}>
                  {propState.CALIDAD_GPS==0&&<img src={signalbars0} alt='rojo' width='15px' height='15px' />}
                  {propState.CALIDAD_GPS==1&&<img src={signalbars1} alt='rojo' width='15px' height='15px' />}
                  {propState.CALIDAD_GPS==2&&<img src={signalbars2} alt='rojo' width='15px' height='15px' />}
                  {propState.CALIDAD_GPS==3&&<img src={signalbars3} alt='rojo' width='15px' height='15px' />}
                  {propState.CALIDAD_GPS==4&&<img src={signalbars4} alt='rojo' width='15px' height='15px' />}
                  {propState.CALIDAD_GPS==5&&<img src={signalbars5} alt='rojo' width='15px' height='15px' />}
                </div></div>
            </div>
            <div style={{display:'flex', justifyContent:'spaceBetween'}} key={'dKardexTgprs'+this.state.placa}> 
              <div style={{width:'70%',backgroundColor:'white', color:'#2E2D2D'}}>Calidad GPRS:</div>
                <div style={{width:'31%', color:'#2E2D2D'}}><div style={{width:'40px', marginLeft:'10px'}}>
                  {propState.CALIDAD_GPRS==0&&<img src={signalbars0} alt='rojo' width='15px' height='15px' />}
                  {propState.CALIDAD_GPRS==1&&<img src={signalbars1} alt='rojo' width='15px' height='15px' />}
                  {propState.CALIDAD_GPRS==2&&<img src={signalbars2} alt='rojo' width='15px' height='15px' />}
                  {propState.CALIDAD_GPRS==3&&<img src={signalbars3} alt='rojo' width='15px' height='15px' />}
                  {propState.CALIDAD_GPRS==4&&<img src={signalbars4} alt='rojo' width='15px' height='15px' />}
                  {propState.CALIDAD_GPRS==5&&<img src={signalbars5} alt='rojo' width='15px' height='15px' />}            
                </div></div>
            </div>
            
            </div>
            {this.state.acumAsignado!==undefined&& <div>
              <div style={{color:'white',borderTop:'1px solid Lavender',backgroundColor:'#1F69AD'}} onClick={()=>{/*this.state.changeShowSensores();this.state.changePlacaSensores(this.state.o['placa']);*/}}>Sensores</div>
              {/*<div style={{display:'flex', justifyContent:'spaceBetween', width:'60%'}} key={'dKardexTVe'+this.state.placa}> 
                <div style={{width:'60%',backgroundColor:'white', color:'#2E2D2D'}}>{this.state.acumAsignado['nombre']}</div>
                <div style={{width:'40%', color:'#2E2D2D'}}>{this.state.acumAsignado['formula']!==undefined?
                  eval(this.state.acumsJson[this.state.acumAsignado['AcumNro']]+this.state.acumAsignado['formula'])+ "C":this.state.acumsJson[this.state.acumAsignado['AcumNro']]+ "C"}</div>
            </div>*/}<div style={{height:'3vh',overflowY: 'scroll',overflowX:'hidden'}}>
            <Sensores popup={false} sensores={this.state.acumAsignado} acumsJson={this.state.acumsJson}/>
            </div>
            </div>
            }
                <div id="confirm">
                <div class="message">This is a warning message.</div>
                <button class="yes">OK</button>
                </div>
            </div>
            )
        }
    }
    export default KardexGrid;
