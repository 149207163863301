import React, { Component } from 'react';
import Placa from "./placa"
import './style.css';
import icono from './icono.png'
class SubGroup extends Component {
    constructor(props){
      super(props);
      this.state={
      placa:'',
      isHire:true,
      group1:this.props.group1,
      groupp:this.props.groupp,
      fObtenerVTikeado:this.props.f,
      google:this.props.google,
      fClearMArker: this.props.u,
      o:this.props.o,
      clearMarkerForSubGroup:this.props.clearMarkerForSubGroup,
      grupoName:this.props.grupoName,
      centrar:this.props.centrar,
      checkConductor:this.props.checkConductor,
      changeHistoricoShow:this.props.changeHistoricoShow,
      changePlacaHistorico:this.props.changePlacaHistorico,
      changeEnviarComandoShow:this.props.changeEnviarComandoShow,
      oPlaca:this.props.oPlaca,
      }
      this.handleClick = this.handleClick.bind(this); 
    }
  componentWillReceiveProps(nextProps){
      try{
        this.setState({o:nextProps.o});
      }
      catch(e){
        console.log(e);
      }
  } 
  handleClick(){
    if(this.state.isHire){
        this.setState({isHire:false});
    }
    else{
        this.setState({isHire:true});
    }
  }
  componentDidMount(){
    //console.log(this.state.groupp.length);
  }
  render() {
    return (
    <div id={"div"+this.state.group1} className='group' key={"div"+this.state.group1} >       
    {this.state.group1!=='Vacio'&&<div key={'d'+this.state.group1} className='group'  style={{borderTop:'1px solid Lavender',background:'white'}}><div style={{marginLeft:'5px',marginBottom:'0px',fontSize:'12px',fontWeight:'bold',color:'#757474',display:'flex',justifyContent:'spaceBetween'}} ><div><input type="checkbox" id={this.state.placa} className={"checkGrilla subGroup "+this.state.grupoName.replace(/ /g, "")+" "+this.state.group1.replace(/ /g, "")} defaultChecked onClick={()=>{this.state.clearMarkerForSubGroup(1,this.state.grupoName,this.state.group1,this.state.groupp)}}/></div><div onClick={()=>{this.handleClick()}}>{this.state.group1}</div></div></div>}
    {this.state.isHire &&
    <div key={"div2"+this.state.group1} >
    <div style={{borderRight:'1px solid white',background: '#1F69AD',display:'flex',justifyContent:'spaceBetween'}}>
    <div style={{width:'30px',borderRight:'1px solid white', color: 'grey'}}></div>
    <div style={{width:'80px',borderRight:'1px solid white', color: 'white',marginLeft:'5px'}}>Placa</div>
    <div style={{width:'48px',borderRight:'1px solid white', color: 'grey'}}></div>
    <div style={{width:'46px',borderRight:'1px solid white', color: 'grey'}}></div>
    </div>
    <div className="row3" style={{display:'block'}}>
    {this.state.groupp.map(p=>(<Placa changeEnviarComandoShow={this.state.changeEnviarComandoShow} changePlacaHistorico={this.state.changePlacaHistorico} changeHistoricoShow={this.state.changeHistoricoShow} centrar={this.state.centrar} google={this.state.google} pc={p} grupoName={this.state.grupoName} subGrupoName={this.state.group1} checkConductor={this.state.checkConductor} oPlaca={this.state.oPlaca} o={this.state.o[p]}  f={this.state.fObtenerVTikeado} u={this.state.fClearMArker} />))}
    </div>
    </div>
    }
    </div>
    )          
}
}
export default SubGroup;
