import React, { Component } from 'react';
//import 'ag-grid/dist/styles/ag-grid.css';
//import 'ag-grid/dist/styles/ag-theme-balham.css';
import Select2 from 'react-select';
import { Input, Button, Form, Media, CardHeader, Card, CardBody, Label } from 'reactstrap';
import {obtenerPlacas,mostrarHistorico,mostrarHistoricoConEvento} from './ajax_Historico';
import {obtenerTipoEvento} from './tipoEvento';
import GrillaSimple from './grillaSimple';
import { AgGridReact } from 'ag-grid-react';
//import "ag-grid-enterprise";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import browndot from './brown-dot.png';
import blueoffdot from './blueoff-dot.png';
import blueondot from './blueon-dot.png';
import play from './play.svg';
import pause from './pause.svg';
import stop from './stop4.png';
import park from './park.png';
import forward from './forward.svg';
import rewind from './rewind.svg';
import { DataTipoEvento } from './dataEventos';
import moment from 'moment';
import './style.css';
import menu from "../Rastreo/menu.svg";
//prueba

var google = window.google;
var listEvento=[];
let gridApi;
let fechaactual = new Date(Date.now());
let countCheck=-1;
let countLimit=0;
let posActual=0;
//new Date('2012.08.10')
var hoy = new Date();
var ayer = new Date((hoy.getTime()-86400000));
var semana = new Date((hoy.getTime()-604800000));


var mesAnteriorIni =hoy.getFullYear()+'-0'+hoy.getMonth()+'-01';
var mesAnteriorFin =hoy.getFullYear()+'-0'+hoy.getMonth()+'-31';
var mesActualIni = hoy.getFullYear()+'-0'+(hoy.getMonth()+1)+'-01';
var mesActualFin = hoy.getFullYear()+'-0'+(hoy.getMonth()+1)+'-31';
//console.log(mesAnteriorIni);
//console.log(mesActual);
var flightPath=[];
var SumKmXViaje=[];
var marker=[];
var markerViaje=[];
var SumKmXViajeVelocidad=[];
var markerViajeVelocidad=[];
let pathPrueba =[];
//------------count---------------//
let countExcesoVelocidad=0;
//------------Marker--------------//
var markerParking=[];
var markerStop=[];
var markerExcesoVelocidad=[];
var markerEventosGeneral=[];
var markerViajes=[];
//-------------------------------//
//---------InfoMarker------------//
var infoParking=[];
var infoStop=[];
var infoExcesoVelocidad=[];
var infoEventoGeneral=[];

//-------------------------------//
var EventoDisponibles
var auto;
function formato(h){
	var dd = h.getDate();
	dd = '0'+dd;
	var mm = h.getMonth()+1;
	mm='0'+mm;
	var yyyy = h.getFullYear();
	
	h=yyyy+'-'+mm+'-'+dd;
return h;

}

hoy=formato(hoy);
ayer=formato(ayer);
semana=formato(semana);

class Historico extends Component {	
	constructor(props) {
		super(props);
		this.map1 = undefined;
		this.sumTotalKm=0;
		this.state = {
			idEmpresa:'',
			statePrincipal:this.props.statePrincipal,
			historico:{},
			historicoConEvento:{},
			fechaIni:fechaactual,
			fechaFin:fechaactual,
			optionPlaca:[],
			horaIni:'00:00',
			horaFin:'23:59',
			esSub:this.props.statePrincipal['esSubCuenta'],
			changeHistoricoShow:this.props.changeHistoricoShow,
			selectedOption: null,
			showPersonalizado:false,
			showPorViaje:false,
			showPorViajeVelocidad:false,
			showPorSenal:false,
			showPorVelocidad:false,
			showEvento:false,
			showMarkerParking:false,
			showMarkerStop:false,
			showMarkerExcesoVelocidad:false,
			showMarkerEventoGeneral:false,
			showValEventoVelocidad:false,
			valEventoVelocidad:0,
			colorMParking:'Lavender',
			colorMStop:'Lavender',
			colorMExcesoVelocidad:'Lavender',
			colorMEventoGeneral:'Lavender',
			pausarRecorrido:false,
			unidad:'',
			class:'App',
			eventoGroup:[],
			count:{
				parking:0,
				stop:0,
				exVelocidad:0,
				condSinCinturon:0,
				fueraHorario:0
			},
			anchura:'80vw',
			anchoMapa:'0%',
			anchuraBoton:'20vw',
			flagKardexGridMinimizado:false,
			kmTotal:0,
			id:this.props,
			lastUpdateTimestamp: new Date().getTime(),
			refreshTimerID: 0,
			eventoSeleccionado: null,
			selectTipoHistorico:'simple',
			isClick:false,
			path:[],
			pathResumen:[],
			arryaKm:[],
			columnDefs: [
                {headerName: "Placa 2", field: "placa",  filter: true},
                {headerName: "Tipo Evento", field: "tipoEvento", filter: "agTextColumnFilter",
				filterParams: {
				  applyButton: true,
				  clearButton: true
				}},
				{headerName: "Fecha/Hora", valueGetter: (params)=>{return this.obtenerFechaLocal(params.data.fechaHora)}, resizable: true},
				{headerName: "Coordenadas", valueGetter: (params)=>{return params.data.lat+", "+params.data.lng}, resizable: true},
				{headerName: "Conductor", field: "conductor", resizable: true}
				
			],
			defaultColDef: { filter: true },
            rowData: []
		}
		this.changeOptionPlaca=this.changeOptionPlaca.bind(this);
		this.changeRowData=this.changeRowData.bind(this);
		this.changeHistoricoConEvento=this.changeHistoricoConEvento.bind(this);
		this.mostrarH=this.mostrarH.bind(this);
		this.mostrarMArker=this.mostrarMArker.bind(this);
	}
	changeAnchoMapa(){
		if(this.state.anchoMapa!=='100vw'){
			this.setState({anchoMapa:'100vw'});
		}else{
			this.setState({anchoMapa:'0%'});
		}
		
	}
	changeAnchura(){
		if(this.state.anchura==='80vw'){
			this.setState({anchura:'0vw'});
			this.setState({anchuraBoton:'0vw'})
			this.setState({class:'App-hidden'});
			this.setState({flagBotonMinimizado:true});
		}else{
			this.setState({anchura:'80vw'});
			this.setState({anchuraBoton:'20vw'})
			this.setState({class:'App'});
			this.setState({flagBotonMinimizado:false});
		}
		this.changeAnchoMapa();
		}
	changeOptionPlaca(optionPlaca){
		this.setState({optionPlaca});
	}
	changeRowData(rowData){
		this.setState({rowData});
	}
	changeHistoricoConEvento(historicoConEvento){
		this.setState({historicoConEvento})
	}
	onGridReady = params => {
		gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	
		params.api.sizeColumnsToFit();
		window.addEventListener("resize", function() {
		  setTimeout(function() {
			params.api.sizeColumnsToFit();
		  });
		});
	
		params.api.sizeColumnsToFit();
		};
		obtenerFechaLocal(d){
      
			let fechaserver = new Date (d);
			console.log(d);
			let fechalocal = fechaserver.getTime() - (fechaserver.getTimezoneOffset());
			fechalocal=String(new Date(fechalocal));
			return fechalocal.substring(3,23);
		 }
	mapInit() {
		var boltracksrl = {lat: -17.759, lng: -63.194};
		this.map1 = new google.maps.Map(
				document.getElementById('gmapsCanvasContainer'), 
				{
					zoom: 6, 
					center: boltracksrl,
					zoomControl: true,
					mapTypeControl: true,
					mapTypeControlOptions: {
						mapTypeIds: ['hybrid','roadmap','satellite','terrain'],
						position: google.maps.ControlPosition.TOP_RIGHT
					},
					streetViewControl: true,
					fullScreenControl: true,
					scrollwheel: true,
					noClear: true,
				}
			);
			//var centerControlDiv = document.createElement('div');
			//var centerControl = new this.Csv(centerControlDiv, this.map1, this.state.id, this);

			//centerControlDiv.index = 1;
			//this.map1.controls[google.maps.ControlPosition.RIGHT].push(centerControlDiv);

			//var centerControlDiv2 = document.createElement('div');
			//var centerControl2 = new this.Kml(centerControlDiv, this.map1, this.state.id, this);

			//centerControlDiv2.index = 1;
			//this.map1.controls[google.maps.ControlPosition.TOP_RIGHT].push(centerControlDiv2);
			this.map1.mapaElems = {};

	}
	Csv(controlDiv, map, ide, thet) {

		// Set CSS for the control border.
		var controlUI = document.createElement('div');
		controlUI.style.backgroundColor = '#fff';
		controlUI.style.border = '2px solid #fff';
		controlUI.style.borderRadius = '1px';
		controlUI.style.padding = '0px';
		controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
		controlUI.style.cursor = 'pointer';
		controlUI.style.marginBottom = '0px';
		controlUI.style.marginTop = '10px';
		controlUI.style.marginRight = '10px';
		controlUI.style.textAlign = 'center';
		controlUI.title = 'Click to geocerca the map';
		controlDiv.appendChild(controlUI);

		// Set CSS for the control interior.
		var controlText = document.createElement('div');
		controlText.style.color = 'rgb(25,25,25)';
		controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
		controlText.style.fontSize = '16px';
		controlText.style.lineHeight = '35px';
		controlText.style.paddingLeft = '5px';
		controlText.style.paddingRight = '5px';
		controlText.innerHTML = 'CSV';
		controlUI.appendChild(controlText);

		// Setup the click event listeners: simply set the map to Chicago.
		controlUI.addEventListener('click', function() {
			gridApi.exportDataAsCsv();
			//ObtenerGeocera(thet,ide);
		});

	}	
Kml(controlDiv, map, ide, thet) {
	// Set CSS for the control border.
	var controlUI = document.createElement('div');
	controlUI.style.backgroundColor = '#fff';
	controlUI.style.border = '2px solid #fff';
	controlUI.style.borderRadius = '1px';
	controlUI.style.padding = '0px';
	controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
	controlUI.style.cursor = 'pointer';
	controlUI.style.marginBottom = '0px';
	controlUI.style.marginTop = '10px';
	controlUI.style.marginRight = '10px';
	controlUI.style.textAlign = 'center';
	controlUI.title = 'Exportar a KML';
	controlDiv.appendChild(controlUI);

	
	let fechaHoraIni = new Date(thet.state.fechaIni+"T"+thet.state.horaIni+"-04:00").getTime();
	let fechaHoraFin = new Date(thet.state.fechaFin+"T"+thet.state.horaFin+"-04:00").getTime();
	console.log(`<a download href='http://gestion.boltrack.net/kmlapi/obtenerkml/${thet.state.idEmpresa}/${thet.state.unidad}/${fechaHoraIni}/${fechaHoraFin}/historico.kml'><img alt="Google Earth" src="http://gestion.boltrack.net/assets/ge.png"/></a>`);
	if (!isNaN(fechaHoraFin) && !isNaN(fechaHoraIni) && thet.state.unidad) {
		controlUI.innerHTML = `<a download href='http://gestion.boltrack.net/kmlapi/obtenerkml/${thet.state.idEmpresa}/${thet.state.unidad}/${fechaHoraIni}/${fechaHoraFin}/historico.kml'><img alt="Google Earth" src="http://gestion.boltrack.net/assets/ge.png"/></a>`;
	}
	

	}	
	
	changeEv(){
		//console.log('entro');
		if(this.state.showMarkerEventoGeneral===false){
			this.setState({showMarkerEventoGeneral:true});
			this.setState({colorMEventoGeneral:'blue'});
		}
		else{
			this.setState({showMarkerEventoGeneral:false});
			this.setState({colorMEventoGeneral:'Lavender'});
		}
		//console.log(this.state.showMarkerEventoGeneral);
	}
	changeEx(){
		//console.log('entro');
		if(this.state.showMarkerExcesoVelocidad===false){
			this.setState({showMarkerExcesoVelocidad:true});
			this.setState({showValEventoVelocidad:true});
			this.setState({colorMExcesoVelocidad:'blue'});
		}
		else{
			this.setState({showMarkerExcesoVelocidad:false});
			this.setState({showValEventoVelocidad:false});
			this.setState({colorMExcesoVelocidad:'Lavender'});
		}
		//console.log(this.state.showMarkerExcesoVelocidad);
	}
	changeP(){
		//console.log('entro');
		if(this.state.showMarkerParking===false){
			this.setState({showMarkerParking:true});
			this.setState({colorMParking:'blue'});
		}
		else{
			this.setState({showMarkerParking:false});
			this.setState({colorMParking:'Lavender'});
		}
		//console.log(this.state.showMarkerParking);
	}
	changeS(){
		//console.log('entro');
		if(this.state.showMarkerStop===false){
			this.setState({showMarkerStop:true});
			this.setState({colorMStop:'blue'});
		}
		else{
			this.setState({showMarkerStop:false});
			this.setState({colorMStop:'Lavender'});
		}
		//console.log(this.state.showMarkerStop);
	}
	changeShowPersonalizado(){
		//console.log('entro');
		if(this.state.showPersonalizado===false){
			this.setState({showPersonalizado:true});
		}
		else{
			this.setState({showPersonalizado:false});
		}
		//console.log(this.state.showPersonalizado);
	}
	changeShowEvento(){
		//console.log('entro');
		if(this.state.showEvento===false){
			this.setState({showEvento:true});
		}
		else{
			this.setState({showEvento:false});
		}
		//console.log(this.state.showEvento);
	}

	changePausarRecorrido(){
		//console.log('entro');
		if(this.state.pausarRecorrido===false){
			this.setState({pausarRecorrido:true});
		}
		else{
			this.setState({pausarRecorrido:false});
		}
	}
	obtenerListaEvento(){
		let content='';
		
		if(markerStop.length>0){
    content+="<div><input type='checkbox' >Parada</input>"
		}
		if(markerExcesoVelocidad.length>0){
		content+="<div><input type='checkbox' >Exceso Velocidad</input>"
		}
		if(markerEventosGeneral.length>0){
		content+="<div><input type='checkbox' >Evento Generales</input>"
		}
		if(markerParking.length>0){
		content+="<div><input type='checkbox' >Parqueado</input>"
		}
		return content;
	}
	limpiarHistorico(){
		this.setState({historico:{}});
	}
	//prueba de marker historico
	obtenerListEvento(){
		try{
			listEvento=[];
			var leh = this.state.historicoConEvento;		
			listEvento = leh.map(l => (l.tipoEvento));
		}
		catch(e){
			console.log(e);
		}
		
	}
	 
	///////////////
	obtenerMarkerStop(re){
		let imgdot;
		let countStop=0;
		for(let i=0;i<Object.keys(re).length;i++){
			//console.log('entro')
			if(re[i].tipoEvento==='Detencion Prolongada'){		
				imgdot = stop;							
				markerStop[countStop] = new google.maps.Marker({
				position: re[i],
				map: this.map1,
				icon: {
					url: imgdot
					},
				title: ''
			});
			
				infoStop[countStop] = new google.maps.InfoWindow({
					content: '<div><div>Tipo de evento:'+re[i].tipoEvento+'</div><div>fecha/Hora:'+this.obtenerFechaLocal(re[i].fechaHora)+'</div><div>cant. Satelites:'+re[i].satelites+'</div></div>'
				});
				markerStop[countStop].addListener('click', 
				((countStop) => {
				return ()=>{ infoStop[countStop].open(this.map1, markerStop[countStop]) };
				}) (countStop)
			);
				countStop++;
			}
		}
	}
	obtenerMarkerParking(re){
		let imgdot;
		let countParking=0;
		infoParking=[];
		for(let i=0;i<Object.keys(re).length;i++){
			//console.log('entro')
			if(re[i].tipoEvento==='Apagado'){		
				imgdot = park;							
				markerParking[countParking] = new google.maps.Marker({
				position: re[i],
				map: this.map1,
				icon: {
					url: imgdot
					},
				title: ''
			});
				//console.log(infoParking);
				infoParking[countParking] = new google.maps.InfoWindow({
					content: '<div><div>Tipo de evento:'+re[i].tipoEvento+'</div><div>fecha/Hora:'+this.obtenerFechaLocal(re[i].fechaHora)+'</div><div>cant. Satelites:'+re[i].satelites+'</div></div>'
				});
				//console.log(infoParking);
				markerParking[countParking].addListener('click', 
				((countParking) => {
				return ()=>{ infoParking[countParking].open(this.map1, markerParking[countParking]) };
				}) (countParking)
			);
				countParking++;
			}
		}
	}
	obtenerMarkerExcesoVelocidad(re){
		let imgdot;
		countExcesoVelocidad=0;
		for(let i=0;i<Object.keys(re).length;i++){
			//console.log(re);
			if(re[i].velocidad>this.state.valEventoVelocidad){		
				imgdot = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";							
				markerExcesoVelocidad[countExcesoVelocidad] = new google.maps.Marker({
				position: re[i],
				map: this.map1,
				icon: {
					url: imgdot
					}
				
			});
			
			infoExcesoVelocidad[countExcesoVelocidad] = new google.maps.InfoWindow({
				content: "<div><div>Velocidad:"+re[i].velocidad+"km </div><div>fecha/Hora:"+this.obtenerFechaLocal(re[i].fechaHora)+"</div><div>cant. Satelites:"+re[i].satelites+"</div></div>"
				});
			//console.log(infoExcesoVelocidad[countExcesoVelocidad])	;
			markerExcesoVelocidad[countExcesoVelocidad].addListener('click', 
			((countExcesoVelocidadP) => {
				return ()=>{ infoExcesoVelocidad[countExcesoVelocidadP].open(this.map1, markerExcesoVelocidad[countExcesoVelocidadP]) };
				}) (countExcesoVelocidad)
			);
			countExcesoVelocidad++;

			
		}
	}
}
	
	obtenerMarkerEventoGeneral(re){
		let imgdot;
		let countEventoGeneral=0;
		for(let i=0;i<Object.keys(re).length;i++){
			//console.log('entro')
			if(re[i].tipoEvento==='Encendido'){		
				imgdot = blueondot;							
				markerEventosGeneral[countEventoGeneral] = new google.maps.Marker({
				position: re[i],
				map: this.map1,
				icon: {
					url: imgdot
					},
				title: ''
			});
		}
			if(re[i].tipoEvento==='Inicio cond. fuera de horario'){		
				imgdot = "http://maps.google.com/mapfiles/ms/icons/orange-dot.png";							
				markerEventosGeneral[countEventoGeneral] = new google.maps.Marker({
				position: re[i],
				map: this.map1,
				icon: {
					url: imgdot
					},
				title: ''
			});
		}
			if(re[i].tipoEvento==='Fin Cond. fuera de Horario'){		
				imgdot = "http://maps.google.com/mapfiles/ms/icons/orange-dot.png";							
				markerEventosGeneral[countEventoGeneral] = new google.maps.Marker({
				position: re[i],
				map: this.map1,
				icon: {
					url: imgdot
					},
				title: ''
			});
		}
			if(re[i].tipoEvento==='Alerta de Impacto'){		
				imgdot = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";							
				markerEventosGeneral[countEventoGeneral] = new google.maps.Marker({
				position: re[i],
				map: this.map1,
				icon: {
					url: imgdot
					},
				title: ''
			});
		}
			if(re[i].tipoEvento==='Aceleracion brusca'){		
				imgdot = browndot;
				markerEventosGeneral[countEventoGeneral] = new google.maps.Marker({
				position: re[i],
				map: this.map1,
				icon: {
					url: imgdot
					},
				title: ''
			});
		}
			if(re[i].tipoEvento==='Frenada Brusca'){		
				imgdot = browndot;							
				markerEventosGeneral[countEventoGeneral] = new google.maps.Marker({
				position: re[i],
				map: this.map1,
				icon: {
					url: imgdot
					},
				title: ''
			});
		}
			
				infoEventoGeneral[countEventoGeneral] = new google.maps.InfoWindow({
				content: '<div><div>Tipo de evento:'+re[i].tipoEvento+'</div><div>fecha/Hora:'+this.obtenerFechaLocal(re[i].fechaHora)+'</div><div>cant. Satelites:'+re[i].satelites+'</div></div>'
				});
				if(markerEventosGeneral[countEventoGeneral]!==undefined){
			  markerEventosGeneral[countEventoGeneral].addListener('click', 
				((countEventoGeneral) => {
				return ()=>{ infoEventoGeneral[countEventoGeneral].open(this.map1, markerEventosGeneral[countEventoGeneral]) };
				}) (countEventoGeneral)
			);}
			countEventoGeneral++;
		}
	}
	zoomToObject(obj){
		try{
		var bounds = new google.maps.LatLngBounds();
		var points = obj.getPath().getArray();
		for (var n = 0; n < points.length ; n++){
			bounds.extend(points[n]);
		}
		this.map1.fitBounds(bounds);
		this.map1.setZoom(9);
	}
	catch(e){
		console.log(e);
	}
	}
	obtenerTotalKm(re,tipo){
		if(tipo!=='porviajesvelocidad'&&tipo!=='simple'){
		let pathHistorico=re;
		let sumTotal = []
		let ODO_SinError=0;
		let errorOdometro=false;    //si el valor del odometro es menor al anterior el mismo dia
			var Sum=0;
			var flag=true;
			console.log(re);			
			for(let i =0;i<Object.keys(pathHistorico).length;i++){
					if(i>0){
							if(flag){
								Sum=pathHistorico[i].ACUM_ODOMETRO;
								sumTotal.push(pathHistorico[i].ACUM_ODOMETRO);
							}
							let ofa = this.obtenerFechaLocal(pathHistorico[i].fechaHora);
							let ofa2 = this.obtenerFechaLocal(pathHistorico[i-1].fechaHora);
							//console.log(ofa);
							ofa = new Date(ofa);
							ofa2 = new Date(ofa2);
							ofa=(ofa.getDate()+"-"+(ofa.getMonth()+1));
							ofa2=(ofa2.getDate()+"-"+(ofa2.getMonth()+1));
							if(!errorOdometro){
									if((pathHistorico[i].ACUM_ODOMETRO<pathHistorico[i-1].ACUM_ODOMETRO)&&(ofa!==ofa2))
									{										
										Sum+=pathHistorico[i-1].ACUM_ODOMETRO;
										errorOdometro=true;
										flag=false;
									}
									if((pathHistorico[i].ACUM_ODOMETRO<pathHistorico[i-1].ACUM_ODOMETRO)&&(ofa===ofa2))
									{
										errorOdometro=true;
										flag=false
										ODO_SinError=pathHistorico[i-1].ACUM_ODOMETRO;
									}
									if((i===(Object.keys(pathHistorico).length-1))&&(flag===false)){
										Sum+=pathHistorico[i].ACUM_ODOMETRO;
									}
						}else{
									
									if((i<(Object.keys(pathHistorico).length-1))&&(flag===false)){
										if((pathHistorico[i].ACUM_ODOMETRO-pathHistorico[i-1].ACUM_ODOMETRO)>0){
											Sum+=(pathHistorico[i].ACUM_ODOMETRO-pathHistorico[i-1].ACUM_ODOMETRO);
										}
										
										//sumTotal.push(pathHistorico[i].ACUM_ODOMETRO);
									}
						}
						}
			}
			Sum=Sum/1000;
			console.log(pathHistorico);
			console.log(sumTotal);
			this.setState({kmTotal:Sum.toFixed(1)});
		
	}
}
	obtenerContarEventos(re){
		let imgdot;
		let count={
			parking:0,
			stop:0,
			exVelocidad:0,
			condSinCinturon:0,
			fueraHorario:0
		};
		let 
		infoParking=[];
		for(let i=0;i<Object.keys(re).length;i++){
			//console.log('entro')
			if(re[i].tipoEvento==='Apagado'){						
				count['parking']++;
			}
			if(re[i].tipoEvento==='Detencion Prolongada'){						
				count['stop']++;
			}
			if((re[i].tipoEvento==='Exceso en zona')&&(re[i].tipoEvento==='Exceso en asfalto')&&(re[i].tipoEvento==='Exceso Velocidad 30')&&(re[i].tipoEvento==='Exceso Velocidad 50')){						
				count['exVelocidad']++;
			}
			if(re[i].tipoEvento==='Conduccion sin cinturon de seguridad'){						
				count['condSinCinturon']++;
			}
			if(re[i].tipoEvento==='Inicio cond. fuera de horario'){						
				count['fueraHorario']++;
			}
		}
		this.setState({count:count});
	}
	uncheckedH(){
		flightPath[0].setMap(null);
	}
	mostrarMArker(re){
		
		let valEventoGroup='';
		for(let i=0;i<Object.keys(this.state.eventoGroup).length;i++){
			valEventoGroup=this.state.eventoGroup[i].value;
			console.log(valEventoGroup);
			if((this.state.showMarkerParking)||(this.state.eventoGroup[i].value==='Apagado')){
				this.obtenerMarkerParking(re);
			}
			if((this.state.showMarkerStop)||(this.state.eventoGroup[i].value==="Detencion Prolongada")){
				//console.log('entro detencion prolongado');
				this.obtenerMarkerStop(re);
			}
			if((this.state.showMarkerExcesoVelocidad)||(this.state.eventoGroup[i].value==='Exceso Vel. Personalizada')){
				
				//console.log(this.state.valEventoVelocidad);
				this.obtenerMarkerExcesoVelocidad(re);
			}
			if((this.state.showMarkerEventoGeneral)||(this.state.eventoGroup[i].value==='Eventos General')){
				this.obtenerMarkerEventoGeneral(re);
			}
		}
	}
	mostrarH(re,tipo){
		flightPath=[];
		let sumTotal=[];
		let ODO_SinError;
					switch(tipo){
						case 'simple':
									if(!flightPath===undefined)	{	
										flightPath.setMap(null);
									}
									else{
									sumTotal =[]
									var pathHistorico = re;
									//console.log(pathHistorico);
									////////////////////////-/////////////////////////////////////											
										//console.log(Object.keys(pathHistorico));
									
											var Sum=0;
											var flag=true;
											let sumTotalXviaje=[];
											let errorOdometro=false;
											let arrayprueba =[]
											
											console.log(arrayprueba);
											
											//console.log(Object.keys(pathHistorico[x]).length);
											for(let i =0;i<Object.keys(pathHistorico).length;i++){
												if((i===0)&&(pathHistorico[i+1]!==undefined)){
												if(pathHistorico[i].ACUM_ODOMETRO>pathHistorico[i+1].ACUM_ODOMETRO){
													pathHistorico[i].ACUM_ODOMETRO=pathHistorico[i+1].ACUM_ODOMETRO;
												}
											}
												if(i>0){
													if(flag){
														if(pathHistorico[i+1]!==undefined){
															if(pathHistorico[i].ACUM_ODOMETRO>pathHistorico[i+1].ACUM_ODOMETRO){
																
																
																Sum=pathHistorico[i+1].ACUM_ODOMETRO;
																if(sumTotal!==undefined){
																sumTotal.push(pathHistorico[i].ACUM_ODOMETRO);
																}
															}
														}else{
																Sum=pathHistorico[i].ACUM_ODOMETRO;
																//sumTotal.push(pathHistorico[i].ACUM_ODOMETRO);
														}
															
														
														
													}
													let ofa = this.obtenerFechaLocal(pathHistorico[i].fechaHora);
													let ofa2 = this.obtenerFechaLocal(pathHistorico[i-1].fechaHora);
													//console.log(ofa);
													ofa = new Date(ofa);
													ofa2 = new Date(ofa2);
													ofa=(ofa.getDate()+"-"+(ofa.getMonth()+1));
													ofa2=(ofa2.getDate()+"-"+(ofa2.getMonth()+1));
													if(!errorOdometro){
															if((pathHistorico[i].ACUM_ODOMETRO<pathHistorico[i-1].ACUM_ODOMETRO)&&(ofa!==ofa2))
															{			
																if((Sum+pathHistorico[i])>0){							
																Sum+=pathHistorico[i-1].ACUM_ODOMETRO;
																}
																
															
																
																errorOdometro=true;
																flag=false;
															}
															if(pathHistorico[i+1]!==undefined){
																if((pathHistorico[i].ACUM_ODOMETRO>pathHistorico[i+1].ACUM_ODOMETRO)&&(ofa===ofa2))
																{
																	errorOdometro=true;
																	flag=false
																	ODO_SinError=pathHistorico[i-1].ACUM_ODOMETRO;
																}
															}
															if((pathHistorico[i].ACUM_ODOMETRO<pathHistorico[i-1].ACUM_ODOMETRO)&&(ofa===ofa2))
															{
																errorOdometro=true;
																flag=false
																ODO_SinError=pathHistorico[i-1].ACUM_ODOMETRO;
															}
															if((i===(Object.keys(pathHistorico).length-1))&&(flag===false)){
																if((Sum+pathHistorico[i])>0){
																Sum+=pathHistorico[i].ACUM_ODOMETRO;
																}
																																
															}
												}else{
															
															if((i<(Object.keys(pathHistorico).length-1))&&(flag===false)){
																if((pathHistorico[i].ACUM_ODOMETRO-pathHistorico[i-1].ACUM_ODOMETRO)>0){
																	if((Sum+pathHistorico[i].ACUM_ODOMETRO)>0){
																	Sum+=(pathHistorico[i].ACUM_ODOMETRO-pathHistorico[i-1].ACUM_ODOMETRO);
																
																	
																}
																}
																
																//sumTotal.push(pathHistorico[i].ACUM_ODOMETRO);
															}
												}
												}
											
											
										pathHistorico[1].KmViaje=Sum-pathHistorico[0].ACUM_ODOMETRO;
										console.log(sumTotalXviaje);
										SumKmXViaje.push(Sum);}
//////////////-----------------------------------------------------//////
									flightPath = new google.maps.Polyline({
										path: pathHistorico,
										geodesic: true,
										icons: [{
											icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
											offset: '100%',
											repeat: '50px'
											}],
										strokeColor: '#008000',
										strokeOpacity: 1.0,
										strokeWeight: 2
										});
								
										flightPath.setMap(this.map1);
										this.zoomToObject(flightPath);
										if(pathHistorico[1]!=undefined){
											this.setState({kmTotal:(pathHistorico[1].KmViaje/1000).toFixed(1)});
											this.sumTotalKm=pathHistorico[1].KmViaje/1000;
											console.log('totalkm'+pathHistorico[1].KmViaje);
											console.log('sumtotalkm'+	this.sumTotalKm);
										}else{
											this.setState({kmTotal:0});
											this.sumTotalKm=0;
										}
										this.setState({pathResumen:re});
										
									}
						break;
						case 'porviaje':
									//console.log('entro a por viajes');
									SumKmXViaje=[];
									//let pathHistorico2=re;
									sumTotal = [];
									ODO_SinError=0;
									let errorOdometro=false;    //si el valor del odometro es menor al anterior el mismo dia
									var Sum=0;
									var flag=true;
									var imgdot = "";
									let path=this.obtenerViajes(re);
									console.log(path);
										if(!flightPath===undefined)	{	
											flightPath.setMap(null);
										}
										else{
										var pathHistorico = path;
										
										//console.log(Object.keys(pathHistorico));
										for(let x=0;x<Object.keys(pathHistorico).length;x++){
											var Sum=0;
											var flag=true;
											let sumTotalXviaje=[];

											for(let i =0;i<Object.keys(pathHistorico[x]).length;i++){
												if((i===0)&&(pathHistorico[x][i+1]!==undefined)){
													if(pathHistorico[x][i].ACUM_ODOMETRO>pathHistorico[x][i+1].ACUM_ODOMETRO){
														pathHistorico[x][i].ACUM_ODOMETRO=pathHistorico[x][i+1].ACUM_ODOMETRO;
													}
												}
												if((i===pathHistorico[x].length-1)&&(pathHistorico[x][i-1].ACUM_ODOMETRO>pathHistorico[x][i].ACUM_ODOMETRO))
												{
													pathHistorico[x][i].ACUM_ODOMETRO=pathHistorico[x][i-1].ACUM_ODOMETRO;
												}
												if(i>0){
													if(flag){
														if(pathHistorico[x][i+1]!==undefined){
															if(pathHistorico[x][i].ACUM_ODOMETRO>pathHistorico[x][i+1].ACUM_ODOMETRO){
																if(x===7){
																	console.log(pathHistorico[7][0].ACUM_ODOMETRO);
																}
																
																Sum=pathHistorico[x][i+1].ACUM_ODOMETRO;
																sumTotal.push(pathHistorico[x][i].ACUM_ODOMETRO);
															}
														}else{
																Sum=pathHistorico[x][i].ACUM_ODOMETRO;
																sumTotal.push(pathHistorico[x][i].ACUM_ODOMETRO);
														}
															
														
														if(x===8){
															console.log(pathHistorico[8][i].ACUM_ODOMETRO);
														}
													}
													let ofa = this.obtenerFechaLocal(pathHistorico[x][i].fechaHora);
													let ofa2 = this.obtenerFechaLocal(pathHistorico[x][i-1].fechaHora);
													ofa = new Date(ofa);
													ofa2 = new Date(ofa2);
													ofa=(ofa.getDate()+"-"+(ofa.getMonth()+1));
													ofa2=(ofa2.getDate()+"-"+(ofa2.getMonth()+1));
													if(!errorOdometro){
															if((pathHistorico[x][i].ACUM_ODOMETRO<pathHistorico[x][i-1].ACUM_ODOMETRO)&&(ofa!==ofa2))
															{			
																if((Sum+pathHistorico[x][i])>0){							
																Sum+=pathHistorico[x][i-1].ACUM_ODOMETRO;
																}																
									
																errorOdometro=true;
																flag=false;
															}
															if(pathHistorico[x][i+1]!==undefined){
																if((pathHistorico[x][i].ACUM_ODOMETRO>pathHistorico[x][i+1].ACUM_ODOMETRO)&&(ofa===ofa2))
																{
																	errorOdometro=true;
																	flag=false
																	ODO_SinError=pathHistorico[x][i-1].ACUM_ODOMETRO;
																}
															}
															if((pathHistorico[x][i].ACUM_ODOMETRO<pathHistorico[x][i-1].ACUM_ODOMETRO)&&(ofa===ofa2))
															{
																errorOdometro=true;
																flag=false
																ODO_SinError=pathHistorico[x][i-1].ACUM_ODOMETRO;
															}
															if((i===(Object.keys(pathHistorico[x]).length-1))&&(flag===false)){
																if((Sum+pathHistorico[x][i])>0){
																Sum+=pathHistorico[x][i].ACUM_ODOMETRO;
																}
																
															}
												}else{
															if((i<(Object.keys(pathHistorico[x]).length-1))&&(flag===false)){
																if((pathHistorico[x][i].ACUM_ODOMETRO-pathHistorico[x][i-1].ACUM_ODOMETRO)>0){
																	if((Sum+pathHistorico[x][i].ACUM_ODOMETRO)>0){
																	Sum+=(pathHistorico[x][i].ACUM_ODOMETRO-pathHistorico[x][i-1].ACUM_ODOMETRO);
																
																}
																}
																
																//sumTotal.push(pathHistorico[i].ACUM_ODOMETRO);
															}
												}
												}
											}
											
										path[x][1].KmViaje=Sum;
										console.log(sumTotalXviaje);
										SumKmXViaje.push(Sum);

										//////---------Polyline--------------//////
										if(((pathHistorico[x][1].KmViaje-pathHistorico[x][1].ACUM_ODOMETRO)/1000)>0){
											this.sumTotalKm+=(pathHistorico[x][1].KmViaje-pathHistorico[x][1].ACUM_ODOMETRO)/1000;
										}

										if(pathHistorico[x][1]!==undefined){
										flightPath[x] = new google.maps.Polyline({
											path:pathHistorico[x],
											geodesic: true,
											icons: [{
												icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
												offset: '100%',
												repeat: '50px'
												}],
											strokeColor:pathHistorico[x][0].rgba,//Object.keys(pathHistorico[x])[0].rgba ,
											strokeOpacity: 1.0,
											strokeWeight: 2
											});
									
											flightPath[x].setMap(this.map1);	
										}
										}
									}
										//console.log(SumKmXViaje);
										console.log(path[0][0]);
										if(path[0][0]===undefined){
											
											path[0][0]=path[0][1];
										}
										
										console.log(pathResultado);
										console.log(this.sumTotalKm);
										this.setState({path:path});
										this.setState({kmTotal:this.sumTotalKm.toFixed(1)});
										this.setState({arrayKm:SumKmXViaje});
										this.setState({pathResumen:re});
										console.log(flightPath[0]);
										this.zoomToObject(flightPath[0]);
									
										
						break;
						case 'porviajesvelocidad':
						this.sumTotalKm=0;
	
										let pathResultado=[];
										let countPorViajeVelocidad=0;
										SumKmXViaje=[];
									
										var imgdot = "";
										let path3=this.obtenerViajesVelocidad(re);
										console.log(path3);
										console.log(re);
											if(!flightPath===undefined)	{	
												flightPath.setMap(null);
											}
											else{
											var pathHistorico = path3;
											console.log(path3);
											//console.log(Object.keys(pathHistorico));
											for(let x=0;x<Object.keys(pathHistorico).length;x++){
												var Sum=0;
												var flag=true;
												let sumTotalXviaje=[];
	
												
												
												//console.log(Object.keys(pathHistorico[x]).length);
												for(let i=0;i<Object.keys(pathHistorico[x]).length;i++){
													if((i===0)&&(pathHistorico[x][i+1]!==undefined)){
													if(pathHistorico[x][i].ACUM_ODOMETRO>pathHistorico[x][i+1].ACUM_ODOMETRO){
														pathHistorico[x][i].ACUM_ODOMETRO=pathHistorico[x][i+1].ACUM_ODOMETRO;
													}
												}
												if((i===pathHistorico[x].length-1)&&(pathHistorico[x][i-1].ACUM_ODOMETRO>pathHistorico[x][i].ACUM_ODOMETRO))
												{
													pathHistorico[x][i].ACUM_ODOMETRO=pathHistorico[x][i-1].ACUM_ODOMETRO;
												}
													if(i>0){
														if(flag){
															if(pathHistorico[x][i+1]!==undefined){
																if(pathHistorico[x][i].ACUM_ODOMETRO>pathHistorico[x][i+1].ACUM_ODOMETRO){
																	if(x===7){
																		console.log(pathHistorico[7][0].ACUM_ODOMETRO);
																	}
																	
																	Sum=pathHistorico[x][i+1].ACUM_ODOMETRO;
																	//sumTotal.push(pathHistorico[x][i].ACUM_ODOMETRO);
																}
															}else{
																	Sum=pathHistorico[x][i].ACUM_ODOMETRO;
																	//sumTotal.push(pathHistorico[x][i].ACUM_ODOMETRO);
															}
																
															
															if(x===8){
																console.log(pathHistorico[8][i].ACUM_ODOMETRO);
															}
														}
														let ofa = this.obtenerFechaLocal(pathHistorico[x][i].fechaHora);
														let ofa2 = this.obtenerFechaLocal(pathHistorico[x][i-1].fechaHora);
														//console.log(ofa);
														ofa = new Date(ofa);
														ofa2 = new Date(ofa2);
														ofa=(ofa.getDate()+"-"+(ofa.getMonth()+1));
														ofa2=(ofa2.getDate()+"-"+(ofa2.getMonth()+1));
														if(!errorOdometro){
																if((pathHistorico[x][i].ACUM_ODOMETRO<pathHistorico[x][i-1].ACUM_ODOMETRO)&&(ofa!==ofa2))
																{			
																	if((Sum+pathHistorico[x][i])>0){							
																	Sum+=pathHistorico[x][i-1].ACUM_ODOMETRO;
																	}
																	
																	if(x===8){
																		console.log(pathHistorico[8][i].ACUM_ODOMETRO);
																	}
																	
																	errorOdometro=true;
																	flag=false;
																}
																if(pathHistorico[x][i+1]!==undefined){
																	if((pathHistorico[x][i].ACUM_ODOMETRO>pathHistorico[x][i+1].ACUM_ODOMETRO)&&(ofa===ofa2))
																	{
																		errorOdometro=true;
																		flag=false
																		ODO_SinError=pathHistorico[x][i-1].ACUM_ODOMETRO;
																			if(pathHistorico[x][i+2]!=undefined){
																	if(pathHistorico[x][i+1].ACUM_ODOMETRO<pathHistorico[x][i+2].ACUM_ODOMETRO){
																			Sum+=(pathHistorico[x][i].ACUM_ODOMETRO);
																	}}
																	}
																}
																if((pathHistorico[x][i].ACUM_ODOMETRO<pathHistorico[x][i-1].ACUM_ODOMETRO)&&(ofa===ofa2))
																{
																	errorOdometro=true;
																	flag=false;
																	ODO_SinError=pathHistorico[x][i-1].ACUM_ODOMETRO;
																}
																if((i===(Object.keys(pathHistorico[x]).length-1))&&(flag===false)){
																	if((Sum+pathHistorico[x][i])>0){
																	Sum+=pathHistorico[x][i].ACUM_ODOMETRO;
																	}
																	if(x===8){
																		console.log(pathHistorico[8][i].ACUM_ODOMETRO);
																	}
																	
																}
													}else{
																
																if((i<(Object.keys(pathHistorico[x]).length-1))&&(flag===false)){
																	if((pathHistorico[x][i].ACUM_ODOMETRO-pathHistorico[x][i-1].ACUM_ODOMETRO)>0){
																		if((Sum+pathHistorico[x][i].ACUM_ODOMETRO)>0){
																		Sum+=(pathHistorico[x][i].ACUM_ODOMETRO-pathHistorico[x][i-1].ACUM_ODOMETRO);
																		if(x===8){
																			console.log(pathHistorico[8][i-1].ACUM_ODOMETRO);
																			console.log(pathHistorico[8][i].ACUM_ODOMETRO);
																		}
																		
																	}
																	}
																	
																	//sumTotal.push(pathHistorico[i].ACUM_ODOMETRO);
																}
													}
													}
												}
												
											path3[x][1].KmViaje=Sum;
											console.log(sumTotalXviaje);
											SumKmXViaje.push(Sum);
										

										//////---------Polyline--------------//////
										
										if(((pathHistorico[x][1].KmViaje-pathHistorico[x][1].ACUM_ODOMETRO)/1000)>0){
											this.sumTotalKm+=(pathHistorico[x][1].KmViaje-pathHistorico[x][1].ACUM_ODOMETRO)/1000;
										}
										console.log('km'+(pathHistorico[x][1].KmViaje-pathHistorico[x][1].ACUM_ODOMETRO)/1000);
										
										if((pathHistorico[x][1]!==undefined)&&(((pathHistorico[x][1].KmViaje-pathHistorico[x][1].ACUM_ODOMETRO)/1000)>=0)){
										pathResultado.push(pathHistorico[x]);
										flightPath[countPorViajeVelocidad] = new google.maps.Polyline({
											path:pathHistorico[x],
											geodesic: true,			
											icons: [{
												icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
												offset: '100%',
												repeat: '50px'
												}],
											strokeColor:pathHistorico[x][0].rgba,//Object.keys(pathHistorico[x])[0].rgba ,
											strokeOpacity: 1.0,
											strokeWeight: 2
											});
									
											flightPath[countPorViajeVelocidad].setMap(this.map1);	
											countPorViajeVelocidad++;
										}
									
										}
										console.log(pathResultado);
										console.log(this.sumTotalKm);
										this.setState({path:pathResultado});
										this.setState({kmTotal:this.sumTotalKm.toFixed(1)});
										this.setState({arrayKm:SumKmXViajeVelocidad});
										this.setState({pathResumen:re});
										console.log(flightPath[0]);
										this.zoomToObject(flightPath[0]);
									}
										
						break;
						case 'porsenal':
						marker=[];
						SumKmXViaje=[];
						var imgdot = "";
						let pathSenal=this.obtenerSenal(re);
							if(!flightPath===undefined)	{	
								flightPath.setMap(null);
							}
							else{
							var pathHistorico = pathSenal;
							for(let x=0;x<Object.keys(pathHistorico).length;x++){
							var Sum=0;
							var flag=true;
							//////---------Marker----------------//////
							for(let i =0;i<Object.keys(pathHistorico[x]).length;i++){
						
							}
							//////---------Polyline--------------//////
							if(pathHistorico[x][1]!==undefined){
							flightPath[x] = new google.maps.Polyline({
								path:pathHistorico[x],
								geodesic: true,
								icons: [{
									icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
									offset: '100%',
									repeat: '50px'
									}],
								strokeColor:pathHistorico[x][1].rgba,//Object.keys(pathHistorico[x])[0].rgba ,
								strokeOpacity: 1.0,
								strokeWeight: 2
								});
								flightPath[x].setMap(this.map1);
							}	
							}

							this.setState({path:path});
							this.setState({pathResumen:re});
							this.zoomToObject(flightPath[0]);
						}
						break;
						case 'porvelocidad':
						marker=[];
						SumKmXViaje=[];
						var imgdot = "";
						//console.log(re);
						let pathVelocidad=this.obtenerVelocidad(re);
						//console.log(pathVelocidad);
							if(!flightPath===undefined)	{
								flightPath.setMap(null);
							}
							else{
							var pathHistorico = pathVelocidad;
							for(let x=0;x<Object.keys(pathHistorico).length;x++){
							var Sum=0;
							var flag=true;
							//////---------Marker----------------//////
							for(let i =0;i<Object.keys(pathHistorico[x]).length;i++){
								////---------conseguir kilometraje--------/////
								////--------------------------------------////
							}
							//////---------Polyline--------------//////
							if(pathHistorico[x][1]!==undefined){
							flightPath[x] = new google.maps.Polyline({
								path:pathHistorico[x],
								geodesic: true,			
								icons: [{
									icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
									offset: '100%',
									repeat: '50px'
									}],
								strokeColor:pathHistorico[x][1].rgba,//Object.keys(pathHistorico[x])[0].rgba ,
								strokeOpacity: 1.0,
								strokeWeight: 2
								});
								flightPath[x].setMap(this.map1);
							}	
							}
							
							
							this.setState({path:path});	
							this.setState({pathResumen:re});					
							this.zoomToObject(flightPath[0]);
						}

						break;
						default:
							console.log('no entro a ninguna opcion de historico')
							break;
					}
					
					
					
				}
	
	inicializeOption(id){
        var x = document.getElementById(id); 
        for(let i=0;i<(document.getElementById(id).length+3);i++){
            x.remove('0');
        }
	}

	agregarOptions(id,opText){
        //console.log(opText);
        var x = document.getElementById(id);
        var option = document.createElement("option");
        option.value =  opText['id'];
        option.text = opText['nombre'];
        x.add(option, x[opText['id']]);
	}
	obtenerSenal(re){
		let ini = true;
		let pathSenal=[];
		let pathSenal2=[];
		for(let x=0;x<Object.keys(re).length;x++){
			if(ini===true){
				switch(re[x].CALIDAD_GPRS){
					case 5:
						re[x].rgba = 'green';
					break;
					case 4:
						re[x].rgba = 'skyblue';
					break;
					case 3:
						re[x].rgba = 'skyeblue';
					break;
					case 2:
						re[x].rgba = 'blue';
					break;
					case 1:
						re[x].rgba = 'blue';
					break;
					case 0:
						re[x].rgba = 'red';
					break;
					default:
					re[x].rgba = 'red';
					break;
				}
				pathSenal2.push(re[x]);
				ini=false;
			}
			else{
				if(re[x].CALIDAD_GPRS===re[x-1].CALIDAD_GPRS){
					switch(re[x].CALIDAD_GPRS){
						case 5:
							re[x].rgba = 'green';
						break;
						case 4:
							re[x].rgba = 'skyblue';
						break;
						case 3:
							re[x].rgba = 'skyblue';
						break;
						case 2:
							re[x].rgba = 'blue';
						break;
						case 1:
							re[x].rgba = 'blue';
						break;
						case 0:
							re[x].rgba = 'red';
						break;
						default:
							re[x].rgba = 'red';
						break;
					}
					pathSenal2.push(re[x]);
				}
				else{
					switch(re[x].CALIDAD_GPRS){
						case 5:
							re[x].rgba = 'green';
						break;
						case 4:
							re[x].rgba = 'skyblue';
						break;
						case 3:
							re[x].rgba = 'skyblue';
						break;
						case 2:
							re[x].rgba = 'blue';
						break;
						case 1:
							re[x].rgba = 'blue';
						break;
						case 0:
							re[x].rgba = 'red';
						break;
						default:
							re[x].rgba = 'red';
						break;
					}
					pathSenal.push(pathSenal2);
					pathSenal2=[];
					pathSenal2.push(re[x-1]);
					pathSenal2.push(re[x]);
				}
				
			}
		}
		this.setState({path:pathSenal});
		//console.log(pathSenal);
		return pathSenal;
	}
	obtenerVelocidad(re){
		let ini = true;
		let pathVelocidad=[];
		let pathVelocidad2=[];
		for(let x=0;x<Object.keys(re).length;x++){
			if(ini===true){
				if(re[x].velocidad<40){
					re[x].rgba = 'darkGreen';
				}
				else if((re[x].velocidad>39)&&(re[x].velocidad<60)){
					re[x].rgba = 'green';
				}
				else if((re[x].velocidad>59)&&(re[x].velocidad<90)){
					re[x].rgba = 'skyblue';
				}
				else if((re[x].velocidad>89)&&(re[x].velocidad<120)){
					re[x].rgba = 'blue';
				}
				else if(re[x].velocidad>119){
					re[x].rgba = 'red';
				}
				else if(re[x].velocidad==undefined){
					re[x].rgba = 'black'
				}
				else {
					re[x].rgba = 'black'
				}
				pathVelocidad2.push(re[x]);
				ini=false;
			}
			else{
				if(re[x].velocidad===re[x-1].velocidad){
					if(re[x].velocidad<40){
						re[x].rgba = 'darkGreen';
					}
					else if((re[x].velocidad>39)&&(re[x].velocidad<60)){
						re[x].rgba = 'green';
					}
					else if((re[x].velocidad>59)&&(re[x].velocidad<90)){
						re[x].rgba = 'skyblue';
					}
					else if((re[x].velocidad>89)&&(re[x].velocidad<120)){
						re[x].rgba = 'blue';
					}
					else if(re[x].velocidad>119){
						re[x].rgba = 'red';
					}
					else if(re[x].velocidad==undefined){
						re[x].rgba = 'black'
					}
					else {
						re[x].rgba = 'black'
					}
					pathVelocidad2.push(re[x]);
				}
				else{
					if(re[x].velocidad<40){
						re[x].rgba = 'darkGreen';
					}
					else if((re[x].velocidad>39)&&(re[x].velocidad<60)){
						re[x].rgba = 'green';
					}
					else if((re[x].velocidad>59)&&(re[x].velocidad<90)){
						re[x].rgba = 'skyblue';
					}
					else if((re[x].velocidad>89)&&(re[x].velocidad<120)){
						re[x].rgba = 'blue';
					}
					else if((re[x].velocidad>89)&&(re[x].velocidad<120)){
						re[x].rgba = 'orange';
					}
					else if(re[x].velocidad>119){
						re[x].rgba = 'red';
					}
					else if(re[x].velocidad==undefined){
						re[x].rgba = 'black'
					}
					else {
						re[x].rgba = 'black'
					}
					pathVelocidad.push(pathVelocidad2);
					pathVelocidad2=[];
					pathVelocidad2.push(re[x-1]);
					pathVelocidad2.push(re[x]);
				}
				
			}
		}
		//console.log(pathVelocidad);
		this.setState({path:pathVelocidad});
		return pathVelocidad;
	}
	obtenerViajes(re){
		let ini = true;
		let path=[];
		let path2=[];
		let pathEvento=[]
		console.log(re);
		for(let x=0;x<Object.keys(re).length;x++){
			if(ini===true){
				path2.push(re[x]);
				pathEvento.push(re[x]);
				ini=false;
			}
			else{
				switch(re[x].tipoEvento){
					case 'Encendido':
					//console.log(path2);
					path2.push(re[x]);
					pathEvento.push(re[x].tipoEvento);
					break;
					case 'Apagado':
					path2.push(re[x]);
					path.push(path2);
					pathEvento.push(re[x].tipoEvento);
					path2=[];
					break;
					default:
					path2.push(re[x]);
					pathEvento.push(re[x].tipoEvento);
					
					break;
				}
				
			}
		}
		console.log(path);
		console.log(pathEvento);
		this.setState({path:path});
		this.setState({showPorViaje:true});
		return path;
	}
	obtenerViajesVelocidad(re){
		let ini = true;
		let iniViaje = false;
		let fin = true;
		let primerEvento = true;
		var fecha1;
		let path=[];
		let path2=[];
		let odometroFinal=0;
		let sumTiempo=0;
		let tiempoEnEspera;
		//console.log(re);
		for(let x=0;x<Object.keys(re).length;x++){
			if((ini===true)){
				if((re[x].velocidad>0.09)&&((re[x].estadoMotor===1)||(re[x].tipoEvento==='Grua'))&&(re[x].ACUM_ODOMETRO>0)){
					if(primerEvento){
						primerEvento=false;
					}
				path2.push(re[x]);
				ini=false;
				}
			}
			else{
				fecha1 = moment(re[x-1].fechaHora);
				var fecha2 = moment(re[x].fechaHora);
				var diff = fecha2.diff(fecha1, 'h'); // Diff in days
				tiempoEnEspera = fecha2.diff(fecha1, 's');
				if(((re[x].velocidad>0.09)&&(re[x].estadoMotor===1))||((re[x].tipoEvento==='Grua')&&(re[x].ACUM_ODOMETRO>0))){
					//path2=[];					
					if(!fin){
						console.log(re[x])
						path2.push(re[x]);//path2.push(re[x-1])
						fin=true;
					}
					if(iniViaje){
						re[x-1].fechaHora=re[x].fechaHora;
						path2.push(re[x-1]);
						path2.push(re[x]);
						iniViaje=false;
					}
					else{
					sumTiempo=0;
					path2.push(re[x]);
					if((x===Object.keys(re).length-1)&&(re[x].velocidad>0.09)&&((re[x].estadoMotor===1)||(re[x].tipoEvento==='Grua'))){
						//0.09
						console.log(path2);
						path2.push(re[x]);
						if(path2[0]!==undefined){
							if((path2[path2.length-1].ACUM_ODOMETRO-path2[0].ACUM_ODOMETRO)>500){							
								path.push(path2);
								fin=false;
								path2=[];
								path2.push(re[x]);
							}
						}else{
							if((path2[path2.length-1].ACUM_ODOMETRO-path2[1].ACUM_ODOMETRO)>500){							
								path.push(path2);
								fin=false;
								path2=[];
								path2.push(re[x]);
							}
							
						}
				}
					//console.log(path2);
					}
				}else{
					sumTiempo+=tiempoEnEspera;
					if((sumTiempo>600)||((fecha2.hour()>22)&&(sumTiempo>200))||((x===Object.keys(re).length-1)&&(re[x].velocidad>0.09)&&(re[x].estadoMotor===1))){
						if(fin){
								if((tiempoEnEspera<3500)){
									
									//console.log(tiempoEnEspera);
								path2.push(re[x]);
								}
								//console.log(fecha2.hour());
								//console.log(path2);								
								if((path2[path2.length-1].ACUM_ODOMETRO-path2[1].ACUM_ODOMETRO)>500)
								{	
									path.push(path2);
									//}									
									//console.log(path2);
									fin=false;
									//path2.length=0;
									path2=[];
									console.log(path2);
									console.log(re[x].fechaHora);
									//re[x].fechaHora= re[x+2].fechaHora;//
									console.log(re[x].fechaHora);
									iniViaje=true;
									//path2.push(re[x]);
									//path2[path2.length-1].fechaHora=re[x+1].fechaHora;
									console.log(path2);
									odometroFinal=0;
								}
								
						}
					
					}else{
						if(((re[x].tipoEvento==="Apagado")&&(re[x].estadoMotor===0)&&(x===Object.keys(re).length-1))||((re[x].tipoEvento==="Apagado erroneo")&&(re[x].estadoMotor===0)&&(x===Object.keys(re).length-1))){
							console.log('entro a este');
							path.push(path2);
							path2=[];
						}
						
					}
					
				}
				
			}
		}
		if(path.length>0){
			if(path[0][0]===undefined){
				path[0][0]=path[0][1];
			}
		}
		console.log(path);
		this.setState({path:path});
		pathPrueba=path;
		this.setState({showPorViajeVelocidad:true});
		return path;
	}
	obtenerKm(p){
		//let inicialOdometro=p[Object.keys(p)][0].ACUM_ODOMETRO;
		for(let x=0;x<p[Object.keys(p)].length;x++){
			//p[Object.keys(p)][x].ACUM_ODOMETRO
		}		
	}
	random_rgba() {
		var o = Math.round, r = Math.random, s = 255;
		return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + 1 + ')';
	}
	onBtExport() {
		//console.log('entra a exportar')
		var params = {
		  fileName: "eventos",
		  sheetName: "eventos"		 
		};
		
		gridApi.exportDataAsExcel(params);
	  }
	changeSelect(event){
		//console.log(event.target.value);
			switch(event.target.value){
					case 'Simple':
							this.setState({showPorViaje:false});
							this.setState({showPorSenal:false});
							this.setState({showPorViajeVelocidad:false});
							this.setState({showPorfalse:true});
							this.setState({selectTipoHistorico:'simple'});
					break;
					case 'PorViajes':
							this.setState({selectTipoHistorico:'porviaje'});
							this.setState({showPorSenal:false});
							this.setState({showPorVelocidad:false});
							this.setState({showPorViajeVelocidad:false});
							this.setState({showPorViaje:true});
					break;
					case 'PorSenal':
							this.setState({showPorViaje:false});
							this.setState({showPorVelocidad:false});
							this.setState({showPorViajeVelocidad:false});
							this.setState({showPorSenal:true});
							this.setState({selectTipoHistorico:'porsenal'});
					break;
					case 'PorVelocidad':
							this.setState({showPorViaje:false});
							this.setState({showPorViajeVelocidad:false});
							this.setState({showPorSenal:false});
							this.setState({showPorVelocidad:true});
							this.setState({selectTipoHistorico:'porvelocidad'});
					break;
					case 'PorViajesVelocidad':
							this.setState({showPorViaje:false});
							this.setState({showPorSenal:false});
							this.setState({showPorViajeVelocidad:true});
							this.setState({showPorVelocidad:false});
							this.setState({selectTipoHistorico:'porviajesvelocidad'});
					break;
					default:
						break;
			}
	}
	obtenerChekeado(id){
		//this.state.fClearMArker(this.state.that);
	try{	
		var x = document.getElementById(id);
		var idNum=id.substring(5);
		
   if(x.checked){
		flightPath[idNum].setMap(this.map1);
		for(let i=0;i<Object.keys(markerViaje[idNum]).length;i++ )
		{
			
			if(markerViaje[idNum][i]!==undefined){
				markerViaje[idNum][i].setMap(this.map1);
			}
			
		}
	 }
	 else{
		
		flightPath[idNum].setMap(null);
		for(let i=0;i<Object.keys(markerViaje[idNum]).length;i++ )
		{
			if(markerViaje[idNum][i]!==undefined){
				markerViaje[idNum][i].setMap(null);
			}
			
		}
	 }
	}
	catch(e){
		console.log(e);
	}  
    
}
obtenerIdEmpresa(){
	var x = this.state.statePrincipal['id'];
	console.log(x);
	return x;
}
obtenerHeredarGrupo(){
	var x = this.state.statePrincipal['heredarGrupo'];		
	console.log(x);
	return x;
}
obtenerLoginIn(){
	var x = this.state.statePrincipal['loginIn'];
	console.log(x);		
	return x;
}
obtenerEsSubCuenta(){
	var x = this.state.statePrincipal['esSubCuenta'];
	console.log(x);
	return x;
}
obtenerIdUsu(){
	var x = this.state.statePrincipal['idUsu'];
	console.log(x);
	return x.id;
}
	playRuta(id,p){
		id=	id.substring(3);
		//console.log(p[id]);
		if(countLimit===0){
		auto={};
		auto = new google.maps.Marker({
			position: p[id][0],
			map: this.map1,
		});
	}
		window.timerID4 = setInterval(
			() => {auto.setPosition(new google.maps.LatLng(p[id][countLimit].lat, p[id][countLimit].lng));countLimit++;if(p[id].length<=countLimit){countLimit=0; console.log('entro a clear');auto={};clearInterval(window.timerID4);}},			
			1000
			  );
	}
	pauseRuta(){
		clearInterval(window.timerID4);
	}
	rewindRuta(id,p){
		id=	id.substring(3);
		if(countLimit>0){
			countLimit--;
			auto.setPosition(new google.maps.LatLng(p[id][countLimit].lat, p[id][countLimit].lng));
		}
	}
	forwardRuta(id,p){
		id=	id.substring(3);
		if(countLimit===0){
			auto={};
			auto = new google.maps.Marker({
				position: p[id][0],
				map: this.map1
			});
		}
		if(countLimit<p[id].length){
		countLimit++;
		auto.setPosition(new google.maps.LatLng(p[id][countLimit].lat, p[id][countLimit].lng));
		}
	}
	onChangeValVelocidad(val){
		this.setState({valEventoVelocidad:val});
	}
	handleChange = (unidad) => {
		let ppal = this.state.statePrincipal;
		ppal.placa = unidad['value'];
		this.setState({statePrincipal:ppal});
		this.setState({unidad:unidad['value'] });
		console.log('Option selected:', unidad['value']);
	}
	handleChangeGroup = (unidadGroup) => {
		this.setState({eventoGroup:unidadGroup});
		console.log('Option selected:', unidadGroup);
  	}
	buscarSelect()
	{
		// creamos un variable que hace referencia al select
		var select=document.getElementById("fPlaca");
	
		// obtenemos el valor a buscar
		var buscar=document.getElementById("fSearch").value;
	
		// recorremos todos los valores del select
		for(var i=1;i<select.length;i++)
		{
			if(select.options[i].text==buscar)
			{
				// seleccionamos el valor que coincide
				select.selectedIndex=i;
			}
		}
	}
	
	componentDidMount() {
		this.mapInit();
		let objPlaca={
			idEmpresa:this.obtenerIdEmpresa(),
			esSub:this.obtenerEsSubCuenta(),
			idUsu:this.state.statePrincipal['idUsu']
		}
		obtenerPlacas(objPlaca,this.changeOptionPlaca);
	}

	componentWillMount(){
		
		 if(window.timerID1!==undefined){
		   //console.log('ACTIVO!!!');
		   clearInterval(window.timerID1);
			clearInterval(window.timerID2);
			 clearInterval(window.timerID3);
		 }
	   }

	IsExcesoVelPersonalizado(){
		let result = false;
		for(let i=0;i<Object.keys(this.state.eventoGroup).length;i++)
		{
			if(this.state.eventoGroup[i].value==='Exceso Vel. Personalizada'){
				result= true;
			}
		}
		return result;
	}
	cargarDatosAState(tipo){
		switch(tipo){
			case 'ayer':
				this.setState({showPersonalizado:false,fechaIni:ayer,fechaFin:ayer,horaIni:'00:00',horaFin:'23:59'});
				return 1;
			break;
			case 'hoy':
				this.setState({showPersonalizado:false,fechaIni:hoy,fechaFin:hoy,horaIni:'00:00',horaFin:'23:59'});
				return 1;
			break;
			case 'semana':
				this.setState({showPersonalizado:false,fechaIni:semana,fechaFin:ayer,horaIni:'00:00',horaFin:'23:59'});
				return 1;
			break;
			case 'mes':
				this.setState({showPersonalizado:false,fechaIni:mesAnteriorIni,mesAnteriorFin:ayer,horaIni:'00:00',horaFin:'23:59'});
				return 1;
			break;
			default:
				break;
		}
	}
	async buscarHistorico(tipo){
		this.mapInit();
		const response = await this.cargarDatosAState(tipo);
		this.ejecutarBuscarHistorico();

		
	}
	ejecutarBuscarHistorico(){
		let objHistorico ={
			placa:	this.state.statePrincipal['placa'],
			tipo: this.state.selectTipoHistorico,
			FechaIni: this.state.fechaIni,
			FechaFin:this.state.fechaFin,
			HoraIni:this.state.horaIni,
			HoraFin:this.state.horaFin
		}
		console.log(objHistorico);
		mostrarHistoricoConEvento(this,objHistorico,this.changeRowData,this.changeHistoricoConEvento,this.mostrarH,this.mostrarMArker)

	}
  render() {
		let propState = this.state.pathResumen;
		let KmState=this.state.arrayKm;
		
		//console.log(this.state.path);
		countCheck=-1;
		//console.log(propState);
    return (
		<div>
			<div id="wrapper" style={{position:'relative', display:'flex', justifyContent:'spaceBetween'}}>			
			<div className={this.state.class} style={{width:this.state.anchura, height:'100vh', backgroundColor:'white'}}> 
			<Form className='FormKardex' name='formVehiculo' style={{margin:'5px'}}>
				<div style={{width:'80vw',display:'flex',justifyContent:'spaceBetween',marginBottom:'4px'}}> 
					<div style={{width:'40%'}}>Unidad:</div>
					<div style={{width:'50%'}}><Select2 value={this.state.optionPlaca.filter(option => option.label === this.state.statePrincipal['placa'])} options={this.state.optionPlaca} isSearchable='true' isClearable='true'  onChange={this.handleChange} /></div>
				</div>
				<div style={{width:'80vw',display:'flex',justifyContent:'spaceBetween'}}>
				<div style={{width:'40%'}}>Tipo:</div>
				<div style={{width:'50%'}}>
				<Input type="select" id='fPlaca'  onClick={event => this.changeSelect(event)}>
					<option value='Simple'>Simple</option>
					{/*<option value='PorViajes'>Por Viaje</option>
					<option value='PorViajesVelocidad'>Por Viaje/Velocidad</option>
					<option value='PorSenal'>Por Senal</option>
					<option value='PorVelocidad'>Por Velocidad</option>*/}
				</Input></div>
				</div>
				<div style={{width:'80vw',display:'flex',justifyContent:'spaceBetween',marginBottom:'4px',marginTop:'4px'}}> 
					<div style={{width:'40%'}}>TipoEvento:</div>
					<div style={{width:'50%'}}><Select2 options={DataTipoEvento} isMulti isSearchable='true' isClearable='true'  onChange={this.handleChangeGroup} /></div>
				</div>
				{this.IsExcesoVelPersonalizado()&&<div style={{marginLeft:'10px',marginTop:'5px',display:'flex',justifyContent:'betweenSpace'}}>Velocidad mayor a:<Input style={{marginLeft:'5px',width:'55%',height:'20px'}} type='text' value={this.state.valEventoVelocidad} onChange={(e)=>{this.onChangeValVelocidad(e.target.value)}}></Input></div>}
				<div style={{display:'flex',justifyContent:'spaceBetween',marginTop:'3px',marginBottom:'3px'}}>
					<div style={{width:'18%', backgroundColor:'#1F69AD', borderLeft:'1px solid white', cursor: 'pointer',textAlign:'center',color:'white',fontSize:'11px'}} onClick={()=>{this.buscarHistorico('ayer');mostrarHistorico(this,this.state.unidad,ayer,ayer,'00:00','23:59')}}>Ayer</div>
					<div style={{width:'18%', backgroundColor:'#1F69AD', borderLeft:'1px solid white', cursor: 'pointer',textAlign:'center',color:'white',fontSize:'11px'}} onClick={()=>{this.buscarHistorico('hoy');mostrarHistorico(this,this.state.unidad,hoy,hoy,'00:00','23:59')}}>Hoy</div>
					<div style={{width:'18%', backgroundColor:'#1F69AD', borderLeft:'1px solid white', cursor: 'pointer',textAlign:'center',color:'white',fontSize:'11px'}} onClick={()=>{this.buscarHistorico('semana');mostrarHistorico(this,this.state.unidad,semana,hoy,'00:00','23:59')}}>Semana</div>
					<div style={{width:'18%', backgroundColor:'#1F69AD', borderLeft:'1px solid white', cursor: 'pointer',textAlign:'center',color:'white',fontSize:'11px'}} onClick={()=>{this.buscarHistorico('mes');mostrarHistorico(this,this.state.unidad,mesAnteriorIni,mesAnteriorFin,'00:00','23:59');}}>Mes Anterior</div>
					<div style={{width:'28%', backgroundColor:'#1F69AD', borderLeft:'1px solid white', cursor: 'pointer',textAlign:'center',color:'white',fontSize:'11px'}} onClick={()=>{this.changeShowPersonalizado()}}>Personalizado</div>
				</div>
				</Form>
				
				<div id='scrooll' style={{overflowY: 'scroll',overflowX:'hidden',height:'59vh'}}>
				<Form>
				{this.state.showPersonalizado===true&&
				<div>
				<div style={{width:'80vw',display:'flex',justifyContent:'spaceAround'}}>
					<div style={{marginLeft:'4vw',width:'30vw'}}>De:</div>
					<div style={{marginLeft:'2vw'}}><Input type="Date" id='fGrupo' value={this.state.fechaIni} onChange={(e)=>{this.setState({fechaIni:e.target.value});}}/></div>
				</div>
				<div style={{width:'80vw',display:'flex',justifyContent:'spaceAround'}}>
					<div style={{marginLeft:'4vw',width:'30vw'}}>Hora Inicio:</div>
					<div style={{marginLeft:'2vw'}}><Input type="Time" id='fGrupo'  value={this.state.horaIni} onChange={(e)=>{this.setState({horaIni:e.target.value});}}/></div>
				</div>
				<div style={{width:'80vw',display:'flex',justifyContent:'spaceAround'}}> 
					<div style={{marginLeft:'4vw',width:'30vw'}}>A:</div>
					<div style={{marginLeft:'2vw'}}><Input type="Date" id='fGrupo' value={this.state.fechaFin} onChange={(e)=>{this.setState({fechaFin:e.target.value});}}/></div>
				</div>
				<div style={{width:'80vw',display:'flex',justifyContent:'spaceAround'}}> 
					<div style={{marginLeft:'4vw',width:'30vw'}}>Hora Fin:</div>
					<div style={{marginLeft:'2vw'}}><Input type="Time" id='fGrupo' value={this.state.horaFin} onChange={(e)=>{this.setState({horaFin:e.target.value});}}/></div>
				</div>
					<Button  onClick={()=>{this.buscarHistorico('personalizado');mostrarHistorico(this,this.state.unidad,this.state.fechaIni,this.state.fechaFin,this.state.horaIni,this.state.horaFin);}}><h4>Consultar</h4></Button>
				</div>
			}
			</Form>
			{this.state.showPorViaje===true&&<div style={{height:'40vh',overflowY: 'scroll',overflowX:'hidden',marginBottom:'5px'}}>
				<div style={{display:'flex',justifyContent:'spacebetween'}}>
					<div>Cantidad de Viajes:</div>
			<div>{this.state.path!==undefined&&Object.keys(this.state.path).length}</div>
				</div>
				{this.state.path!==undefined&&this.state.path.map(p=>{countCheck++;
					return <div style={{borderBottom:'1px solid Lavender',display:'flex',justifyContent:'spaceBetween'}}>
					<div style={{width:'30px',backgroundColor:p[Object.keys(p)[0]].rgba,marginLeft:'5px',marginRight:'5px'}}></div>
					<div>
						<div style={{fontSize:'10px'}}>{this.obtenerFechaLocal(p[Object.keys(p)[0]].fechaHora)}</div>
						<div style={{fontSize:'10px'}}>{this.obtenerFechaLocal(p[Object.keys(p)[Object.keys(p).length-1]].fechaHora)}</div>
					</div>
					<div style={{display:'flex',justifyContent:'spaceBetween',width:'30%'}}>
						<div style={{paddingLeft:'9px',fontSize:'12px',width:'60%'}}>{((p[1].KmViaje-p[0].ACUM_ODOMETRO)/1000).toFixed(1)+" Km"}</div>
						<div style={{marginLeft:'13px'}}><input type='checkbox' className='checkViaje' id={'check'+countCheck} defaultChecked onClick={(e)=>{this.obtenerChekeado(e.currentTarget.id)}}/></div>
					</div>
					<div style={{display:'flex',justifyContent:'spaceBetween',width:'10%'}}>
						<div onClick={(e)=>{this.rewindRuta(e.currentTarget.id,this.state.path)}} id={'Rep'+countCheck}><img src={rewind} alt='rojo' width='20px' height='20px' /></div>
					
					  <div onClick={(e)=>{if(this.state.pausarRecorrido){this.pauseRuta()}else{this.playRuta(e.currentTarget.id,this.state.path)};this.changePausarRecorrido();}} id={'Rep'+countCheck}><img src={play} alt='rojo' width='20px' height='20px' /></div>
						
						<div onClick={(e)=>{this.forwardRuta(e.currentTarget.id,this.state.path)}} id={'dep'+countCheck}><img src={forward} alt='rojo' width='20px' height='20px' /></div>
					</div>
					</div>})
				}
				<div>
				<div style={{width:'30px'}}></div>
				<div style={{width:'30%'}}></div>
				<div></div>
				</div>
			</div>}
			{this.state.showPorViajeVelocidad===true&&<div style={{height:'40vh',overflowY: 'scroll',overflowX:'hidden',marginBottom:'5px'}}>
				<div style={{display:'flex',justifyContent:'spacebetween'}}>
				<div>Cantidad de Viajes:</div>
			<div>{this.state.path!==undefined&&Object.keys(this.state.path).length}</div>
				</div>
				{this.state.path!==undefined&&this.state.path.map(p=>{countCheck++;
					return <div style={{borderBottom:'1px solid Lavender',display:'flex',justifyContent:'spaceBetween'}} >
					<div style={{width:'30px',backgroundColor:p[Object.keys(p)[0]].rgba,marginLeft:'5px',marginRight:'5px'}}></div>
					<div>
						<div style={{fontSize:'10px'}}>{this.obtenerFechaLocal(p[Object.keys(p)[0]].fechaHora)}</div>
						<div style={{fontSize:'10px'}}>{this.obtenerFechaLocal(p[Object.keys(p)[Object.keys(p).length-1]].fechaHora)}</div>
					</div>
					<div style={{display:'flex',justifyContent:'spaceBetween',width:'30%'}}>
						<div style={{paddingLeft:'9px',fontSize:'12px',width:'60%'}}>{((p[1].KmViaje-p[0].ACUM_ODOMETRO)/1000).toFixed(2)+" Km"}</div>
						<div style={{marginLeft:'13px'}}><input type='checkbox' className='checkViaje' id={'check'+countCheck} defaultChecked onClick={(e)=>{this.obtenerChekeado(e.currentTarget.id)}}/></div>
					</div>
					<div style={{display:'flex',justifyContent:'spaceBetween',width:'10%'}}>
						<div onClick={(e)=>{this.rewindRuta(e.currentTarget.id,this.state.path)}} id={'Rep'+countCheck}><img src={rewind} alt='rojo' width='20px' height='20px' /></div>
					
					  <div onClick={(e)=>{if(this.state.pausarRecorrido){this.pauseRuta()}else{this.playRuta(e.currentTarget.id,this.state.path)};this.changePausarRecorrido();}} id={'Rep'+countCheck}><img src={play} alt='rojo' width='20px' height='20px' /></div>
						
						<div onClick={(e)=>{this.forwardRuta(e.currentTarget.id,this.state.path)}} id={'dep'+countCheck}><img src={forward} alt='rojo' width='20px' height='20px' /></div>
					</div>
					</div>})
				}
				<div>
				<div style={{width:'30px'}}></div>
				<div style={{width:'30%'}}></div>
				<div></div>
				</div>
			</div>}
			{this.state.showPorSenal&&<div style={{marginLeft:'5vw',marginBottom:'5px'}}>
					<div style={{display:'flex', justifyContent:'spaceBetween'}}>
						<div style={{backgroundColor:'green',height:'25px', width:'25px',borderRadius:'5px',marginBottom:'2px'}}></div>
						<div style={{marginLeft:'4px',color:'grey'}}>Excelente</div>
					</div>
					<div style={{display:'flex', justifyContent:'spaceBetween'}}>
						<div style={{backgroundColor:'skyblue',height:'25px', width:'25px',borderRadius:'5px',marginBottom:'2px'}}></div>
						<div style={{marginLeft:'4px',color:'grey'}}>Moderada</div>
					</div>
					<div style={{display:'flex', justifyContent:'spaceBetween'}}>
						<div style={{backgroundColor:'blue',height:'25px', width:'25px',borderRadius:'5px',marginBottom:'2px'}}></div>
						<div style={{marginLeft:'4px',color:'grey'}}>Baja</div>
					</div>
					<div style={{display:'flex', justifyContent:'spaceBetween'}}>
						<div style={{backgroundColor:'red',height:'25px', width:'25px',borderRadius:'5px',marginBottom:'2px'}}></div>
						<div style={{marginLeft:'4px',color:'grey'}}>Sin Senal</div>
					</div>
			</div>
			}
			{
				this.state.showPorVelocidad&&<div style={{marginLeft:'5vw',marginBottom:'5px'}}>
					<div style={{display:'flex', justifyContent:'spaceBetween'}}>
						<div style={{backgroundColor:'darkGreen',height:'25px', width:'25px',marginBottom:'2px',borderRadius:'5px'}}></div>
						<div style={{marginLeft:'4px',color:'grey'}}>0-39</div>
					</div>
					<div style={{display:'flex', justifyContent:'spaceBetween'}}>
						<div style={{backgroundColor:'green',height:'25px', width:'25px',marginBottom:'2px',borderRadius:'5px'}}></div>
						<div style={{marginLeft:'4px',color:'grey'}}>40-59</div>
					</div>
					<div style={{display:'flex', justifyContent:'spaceBetween'}}>
						<div style={{backgroundColor:'skyblue',height:'25px', width:'25px',marginBottom:'2px',borderRadius:'5px'}}></div>
						<div style={{marginLeft:'4px',color:'grey'}}>60-89</div>
					</div>
					<div style={{display:'flex', justifyContent:'spaceBetween'}}>
						<div style={{backgroundColor:'blue',height:'25px', width:'25px',marginBottom:'2px',borderRadius:'5px'}}></div>
						<div style={{marginLeft:'4px',color:'grey'}}>90-119</div>
					</div>
					<div style={{display:'flex', justifyContent:'spaceBetween'}}>
						<div style={{backgroundColor:'red',height:'25px', width:'25px',marginBottom:'2px',borderRadius:'5px'}}></div>
						<div style={{marginLeft:'4px',color:'grey'}}>120-...</div>
					</div>
			</div>
			}
			<div id='ResumenHistorico' style={{marginLeft:'10px',width:'80vw',color:'grey',borderTop:'2px solid lavender'}}>
				<div style={{display:'flex',justifyContent:'spaceBetween', width:'80vw'}}>
					<div style={{width:'50%'}}>Rango Fecha/Hora:</div>
					<div style={{width:'50%'}}>{propState[0]!==undefined&& this.obtenerFechaLocal(propState[0].fechaHora)}</div>
					<div style={{width:'50%'}}>{propState[0]!==undefined&& this.obtenerFechaLocal(propState[Object.keys(propState).length-1].fechaHora)}</div>
				</div>
				<div style={{display:'flex',justifyContent:'spaceBetween', width:'80vw'}}>
					<div style={{width:'50%'}}>{'Lugar Inicio: '}</div>
					<div style={{width:'50%'}}>{'Lugar Fin: '}</div>
				</div>
				<div style={{width:'100%',backgroundColor:'#1F69AD'}}>
				<h6 style={{color:'white'}}>Resumen</h6>
				</div>
				<div style={{display:'flex',justifyContent:'spaceAround', width:'80vw'}}>
					<div style={{}}>Kilometraje Total:</div>
					<div style={{marginLeft:'2vw'}}>{this.sumTotalKm>0?this.state.kmTotal +" km":this.sumTotalKm+" km"}</div>
				</div>
				<div style={{display:'flex',justifyContent:'spaceAround', width:'80vw'}}>
					<div style={{}}>Paradas:</div>
					<div style={{marginLeft:'2vw'}}>{this.state.count['parking']}</div>
				</div>
				<div style={{display:'flex',justifyContent:'spaceAround'}}>
					<div style={{}}>Detenciones Prolongadas:</div>
					<div style={{marginLeft:'2vw'}}>{this.state.count['stop']}</div>
				</div>
				<div style={{display:'flex',justifyContent:'spaceAround'}}>
					<div style={{}}>Excesos de Velocidad:</div>
					<div style={{marginLeft:'2vw'}}>{this.state.count['exVelocidad']}</div>
				</div>
				<div style={{display:'flex',justifyContent:'spaceAround'}}>
					<div style={{}}>Conduccion sin cinturon:</div>
					<div style={{marginLeft:'2vw'}}>{this.state.count['condSinCinturon']}</div>
				</div>
				<div style={{display:'flex',justifyContent:'spaceAround'}}>
					<div style={{}}>Conduccion fuera de horario:</div>
					<div style={{marginLeft:'2vw'}}>{this.state.count['fueraHorario']}</div>
					<div style={{}}></div>
				</div>
				<br/>
				<div style={{borderRadius: '6px',backgroundColor:'#3a4248',width:'50vw',height:'10vh',color:'white',marginLeft:'8vw',fontSize:'16px'}}>
					<div style={{paddingTop:'2vh',marginLeft:'6vw'}} onClick={()=>{this.state.changeHistoricoShow(false)}}>Rastreo</div>
				</div>
			</div>
			</div>
			</div>
			
			<div>
			<div>
			</div>
				
			</div>
			<div className={this.state.class} style={{ backgroundColor:'rgb(31, 105, 173)',height:'100vh',width:this.state.anchuraBoton,color:'white'}} onClick={()=>{this.changeAnchura()}}><div style={{marginTop:'40vh'}}>Ver <br/> Mapa</div></div>
			<div id="gmapsCanvasContainer" style={{ width:this.state.anchoMapa,height:'100vh',zIndex:1,}}></div>
			{this.state.flagBotonMinimizado&&<div style={{width:'40px', height:'40px', backgroundColor:'white', position:'absolute', zIndex:'999',top:'1vh', left:'3vw'}} onClick={()=>{this.changeAnchura()}}><img src={menu} alt='' /></div>}
		
			</div>
		
				<div style={{height:'0vh', width:'0vw', backgroundColor:'white', cursor:'pointer'}}>
					<div style={{height:'0vh', width:'0vw',backgroundColor:'Lavender', color: 'gray',display:'flex',justifyContent:'spaceAround'}} ><div style={{width:'87%'}} onClick={()=>{this.changeShowEvento()}}></div></div>
					<div 
                  className="ag-theme-balham"
                  style={{
	                height: '190px', 
	                width: '100%' }}
		            >
                    <AgGridReact
						enableSorting={true}
						enableFilter={true}
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
						defaultColDef={this.state.defaultColDef}
						onGridReady={this.onGridReady}
						onRowClicked={(re)=>{
							//window.console.log(re);
							let eventoCoordenadas ={lat:re.data.lat,
																			lng:re.data.lng}
							this.setState({coordenadas:eventoCoordenadas});

						}}
						/>
                    
                </div>
					
					
				</div>
			</div>
    );
  }
}

export default Historico;
