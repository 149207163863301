import React, { Component } from 'react';
import Sensor from './Sensor';


class Sensores extends Component {
    //atributos del state que comiensan en 'o' obtienen su valor de ajax_obtenerInfoSelect
    constructor(props){
        super(props);
        this.state={ 
            ///////////Usuario///////////
            sensores:this.props.sensores,
            popup:this.props.popup,
            acumsJson:this.props.acumsJson
        }
        
      }
    
    componentWillReceiveProps(nextprops){
        if(this.state.sensores!==nextprops.sensores){
            this.setState({sensores:nextprops.sensores});
            this.setState({acumsJson:nextprops.acumsJson});
        }
    }
    
    render() {
        //console.log(this.props);
        let sensores = this.state.sensores;
        console.log(this.state.popup);
        console.log(sensores);
        return (
            <div>
                <div >               
                {sensores.map(i=><Sensor sensor={i} acumsJson={this.state.acumsJson}/> )} 
                </div>
            </div>
        )
    }
}

export default Sensores;
