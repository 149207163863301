import $ from 'jquery';
import {url, url2} from '../ParametroConexion/parametroConexion';

export function ObtenerEventosVehiculo(that,idEmpresa,esSubcuenta,idUsu,loginIn){
    if(loginIn===0){
        $.ajax({
        //url: 'http://127.0.0.1:2525/datos',
        url: url,
        type: "POST",
        data: {
                
                data_op:{
                        idEmpresa:idEmpresa,
                        esSubcuenta:esSubcuenta,
                        idUsu:idUsu        
                },
                op:'READ-OBTENEREVENTOCTUALVEHICULO'
        },
        success: function(result)            
		   {
                      
            var array = JSON.parse( result );
            var istrue = false,
            result= array['data'];
            
            if(result!=null){
                istrue=true;
            }
            else{
               istrue=false;
            }
            that.setState({punto:result});
            that.mostrarVehiculos(result);
           console.log(that.state.punto);
            }
    
    }).done(function(result){
 
    })
}else{
        $.ajax({
                url: url2,
                type: "POST",
                data: {
                        
                        data_op:{
                                idEmpresa:idEmpresa,
                                esSubcuenta:esSubcuenta,
                                idUsu:idUsu        
                        },
                        op:'READ-OBTENEREVENTOCTUALVEHICULO'
                },
                success: function(result)            
                           {
                              
                    var array = JSON.parse( result );
                    var istrue = false,
                    result= array['data'];
                    console.log(result);
                    if(result!=null){
                        istrue=true;
                    }
                    else{
                       istrue=false;
                    }
                    that.setState({punto:result});
                    that.mostrarVehiculos(result);
                    }
            
            }).done(function(result){
              
         
            })
}
}
export function ObtenerMapaSenal(that){
        console.log('entro a map senal');
            $.ajax({
            //url: 'http://127.0.0.1:2525/datos',
            url: url,
            type: "POST",
            data: {
                    
                    data_op:{
                                  
                    },
                    op:'READ-MAPASENAL'
            },
            success: function(result)            
                       {
                          
                var array = JSON.parse( result );
                var istrue = false,
                result= array['data'];
                
                if(result!=null){
                    istrue=true;
                }
                else{
                   istrue=false;
                }
                console.log(result);
                that.setState({puntoMapaSenal:result});
                that.mostrarMapaSenal(result);
               
                }
        
        }).done(function(result){
     
        })
    }
export function ObtenerVehiculo(idEmpresa,esSubcuenta,idUsu,loginIn,changePunto,mostrarVehiculos,herenciaGrupo){
        console.log(idEmpresa,esSubcuenta,idUsu);
    if(loginIn==='0'){
        $.ajax({
        url: url,
        type: "POST",
        data: {
                data_op:{
                        idEmpresa:idEmpresa,
                        esSubcuenta:esSubcuenta,
                        herenciaGrupo:herenciaGrupo,
                        idUsu:idUsu
                },
                op:'READ-OBTENERPUNTOACTUALVEHICULO'
        },
        success: function(result)            
		   {
                     
            var array = JSON.parse( result );
            var istrue = false,
            result= array['data'];
            
            if(result!=null){
                istrue=true;
            }
            else{
               istrue=false;
            }
            changePunto(result);
            mostrarVehiculos(result);
            }
    
    }).done(function(result){
     
    })
}else{
        $.ajax({
                //url: 'http://127.0.0.1:2525/datos',
                url: url2,
                type: "POST",
                data: {
                        data_op:{idEmpresa:idEmpresa,
                        esSubcuenta:esSubcuenta,
                        idUsu:idUsu        
                },
                        op:'READ-OBTENERPUNTOACTUALVEHICULO'
                },
                success: function(result)            
                           {
                             
                    var array = JSON.parse( result );
                    var istrue = false,
                    result= array['data'];
                    
                    if(result!=null){
                        istrue=true;
                    }
                    else{
                       istrue=false;
                    }
                    changePunto(result);
                    mostrarVehiculos(result);
                    
                    }
 
            }).done(function(result){
              
            })
}
}
export function ObtenerActivos(that,idEmpresa){
    $.ajax({
        //url: 'http://127.0.0.1:2525/datos',
        url: url,
        type: "POST",
        data: {
                data_op:idEmpresa,
                op:'READ-OBTENERACTIVOS'
        },
        success: function(result)            
		   {
            
            var array = JSON.parse( result );
            var istrue = false,
            result= array['data'];
            
            if(result!=null){
                istrue=true;
               // console.log(result);
               // console.log('entro a no es null');
            }
            else{
               istrue=false;
            }
            that.setState({activos:result});
            
        
            },
        error: function() {
            $.ajax({
                //url: 'http://127.0.0.1:2525/datos',
                url: url2,
                type: "POST",
                data: {
                        data_op:idEmpresa,
                        op:'READ-OBTENERACTIVOS'
                },
                success: function(result)            
                   {
        
                    var array = JSON.parse( result );
                    var istrue = false,
                    result= array['data'];
                    
                    if(result!=null){
                        istrue=true;
                       // console.log(result);
                       // console.log('entro a no es null');
                    }
                    else{
                       istrue=false;
                    }
                    that.setState({activos:result});
                    
                    
                    }
                    }).done(function(result){
                    // console.log('ajax_activos');
                   // console.log(result);
              
                    })
            }
    }).done(function(result){
       // console.log('ajax_activos');
      // console.log(result);
 
    })
}


